import React from "react";
import AboutIcons from "../modules/AboutIcons";

const About = () => {
    return <>
        <link rel="stylesheet" href="css/About.module.css" />
        <div className="mkd-content">
            <div className="mkd-ps-image-holder">
                <div className="mkd-ps-image mkd-owl-slider">
                    <div className="mkd-ps-image">
                        <picture>
                            <source media="(min-width: 481px)" srcSet="assets/freepik/3.jpg" />
                            <source media="(max-width: 480px)" srcSet="assets/freepik/4.jpg" />
                            <img className="d-block w-100" src="assets/freepik/4.jpg" alt="Banner" />
                        </picture>
                    </div>
                </div>
            </div>
            <div className="vc_empty_space" style={{ height: 20 }}>
                <span className="vc_empty_space_inner" />
            </div>
            <div className="mkd-content-inner">
                <div className="mkd-full-width">
                    <div className="mkd-full-width-inner">
                        <div className="mkd-grid-row">
                            <div className="mkd-page-content-holder mkd-grid-col-12">
                                <div className="mkd-row-grid-section-wrapper ">
                                    <div className="mkd-row-grid-section">
                                        <div className="vc_row wpb_row vc_row-fluid vc_custom_1518101055446">
                                            <div className="wpb_column vc_column_container vc_col-sm-12">
                                                <div className="vc_column-inner vc_custom_1517908805816">
                                                    <div className="wpb_wrapper">
                                                        <div className="wpb_text_column wpb_content_element ">
                                                            <div className="wpb_wrapper">
                                                                <p style={{ textAlign: "center" }}>
                                                                    
                                                                    Welcome to Newry Properties Pvt. Ltd. Our aim is
                                                                    to revolutionize real estate in Chennai. We
                                                                    meticulously craft residential homes at strategic
                                                                    locations. We seamlessly blend urban living with
                                                                    nature's tranquility. Our mission is to deliver
                                                                    sophisticated homes.
                                                                    We cater to individuals, families, and
                                                                    communities. Our homes embody uncompromising
                                                                    quality and excellence. Proudly affiliated
                                                                    with the esteemed Newry Group, headquartered in
                                                                    Hong Kong. Its global presence and multifaceted
                                                                    excellence have earned it renown.
                                                                </p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="vc_empty_space" style={{ height: 50 }}>
                                <span className="vc_empty_space_inner" />
                            </div>
                            <div className="mkd-row-grid-section-wrapper ">
                                <div className="mkd-row-grid-section">
                                    <div className="vc_row wpb_row vc_row-fluid vc_custom_1516716512293"></div>
                                </div>
                            </div>
                            <div
                                className="mkd-row-grid-section-wrapper mkd-parallax-row-holder mkd-content-aligment-left"
                                data-parallax-bg-image="assets/freepik/5.jpg"
                                data-parallax-bg-speed={1}
                                data-parallax-bg-height={520}
                            >
                                <div className="mkd-row-grid-section">
                                    <div className="vc_row wpb_row vc_row-fluid vc_row-o-content-middle vc_row-flex">
                                        <div className="wpb_column vc_column_container vc_col-sm-12">
                                            <div className="vc_column-inner vc_custom_1516718150958">
                                                <div className="wpb_wrapper">
                                                    <div className="mkd-elements-holder   mkd-one-column  mkd-responsive-mode-768 ">
                                                        <div
                                                            className="mkd-eh-item    "
                                                            data-item-class="mkd-eh-custom-1384"
                                                            data-1280-1600="151px 0 0 0 "
                                                            data-1024-1280="151px 0 0 0 "
                                                            data-768-1024="153px 0 164px 0"
                                                            data-680-768="154px 0 164px 0"
                                                            data-680="150px 0"
                                                        >
                                                            <div className="mkd-eh-item-inner">
                                                                <div
                                                                    className="mkd-eh-item-content mkd-eh-custom-1384"
                                                                    style={{ padding: "151px 0 0 0" }}
                                                                >
                                                                    <div className="mkd-section-title-holder  ">
                                                                        <div className="mkd-st-inner">
                                                                            <h1
                                                                                className="mkd-st-title"
                                                                                style={{ color: "#ffffff" }}
                                                                            >
                                                                                Our Philosophy
                                                                            </h1>
                                                                            <p className="philosophy-text">
                                                                                Beyond erecting concrete structures, we
                                                                                prioritize harmony with nature, optimizing
                                                                                ventilation, light, and vaastu principles.
                                                                                With a steadfast commitment to aesthetics,
                                                                                sustainability, and resident well-being,
                                                                                we're setting new industry standards.
                                                                            </p>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="vc_empty_space" style={{ height: 50 }}>
                                <span className="vc_empty_space_inner" />
                            </div>
                            <div className="mkd-row-grid-section-wrapper ">
                                <div className="mkd-row-grid-section">
                                    <div className="vc_row wpb_row vc_row-fluid vc_custom_1517909237365">
                                        <div className="wpb_column vc_column_container vc_col-sm-12 vc_col-lg-6 vc_col-md-12 vc_col-xs-12">
                                            <div className="vc_column-inner vc_custom_1516720842737">
                                                <div className="wpb_wrapper">
                                                    <div className="mkd-elements-holder   mkd-one-column  mkd-responsive-mode-768 ">
                                                        <div
                                                            className="mkd-eh-item    "
                                                            data-item-class="mkd-eh-custom-2477"
                                                            data-1280-1600="0 12px 0 25px"
                                                            data-1024-1280="0 12px 0 0"
                                                            data-768-1024="0 33px 0 0"
                                                            data-680-768="0 33px 0 0"
                                                            data-680="0 43px 0 10px"
                                                        >
                                                            <div className="mkd-eh-item-inner">
                                                                <div
                                                                    className="mkd-eh-item-content mkd-eh-custom-2477"
                                                                    style={{ padding: "0 12px 0 25px" }}
                                                                >
                                                                    <div className="mkd-single-image-holder   mkd-image-has-drop-shadow-effect  mkd-image-drop-shadow-left">
                                                                        <div className="mkd-si-inner">
                                                                            <img
                                                                                width={800}
                                                                                height={1166}
                                                                                src="assets/freepik/6.jpg"
                                                                                className="attachment-full size-full"
                                                                                alt="a"
                                                                                loading="lazy"
                                                                            />
                                                                            <div
                                                                                className="mkd-si-drop-shadow"
                                                                                style={{ backgroundColor: "#c3c7d0" }}
                                                                            ></div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="wpb_column vc_column_container vc_col-sm-12 vc_col-lg-6 vc_col-md-12 vc_col-xs-12">
                                            <div className="vc_column-inner vc_custom_1516720853580">
                                                <div className="wpb_wrapper">
                                                    <div className="mkd-elements-holder   mkd-one-column  mkd-responsive-mode-768 ">
                                                        <div className="mkd-eh-item">
                                                            <div className="mkd-eh-item-inner">
                                                                <div className="mkd-eh-item-content mkd-eh-custom-9539">
                                                                    <div className="wpb_text_column wpb_content_element ">
                                                                        <div className="wpb_wrapper">
                                                                            <h3
                                                                                style={{
                                                                                    marginBottom: 25,
                                                                                    letterSpacing: "3.1px"
                                                                                }}
                                                                            >
                                                                                Vision
                                                                            </h3>
                                                                            <ul className="vision">
                                                                                <li>
                                                                                    To create lasting smiles for every
                                                                                    homeowner with the dream home of their
                                                                                    life.
                                                                                </li>
                                                                                <li>
                                                                                    We strive to be the synonym of happiness
                                                                                    in real estate by crafting living spaces
                                                                                    that transcend mere structures.
                                                                                </li>
                                                                                <li>
                                                                                    Our aim is to resonate with the customer's
                                                                                    aspirations and desires by integrating
                                                                                    harmony in concrete.
                                                                                </li>
                                                                            </ul>
                                                                        </div>
                                                                    </div>
                                                                    <div
                                                                        className="vc_empty_space"
                                                                        style={{ height: 34 }}
                                                                    >
                                                                        <span className="vc_empty_space_inner" />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="vc_empty_space" style={{ height: 50 }}>
                                <span className="vc_empty_space_inner" />
                            </div>
                            <div className="mkd-row-grid-section-wrapper ">
                                <div className="mkd-row-grid-section">
                                    <div className="vc_row wpb_row vc_row-fluid vc_custom_1517909205847">
                                        <div className="wpb_column vc_column_container vc_col-sm-12">
                                            <div className="vc_column-inner">
                                                <div className="wpb_wrapper">
                                                    <div className="mkd-testimonials-holder mkd-testimonials-standard  clearfix">
                                                        <div className="mkd-separator-top-line" />
                                                        <div className="mkd-cover-box" />
                                                        <div className="mkd-author-separator-line" />
                                                        <div
                                                            className="mkd-testimonials mkd-owl-slider"
                                                            data-number-of-items={1}
                                                            data-enable-loop="yes"
                                                            data-enable-autoplay="yes"
                                                            data-slider-speed={5000}
                                                            data-slider-speed-animation={600}
                                                            data-enable-navigation="yes"
                                                            data-enable-pagination="no"
                                                        >
                                                            <div
                                                                className="mkd-testimonial-content"
                                                                id="mkd-testimonials-666"
                                                            >
                                                                <div className="mkd-testimonial-text-holder">
                                                                    <p className="mkd-testimonial-text">
                                                                        The quality of a person's life is in direct
                                                                        proportion to their commitment to excellence,
                                                                        regardless of their chosen field of endeavor.
                                                                    </p>
                                                                    <span className="mkd-testimonial-author">
                                                                        <span>Vince Lombardi</span>
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="vc_empty_space" style={{ height: 50 }}>
                                <span className="vc_empty_space_inner" />
                            </div>
                            <div className="mkd-row-grid-section-wrapper ">
                                <div className="mkd-row-grid-section">
                                    <div className="vc_row wpb_row vc_row-fluid vc_custom_1517909237365">
                                        <div className="wpb_column vc_column_container vc_col-sm-12 vc_col-lg-5 vc_col-md-12 vc_col-xs-12">
                                            <div className="vc_column-inner vc_custom_1516720853580 mission">
                                                <div className="wpb_wrapper">
                                                    <div className="mkd-elements-holder   mkd-one-column  mkd-responsive-mode-768 ">
                                                        <div className="mkd-eh-item    ">
                                                            <div className="mkd-eh-item-inner">
                                                                <div className="mkd-eh-item-content mkd-eh-custom-9539">
                                                                    <div className="wpb_text_column wpb_content_element ">
                                                                        <div className="wpb_wrapper">
                                                                            <h3
                                                                                style={{
                                                                                    marginBottom: 25,
                                                                                    letterSpacing: "3.1px"
                                                                                }}
                                                                            >
                                                                                Mission
                                                                            </h3>
                                                                            <ul className="vision">
                                                                                <li style={{ marginBottom: 0 }}>
                                                                                    Prioritize quality and customer
                                                                                    satisfaction as fundamental principles.
                                                                                </li>
                                                                                <li style={{ marginBottom: 0 }}>
                                                                                    Design homes that excel in aesthetics,
                                                                                    functionality, and comfort, reflecting our
                                                                                    commitment to sustainability.
                                                                                </li>
                                                                                <li style={{ marginBottom: 0 }}>
                                                                                    Cater to diverse tastes and preferences,
                                                                                    leaving no detail overlooked.
                                                                                </li>
                                                                                <li style={{ marginBottom: 0 }}>
                                                                                    Measure success by exceeding homeowner
                                                                                    expectations, delivering unparalleled
                                                                                    satisfaction and happiness.
                                                                                </li>
                                                                                <li style={{ marginBottom: 0 }}>
                                                                                    Continuously strive to surpass standards,
                                                                                    setting new benchmarks in the real estate
                                                                                    industry.
                                                                                </li>
                                                                            </ul>
                                                                        </div>
                                                                    </div>
                                                                    <div
                                                                        className="vc_empty_space hide-desktop"
                                                                        style={{ height: 34 }}
                                                                    >
                                                                        <span className="vc_empty_space_inner" />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="wpb_column vc_column_container vc_col-sm-12 vc_col-lg-6 vc_col-md-12 vc_col-xs-12">
                                            <div className="vc_column-inner vc_custom_1516720842737">
                                                <div className="wpb_wrapper">
                                                    <div className="mkd-elements-holder   mkd-one-column  mkd-responsive-mode-768 ">
                                                        <div
                                                            className="mkd-eh-item    "
                                                            data-item-class="mkd-eh-custom-2477"
                                                            data-1280-1600="0 12px 0 25px"
                                                            data-1024-1280="0 12px 0 0"
                                                            data-768-1024="0 33px 0 0"
                                                            data-680-768="0 33px 0 0"
                                                            data-680="0 43px 0 10px"
                                                        >
                                                            <div className="mkd-eh-item-inner">
                                                                <div
                                                                    className="mkd-eh-item-content mkd-eh-custom-2477"
                                                                    style={{ padding: "0 12px 0 25px" }}
                                                                >
                                                                    <div className="mkd-single-image-holder   mkd-image-has-drop-shadow-effect  mkd-image-drop-shadow-left">
                                                                        <div className="mkd-si-inner">
                                                                            <img
                                                                                width={800}
                                                                                height={1166}
                                                                                src="assets/freepik/7.jpg"
                                                                                className="attachment-full size-full"
                                                                                alt="a"
                                                                                loading="lazy"
                                                                            />
                                                                            <div
                                                                                className="mkd-si-drop-shadow"
                                                                                style={{ backgroundColor: "#c3c7d0" }}
                                                                            ></div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="vc_empty_space" style={{ height: 50 }}>
                                <span className="vc_empty_space_inner" />
                            </div>
                            <section className="wpb-content-wrapper location-advantages">
                                <div className="mkd-row-grid-section-wrapper ">
                                    <div className="mkd-row-grid-section">
                                        <div className="vc_row wpb_row vc_row-fluid vc_custom_1516021087353">
                                            <div className="wpb_column vc_column_container vc_col-sm-12">
                                                <div className="vc_column-inner vc_custom_1516007647796">
                                                    <div className="wpb_wrapper">
                                                        <div className="mkd-elements-holder   mkd-one-column  mkd-responsive-mode-768 ">
                                                            <div
                                                                className="mkd-eh-item    "
                                                                data-item-class="mkd-eh-custom-8773"
                                                                data-1280-1600="0 0 20px 0"
                                                                data-1024-1280="0 0 20px 0"
                                                                data-768-1024="0 0 20px 0"
                                                                data-680-768="0 0 20px 0"
                                                                data-680="0px"
                                                            >
                                                                <div className="mkd-eh-item-inner">
                                                                    <div
                                                                        className="mkd-eh-item-content mkd-eh-custom-8773"
                                                                        style={{ padding: "0 0 20px 0" }}
                                                                    >
                                                                        <div className="mkd-section-title-holder  ">
                                                                            <div className="mkd-st-inner road">
                                                                                <h2 className="mkd-st-title">
                                                                                    THE ROAD AHEAD
                                                                                </h2>
                                                                                <p>
                                                                                    As we journey forward, NEWRY Properties
                                                                                    remains steadfast in our pursuit of real
                                                                                    estate excellence. Our roadmap is a fusion
                                                                                    of innovation, sustainability, and an
                                                                                    unswerving commitment to our customers. In
                                                                                    the years ahead, we will:
                                                                                </p>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </section>
                            <div className="mkd-full-width" style={{ background: "#ebebeb" }}>
                                <div className="mkd-full-width-inner">
                                    <div className="mkd-grid-row">
                                        <div className="mkd-page-content-holder mkd-grid-col-12">
                                            <section className="wpb-content-wrapper location-advantages">
                                                <div className="mkd-row-grid-section-wrapper ">
                                                    <div className="mkd-row-grid-section">
                                                        <div className="vc_row wpb_row vc_row-fluid vc_column-gap-35">
                                                            <div className="wpb_column vc_column_container vc_col-sm-12 vc_col-lg-4 vc_col-md-12 vc_col-xs-12">
                                                                <div className="vc_column-inner">
                                                                    <div className="wpb_wrapper">

                                                                        <div className="mkd-iwt clearfix  mkd-iwt-icon-left mkd-iwt-icon-medium">
                                                                            <AboutIcons
                                                                                id="icon1"
                                                                                defaultSrc="assets/icon1.svg"
                                                                                hoverSrc="assets/bulb-icon.svg"
                                                                                listItems={['Innovate in design to create living spaces that resonate with evolving customer needs.']}
                                                                            />
                                                                        </div>
                                                                        <div
                                                                            className="vc_empty_space"
                                                                            style={{ height: 38 }}
                                                                        >
                                                                            <span className="vc_empty_space_inner" />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="wpb_column vc_column_container vc_col-sm-12 vc_col-lg-4 vc_col-md-12 vc_col-xs-12">
                                                                <div className="vc_column-inner">
                                                                    <div className="wpb_wrapper">
                                                                        <div className="mkd-iwt clearfix  mkd-iwt-icon-left mkd-iwt-icon-medium">
                                                                            <AboutIcons
                                                                                id="icon2"
                                                                                defaultSrc="assets/globe.svg"
                                                                                hoverSrc="assets/globe-animated.svg"
                                                                                listItems={['Champion environmental stewardship through sustainable practices and technology.']}
                                                                            /> 
                                                                        </div>
                                                                        <div
                                                                            className="vc_empty_space"
                                                                            style={{ height: 38 }}
                                                                        >
                                                                            <span className="vc_empty_space_inner" />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="wpb_column vc_column_container vc_col-sm-12 vc_col-lg-4 vc_col-md-12 vc_col-xs-12">
                                                                <div className="vc_column-inner">
                                                                    <div className="wpb_wrapper">
                                                                        <div className="mkd-iwt clearfix  mkd-iwt-icon-left mkd-iwt-icon-medium">
                                                                            <AboutIcons
                                                                                id="icon3"
                                                                                defaultSrc="assets/aboutus/3-a.svg"
                                                                                hoverSrc="assets/aboutus/3-animated.svg"
                                                                                listItems={['Elevate community engagement, enriching lives beyond our developments.']}
                                                                            />  
                                                                        </div>
                                                                        <div
                                                                            className="vc_empty_space"
                                                                            style={{ height: 38 }}
                                                                        >
                                                                            <span className="vc_empty_space_inner" />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="mkd-row-grid-section-wrapper ">
                                                    <div className="mkd-row-grid-section">
                                                        <div className="vc_row wpb_row vc_row-fluid vc_custom_1516871268525 vc_column-gap-35">
                                                            <div className="wpb_column vc_column_container vc_col-sm-12 vc_col-lg-4 vc_col-md-12 vc_col-xs-12">
                                                                <div className="vc_column-inner">
                                                                    <div className="wpb_wrapper">
                                                                        <div className="mkd-iwt clearfix  mkd-iwt-icon-left mkd-iwt-icon-medium">
                                                                            <AboutIcons
                                                                             id="icon4"
                                                                             defaultSrc="assets/aboutus/globalization.svg"
                                                                             hoverSrc="assets/aboutus/globalization-a.svg"
                                                                                listItems={['Expand our global footprint while nurturing our presence in India.']}
                                                                            /> 
                                                                        </div>
                                                                        <div
                                                                            className="vc_empty_space"
                                                                            style={{ height: 38 }}
                                                                        >
                                                                            <span className="vc_empty_space_inner" />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="wpb_column vc_column_container vc_col-sm-12 vc_col-lg-4 vc_col-md-11 vc_col-xs-12">
                                                                <div className="vc_column-inner">
                                                                    <div className="wpb_wrapper">
                                                                        <div className="mkd-iwt clearfix  mkd-iwt-icon-left mkd-iwt-icon-medium">
                                                                            <AboutIcons
                                                                             id="icon5"
                                                                             defaultSrc="assets/aboutus/icon5.svg"
                                                                             hoverSrc="assets/aboutus/icon5-a.svg"
                                                                                listItems={['Embrace cutting-edge technology for enhanced customer experiences.']}
                                                                            />  
                                                                        </div>
                                                                        <div
                                                                            className="vc_empty_space"
                                                                            style={{ height: 38 }}
                                                                        >
                                                                            <span className="vc_empty_space_inner" />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="wpb_column vc_column_container vc_col-sm-12 vc_col-lg-4 vc_col-md-12 vc_col-xs-12">
                                                                <div className="vc_column-inner">
                                                                    <div className="wpb_wrapper">
                                                                        <div className="mkd-iwt clearfix  mkd-iwt-icon-left mkd-iwt-icon-medium">
                                                                        <AboutIcons
                                                                             id="icon6"
                                                                             defaultSrc="assets/aboutus/Icons2.svg"
                                                                             hoverSrc="assets/aboutus/Icons2-a.svg"
                                                                                listItems={['Uphold a customer-centric approach, adapting to their evolving needs.']}
                                                                            /> 
                                                                        </div>
                                                                        <div
                                                                            className="vc_empty_space"
                                                                            style={{ height: 38 }}
                                                                        >
                                                                            <span className="vc_empty_space_inner" />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </section>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </>;
};

export default About;