const TermsAndConditions = () => {
    return <>
    <link rel="stylesheet" href="css/BuyersGuide.moudle.css" />
    <div className="mkd-content">
  {/*SLIDER START*/}
  <div className="mkd-ps-image-holder">
    <div className="mkd-ps-image mkd-owl-slider">
      <div className="mkd-ps-image">
        <picture>
          <source media="(min-width: 481px)" srcSet="assets/banner-white.jpg" />
          <source media="(max-width: 480px)" srcSet="assets/banner-white-mobile.jpg" />
          <img
            className="d-block w-100"
            src="assets/banner-white-mobile.jpg"
            alt="Banner"
          />
        </picture>
      </div>
    </div>
  </div>
  {/*SLIDER END*/}
  <div className="vc_empty_space" style={{ height: 20 }}>
    <span className="vc_empty_space_inner" />
  </div>
  <section className="wpb-content-wrapper location-advantages">
    <div className="mkd-row-grid-section-wrapper ">
      <div className="mkd-row-grid-section">
        <div className="vc_row wpb_row vc_row-fluid vc_custom_1516021087353">
          <div className="wpb_column vc_column_container vc_col-sm-12">
            <div className="vc_column-inner vc_custom_1516007647796">
              <div className="wpb_wrapper">
                <div className="mkd-elements-holder   mkd-one-column  mkd-responsive-mode-768 ">
                  <div
                    className="mkd-eh-item    "
                    data-item-class="mkd-eh-custom-8773"
                    data-1280-1600="0 0 20px 0"
                    data-1024-1280="0 0 20px 0"
                    data-768-1024="0 0 20px 0"
                    data-680-768="0 0 20px 0"
                    data-680="0px"
                  >
                    <div className="mkd-eh-item-inner">
                      <div
                        className="mkd-eh-item-content mkd-eh-custom-8773"
                        style={{ padding: "0 0 20px 0" }}
                      >
                        <div className="mkd-section-title-holder  ">
                          <div className="mkd-st-inner road">
                            <h2 className="mkd-st-title">
                              Terms and Conditions
                            </h2>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <div className="mkd-content-inner">
    <div className="mkd-full-width">
      <div className="mkd-full-width-inner">
        <div className="mkd-grid-row">
          <div className="mkd-page-content-holder mkd-grid-col-12">
            <div className="mkd-row-grid-section-wrapper ">
              <div className="mkd-row-grid-section">
                <div className="vc_row wpb_row vc_row-fluid vc_custom_1516877211553 vc_column-gap-35">
                  <div className="wpb_column vc_column_container vc_col-sm-12">
                    <div className="vc_column-inner">
                      <div className="wpb_wrapper">
                        <div className="vc_row wpb_row vc_inner vc_row-fluid">
                          <div className="wpb_column vc_column_container vc_col-sm-12">
                            <div className="vc_column-inner">
                              <div className="wpb_wrapper">
                                <div className="wpb_text_column wpb_content_element ">
                                  <div className="wpb_wrapper">
                                    <div className="wpb_text_column wpb_content_element ">
                                      <div className="wpb_wrapper">
                                        <h3 style={{ color: "#2c498a" }}>
                                          Consent
                                        </h3>
                                      </div>
                                    </div>
                                    <p>Welcome to www.newry.co.in!</p>
                                    <p>
                                      These terms and conditions outline the
                                      rules and regulations for the use of Newry
                                      Properties’s Website, located at
                                      https://www.newry.co.in/.{" "}
                                    </p>
                                    <p>
                                      By accessing this website we assume you
                                      accept these terms and conditions. Do not
                                      continue to use www.newry.co.in if you do
                                      not agree to take all of the terms and
                                      conditions stated on this page.{" "}
                                    </p>
                                    <p>
                                      The following terminology applies to these
                                      Terms and Conditions, Privacy Statement
                                      and Disclaimer Notice and all Agreements:
                                      “Client”, “You” and “Your” refers to you,
                                      the person log on this website and
                                      compliant to the Company’s terms and
                                      conditions. “The Company”, “Ourselves”,
                                      “We”, “Our” and “Us”, refers to our
                                      Company. “Party”, “Parties”, or “Us”,
                                      refers to both the Client and ourselves.
                                      All terms refer to the offer, acceptance
                                      and consideration of payment necessary to
                                      undertake the process of our assistance to
                                      the Client in the most appropriate manner
                                      for the express purpose of meeting the
                                      Client’s needs in respect of provision of
                                      the Company’s stated services, in
                                      accordance with and subject to, prevailing
                                      law of Netherlands. Any use of the above
                                      terminology or other words in the
                                      singular, plural, capitalization and/or
                                      he/she or they, are taken as
                                      interchangeable and therefore as referring
                                      to same.{" "}
                                    </p>
                                    <br />
                                    <div className="wpb_text_column wpb_content_element ">
                                      <div className="wpb_wrapper">
                                        <h3 style={{ color: "#2c498a" }}>
                                          Cookies
                                        </h3>
                                      </div>
                                    </div>
                                    <p>
                                      We employ the use of cookies. By accessing
                                      www.newry.co.in, you agreed to use cookies
                                      in agreement with the Newry Properties’s
                                      Privacy Policy.{" "}
                                    </p>
                                    <p>
                                      Most interactive websites use cookies to
                                      let us retrieve the user’s details for
                                      each visit. Cookies are used by our
                                      website to enable the functionality of
                                      certain areas to make it easier for people
                                      visiting our website. Some of our
                                      affiliate/advertising partners may also
                                      use cookies.{" "}
                                    </p>
                                    <br />
                                    <div className="wpb_text_column wpb_content_element ">
                                      <div className="wpb_wrapper">
                                        <h3 style={{ color: "#2c498a" }}>
                                          License
                                        </h3>
                                      </div>
                                    </div>
                                    <p>
                                      Unless otherwise stated, Newry Properties
                                      and/or its licensors own the intellectual
                                      property rights for all material on
                                      www.newry.co.in. All intellectual property
                                      rights are reserved. You may access this
                                      from www.newry.co.in for your own personal
                                      use subjected to restrictions set in these
                                      terms and conditions.
                                    </p>
                                    <p>You must not:</p>
                                    <p>
                                      • Republish material from www.newry.co.in
                                    </p>
                                    <p>
                                      • Sell, rent or sub-license material from
                                      www.newry.co.in
                                    </p>
                                    <p>
                                      • Reproduce, duplicate or copy material
                                      from www.newry.co.in
                                    </p>
                                    <p>
                                      • Redistribute content from
                                      www.newry.co.in
                                    </p>
                                    <p>
                                      This Agreement shall begin on the date
                                      hereof. Our Terms and Conditions were
                                      created with the help of the Terms And
                                      Conditions Template.
                                    </p>
                                    <p>
                                      Parts of this website offer an opportunity
                                      for users to post and exchange opinions
                                      and information in certain areas of the
                                      website. Newry Properties does not filter,
                                      edit, publish or review Comments prior to
                                      their presence on the website. Comments do
                                      not reflect the views and opinions of
                                      Newry Properties,its agents and/or
                                      affiliates. Comments reflect the views and
                                      opinions of the person who post their
                                      views and opinions. To the extent
                                      permitted by applicable laws, Newry
                                      Properties shall not be liable for the
                                      Comments or for any liability, damages or
                                      expenses caused and/or suffered as a
                                      result of any use of and/or posting of
                                      and/or appearance of the Comments on this
                                      website.
                                    </p>
                                    <p>
                                      Newry Properties reserves the right to
                                      monitor all Comments and to remove any
                                      Comments which can be considered
                                      inappropriate, offensive or causes breach
                                      of these Terms and Conditions.
                                    </p>
                                    <br />
                                    <div className="wpb_text_column wpb_content_element ">
                                      <div className="wpb_wrapper">
                                        <h3 style={{ color: "#2c498a" }}>
                                          You warrant and represent that:
                                        </h3>
                                      </div>
                                    </div>
                                    <p>
                                      • You are entitled to post the Comments on
                                      our website and have all necessary
                                      licenses and consents to do so;
                                    </p>
                                    <p>
                                      • The Comments do not invade any
                                      intellectual property right, including
                                      without limitation copyright, patent or
                                      trademark of any third party;
                                    </p>
                                    <p>
                                      • The Comments do not contain any
                                      defamatory, libelous, offensive, indecent
                                      or otherwise unlawful material which is an
                                      invasion of privacy
                                    </p>
                                    <p>
                                      • The Comments will not be used to solicit
                                      or promote business or custom or present
                                      commercial activities or unlawful
                                      activity.
                                    </p>
                                    <p>
                                      You hereby grant Newry Properties a
                                      non-exclusive license to use, reproduce,
                                      edit and authorize others to use,
                                      reproduce and edit any of your Comments in
                                      any and all forms, formats or media.
                                    </p>
                                    <br />
                                    <div className="wpb_text_column wpb_content_element ">
                                      <div className="wpb_wrapper">
                                        <h3 style={{ color: "#2c498a" }}>
                                          Hyperlinking to our Content
                                        </h3>
                                      </div>
                                    </div>
                                    <p>
                                      The following organizations may link to
                                      our Website without prior written
                                      approval:
                                    </p>
                                    <p>• Government agencies;</p>
                                    <p>• Search engines;</p>
                                    <p>• News organizations;</p>
                                    <p>
                                      • Online directory distributors may link
                                      to our Website in the same manner as they
                                      hyperlink to the Websites of other listed
                                      businesses; and
                                    </p>
                                    <p>
                                      • System wide Accredited Businesses except
                                      soliciting non-profit organizations,
                                      charity shopping malls, and charity
                                      fundraising groups which may not hyperlink
                                      to our Web site.
                                    </p>
                                    <p>
                                      These organizations may link to our home
                                      page, to publications or to other Website
                                      information so long as the link: (a) is
                                      not in any way deceptive; (b) does not
                                      falsely imply sponsorship, endorsement or
                                      approval of the linking party and its
                                      products and/or services; and (c) fits
                                      within the context of the linking party’s
                                      site.
                                    </p>
                                    <p>
                                      We may consider and approve other link
                                      requests from the following types of
                                      organizations:{" "}
                                    </p>
                                    <p>
                                      • commonly-known consumer and/or business
                                      information sources;
                                    </p>
                                    <p>• dot.com community sites;</p>
                                    <p>
                                      • associations or other groups
                                      representing charities;
                                    </p>
                                    <p>• online directory distributors;</p>
                                    <p>• internet portals;</p>
                                    <p>
                                      • accounting, law and consulting firms;
                                      and
                                    </p>
                                    <p>
                                      • educational institutions and trade
                                      associations.
                                    </p>
                                    <p>
                                      We will approve link requests from these
                                      organizations if we decide that: (a) the
                                      link would not make us look unfavorably to
                                      ourselves or to our accredited businesses;
                                      (b) the organization does not have any
                                      negative records with us; (c) the benefit
                                      to us from the visibility of the hyperlink
                                      compensates the absence of Newry
                                      Properties; and (d) the link is in the
                                      context of general resource information.
                                    </p>
                                    <p>
                                      These organizations may link to our home
                                      page so long as the link: (a) is not in
                                      any way deceptive; (b) does not falsely
                                      imply sponsorship, endorsement or approval
                                      of the linking party and its products or
                                      services; and (c) fits within the context
                                      of the linking party’s site.
                                    </p>
                                    <p>
                                      If you are one of the organizations listed
                                      in paragraph 2 above and are interested in
                                      linking to our website, you must inform us
                                      by sending an e-mail to Newry Properties.
                                      Please include your name, your
                                      organization name, contact information as
                                      well as the URL of your site, a list of
                                      any URLs from which you intend to link to
                                      our Website, and a list of the URLs on our
                                      site to which you would like to link. Wait
                                      2-3 weeks for a response.
                                    </p>
                                    <p>
                                      Approved organizations may hyperlink to
                                      our Website as follows:{" "}
                                    </p>
                                    <p>• By use of our corporate name; or</p>
                                    <p>
                                      • By use of the uniform resource locator
                                      being linked to; or
                                    </p>
                                    <p>
                                      • By use of any other description of our
                                      Website being linked to that makes sense
                                      within the context and format of content
                                      on the linking party’s site.{" "}
                                    </p>
                                    <p>
                                      No use of Newry Properties’s logo or other
                                      artwork will be allowed for linking absent
                                      a trademark license agreement.
                                    </p>
                                    <br />
                                    <div className="wpb_text_column wpb_content_element ">
                                      <div className="wpb_wrapper">
                                        <h3 style={{ color: "#2c498a" }}>
                                          iFrames
                                        </h3>
                                      </div>
                                    </div>
                                    <p>
                                      Without prior approval and written
                                      permission, you may not create frames
                                      around our Webpages that alter in any way
                                      the visual presentation or appearance of
                                      our Website.
                                    </p>
                                    <br />
                                    <div className="wpb_text_column wpb_content_element ">
                                      <div className="wpb_wrapper">
                                        <h3 style={{ color: "#2c498a" }}>
                                          Content Liability
                                        </h3>
                                      </div>
                                    </div>
                                    <p>
                                      We shall not be hold responsible for any
                                      content that appears on your Website. You
                                      agree to protect and defend us against all
                                      claims that is rising on your Website. No
                                      link(s) should appear on any Website that
                                      may be interpreted as libelous, obscene or
                                      criminal, or which infringes, otherwise
                                      violates, or advocates the infringement or
                                      other violation of, any third party
                                      rights.
                                    </p>
                                    <br />
                                    <div className="wpb_text_column wpb_content_element ">
                                      <div className="wpb_wrapper">
                                        <h3 style={{ color: "#2c498a" }}>
                                          Your Privacy
                                        </h3>
                                      </div>
                                    </div>
                                    <p>Please read Privacy Policy</p>
                                    <br />
                                    <div className="wpb_text_column wpb_content_element ">
                                      <div className="wpb_wrapper">
                                        <h3 style={{ color: "#2c498a" }}>
                                          Reservation of Rights
                                        </h3>
                                      </div>
                                    </div>
                                    <p>
                                      We reserve the right to request that you
                                      remove all links or any particular link to
                                      our Website. You approve to immediately
                                      remove all links to our Website upon
                                      request. We also reserve the right to amen
                                      these terms and conditions and it’s
                                      linking policy at any time. By
                                      continuously linking to our Website, you
                                      agree to be bound to and follow these
                                      linking terms and conditions.
                                    </p>
                                    <br />
                                    <div className="wpb_text_column wpb_content_element ">
                                      <div className="wpb_wrapper">
                                        <h3 style={{ color: "#2c498a" }}>
                                          Removal of links from our website
                                        </h3>
                                      </div>
                                    </div>
                                    <p>
                                      If you find any link on our Website that
                                      is offensive for any reason, you are free
                                      to contact and inform us any moment. We
                                      will consider requests to remove links but
                                      we are not obligated to or so or to
                                      respond to you directly.
                                    </p>
                                    <p>
                                      We do not ensure that the information on
                                      this website is correct, we do not warrant
                                      its completeness or accuracy; nor do we
                                      promise to ensure that the website remains
                                      available or that the material on the
                                      website is kept up to date.
                                    </p>
                                    <br />
                                    <div className="wpb_text_column wpb_content_element ">
                                      <div className="wpb_wrapper">
                                        <h3 style={{ color: "#2c498a" }}>
                                          Disclaimer
                                        </h3>
                                      </div>
                                    </div>
                                    <p>
                                      To the maximum extent permitted by
                                      applicable law, we exclude all
                                      representations, warranties and conditions
                                      relating to our website and the use of
                                      this website. Nothing in this disclaimer
                                      will:
                                    </p>
                                    <p>
                                      • limit or exclude our or your liability
                                      for death or personal injury;
                                    </p>
                                    <p>
                                      • limit or exclude our or your liability
                                      for fraud or fraudulent misrepresentation;
                                    </p>
                                    <p>
                                      • limit any of our or your liabilities in
                                      any way that is not permitted under
                                      applicable law; or
                                    </p>
                                    <p>
                                      • exclude any of our or your liabilities
                                      that may not be excluded under applicable
                                      law.
                                    </p>
                                    <p>
                                      The limitations and prohibitions of
                                      liability set in this Section and
                                      elsewhere in this disclaimer: (a) are
                                      subject to the preceding paragraph; and
                                      (b) govern all liabilities arising under
                                      the disclaimer, including liabilities
                                      arising in contract, in tort and for
                                      breach of statutory duty.
                                    </p>
                                    <p>
                                      As long as the website and the information
                                      and services on the website are provided
                                      free of charge, we will not be liable for
                                      any loss or damage of any nature.
                                    </p>
                                    <br />
                                  </div>
                                </div>
                                <div
                                  className="vc_empty_space"
                                  style={{ height: 32 }}
                                >
                                  <span className="vc_empty_space_inner" />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

    </>;
}

export default TermsAndConditions;