import DynamicHoverIconComponent from '../modules/DynamicHoverIconComponent'
const Services = () => {
  return <>
    <link rel="stylesheet" href="css/Services.module.css" />
    <div className="mkd-content">
      {/*SLIDER START*/}
      <div className="mkd-ps-image-holder">
        <div className="mkd-ps-image mkd-owl-slider">
          <div className="mkd-ps-image">
            <picture>
              <source media="(min-width: 481px)" srcSet="assets/freepik/17.jpg" />
              <source media="(max-width: 480px)" srcSet="assets/freepik/18.jpg" />
              <img className="d-block w-100" src="assets/freepik/18.jpg" alt="Banner" />
            </picture>
            {/*<div class="banner-description">*/}
            {/*   <h2>Leadersh</h2>*/}
            {/*   <h5>A Perfect Lifestyle </h5> */}
            {/*</div> */}
          </div>
        </div>
      </div>
      {/*SLIDER END*/}
      <div className="vc_empty_space" style={{ height: 60 }}>
        <span className="vc_empty_space_inner" />
      </div>
      <div className="mkd-content-inner">
        <div className="mkd-full-width">
          <div className="mkd-full-width-inner">
            <div className="mkd-grid-row">
              <div className="mkd-page-content-holder mkd-grid-col-12">
                <section className="wpb-content-wrapper location-advantages">
                  <div className="mkd-row-grid-section-wrapper ">
                    <div className="mkd-row-grid-section">
                      <div className="vc_row wpb_row vc_row-fluid vc_custom_1516021087353">
                        <div className="wpb_column vc_column_container vc_col-sm-12">
                          <div className="vc_column-inner vc_custom_1516007647796">
                            <div className="wpb_wrapper">
                              <div className="mkd-elements-holder   mkd-one-column  mkd-responsive-mode-768 ">
                                <div
                                  className="mkd-eh-item    "
                                  data-item-class="mkd-eh-custom-8773"
                                  data-1280-1600="0 0 20px 0"
                                  data-1024-1280="0 0 20px 0"
                                  data-768-1024="0 0 20px 0"
                                  data-680-768="0 0 20px 0"
                                  data-680="0px"
                                >
                                  <div className="mkd-eh-item-inner">
                                    <div
                                      className="mkd-eh-item-content mkd-eh-custom-8773"
                                      style={{ padding: "0 0 20px 0" }}
                                    >
                                      <div className="mkd-section-title-holder  ">
                                        <div className="mkd-st-inner road">
                                          <h2 className="mkd-st-title">
                                            Our Services
                                          </h2>
                                          <div
                                            className="vc_empty_space"
                                            style={{ height: 40 }}
                                          >
                                            <span className="vc_empty_space_inner" />
                                          </div>
                                          <p style={{ textAlign: "center" }}>
                                            Newry specializes in property
                                            maintenance by providing comprehensive
                                            services for a period of one year before
                                            handing over to the association. As part
                                            of our maintenance activities, we offer
                                            the following services:
                                          </p>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>
                {/*FUTURE*/}
                <div className="mkd-full-width" style={{ background: "#ebebeb" }}>
                  <div className="vc_empty_space" style={{ height: 40 }}>
                    <span className="vc_empty_space_inner" />
                  </div>
                  <div className="mkd-full-width-inner">
                    <div className="mkd-grid-row">
                      <div className="mkd-page-content-holder mkd-grid-col-12">
                        <section className="wpb-content-wrapper location-advantages">
                          <div className="mkd-row-grid-section-wrapper ">
                            <div className="mkd-row-grid-section">
                              <div className="vc_row wpb_row vc_row-fluid vc_column-gap-35">
                                <div className="wpb_column vc_column_container vc_col-sm-12 vc_col-lg-4 vc_col-md-12 vc_col-xs-12">
                                  <div className="vc_column-inner">
                                    <div className="wpb_wrapper">
                                      <div className="mkd-iwt clearfix  mkd-iwt-icon-left mkd-iwt-icon-medium">
                                        <div className="mkd-iwt-content">
                                          <DynamicHoverIconComponent 
                                            id="icon1-hover"
                                            defaultSrc="assets/services/1.svg"
                                            hoverSrc="assets/animated/1.svg"
                                            iconStyle={{ width: 100, height: 100 }}
                                          />
                                          <h4 className="mkd-iwt-title">
                                            Security <br />
                                            Management
                                          </h4>
                                          <ul>
                                            <li>
                                              Comprehensive security management
                                              solutions through video surveillance
                                              systems.
                                            </li>
                                          </ul>
                                        </div>
                                      </div>
                                      <div
                                        className="vc_empty_space"
                                        style={{ height: 38 }}
                                      >
                                        <span className="vc_empty_space_inner" />
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div className="wpb_column vc_column_container vc_col-sm-12 vc_col-lg-4 vc_col-md-12 vc_col-xs-12">
                                  <div className="vc_column-inner">
                                    <div className="wpb_wrapper">
                                      <div className="mkd-iwt clearfix  mkd-iwt-icon-left mkd-iwt-icon-medium">
                                        <div className="mkd-iwt-content">
                                          <DynamicHoverIconComponent 
                                            id="icon2-hover"
                                            defaultSrc="assets/services/2.svg"
                                            hoverSrc="assets/animated/2.svg"
                                            iconStyle={{ width: 100, height: 100 }}
                                          /> 
                                          <h4 className="mkd-iwt-title">
                                            Housekeeping <br />
                                            Services
                                          </h4>
                                          <ul>
                                            <li>
                                              Maintaining pristine property
                                              conditions through diligent
                                              housekeeping services.
                                            </li>
                                          </ul>
                                        </div>
                                      </div>
                                      <div
                                        className="vc_empty_space"
                                        style={{ height: 38 }}
                                      >
                                        <span className="vc_empty_space_inner" />
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div className="wpb_column vc_column_container vc_col-sm-12 vc_col-lg-4 vc_col-md-12 vc_col-xs-12">
                                  <div className="vc_column-inner">
                                    <div className="wpb_wrapper">
                                      <div className="mkd-iwt clearfix  mkd-iwt-icon-left mkd-iwt-icon-medium">
                                        <div className="mkd-iwt-content">
                                          <DynamicHoverIconComponent 
                                            id="icon3-hover"
                                            defaultSrc="assets/services/3.svg"
                                            hoverSrc="assets/animated/3.svg"
                                            iconStyle={{ width: 100, height: 100 }}
                                          />  
                                          <h4 className="mkd-iwt-title">
                                            Waste <br />
                                            Management
                                          </h4>
                                          <ul>
                                            <li>
                                              Efficient waste management strategies
                                              for a cleaner, more sustainable
                                              environment.
                                            </li>
                                          </ul>
                                        </div>
                                      </div>
                                      <div
                                        className="vc_empty_space"
                                        style={{ height: 38 }}
                                      >
                                        <span className="vc_empty_space_inner" />
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="mkd-row-grid-section-wrapper ">
                            <div className="mkd-row-grid-section">
                              <div className="vc_row wpb_row vc_row-fluid vc_custom_1516871268525 vc_column-gap-35">
                                <div className="wpb_column vc_column_container vc_col-sm-12 vc_col-lg-4 vc_col-md-12 vc_col-xs-12">
                                  <div className="vc_column-inner">
                                    <div className="wpb_wrapper">
                                      <div className="mkd-iwt clearfix  mkd-iwt-icon-left mkd-iwt-icon-medium">
                                        <div className="mkd-iwt-content">
                                          <DynamicHoverIconComponent 
                                            id="icon4-hover"
                                            defaultSrc="assets/services/4.svg"
                                            hoverSrc="assets/animated/4.svg"
                                            iconStyle={{ width: 100, height: 100 }}
                                          /> 
                                          <h4 className="mkd-iwt-title">
                                            Janitorial <br />
                                            Services
                                          </h4>
                                          <ul>
                                            <li>
                                              Impeccable cleanliness and hygiene
                                              with dedicated janitorial services for
                                              common areas.
                                            </li>
                                          </ul>
                                        </div>
                                      </div>
                                      <div
                                        className="vc_empty_space"
                                        style={{ height: 38 }}
                                      >
                                        <span className="vc_empty_space_inner" />
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div className="wpb_column vc_column_container vc_col-sm-12 vc_col-lg-4 vc_col-md-11 vc_col-xs-12">
                                  <div className="vc_column-inner">
                                    <div className="wpb_wrapper">
                                      <div className="mkd-iwt clearfix  mkd-iwt-icon-left mkd-iwt-icon-medium">
                                        <div className="mkd-iwt-content">
                                          <DynamicHoverIconComponent 
                                            id="icon5-hover"
                                            defaultSrc="assets/services/5.svg"
                                            hoverSrc="assets/animated/5.svg"
                                            iconStyle={{ width: 100, height: 100 }}
                                          /> 
                                          <h4 className="mkd-iwt-title">
                                            Fire Safety &amp; <br />
                                            Emergency
                                          </h4>
                                          <ul>
                                            <li>
                                              {" "}
                                              Prioritizing safety with robust fire
                                              safety protocols along emergency
                                              planning &amp; preparedness{" "}
                                            </li>
                                          </ul>
                                        </div>
                                      </div>
                                      <div
                                        className="vc_empty_space"
                                        style={{ height: 38 }}
                                      >
                                        <span className="vc_empty_space_inner" />
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div className="wpb_column vc_column_container vc_col-sm-12 vc_col-lg-4 vc_col-md-12 vc_col-xs-12">
                                  <div className="vc_column-inner">
                                    <div className="wpb_wrapper">
                                      <div className="mkd-iwt clearfix  mkd-iwt-icon-left mkd-iwt-icon-medium">
                                        <div className="mkd-iwt-content">
                                          <DynamicHoverIconComponent 
                                            id="icon6-hover"
                                            defaultSrc="assets/services/6.svg"
                                            hoverSrc="assets/animated/6.svg"
                                            iconStyle={{ width: 100, height: 100 }}
                                          /> 
                                          <h4 className="mkd-iwt-title">
                                            Energy <br />
                                            Management
                                          </h4>
                                          <ul>
                                            <li>
                                              Optimizing energy usage through
                                              effective energy management
                                              techniques.
                                            </li>
                                          </ul>
                                        </div>
                                      </div>
                                      <div
                                        className="vc_empty_space"
                                        style={{ height: 38 }}
                                      >
                                        <span className="vc_empty_space_inner" />
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div
                                  className="wpb_column vc_column_container vc_col-sm-12 vc_col-lg-4 vc_col-md-12 vc_col-xs-12"
                                  style={{ clear: "left" }}
                                >
                                  <div className="vc_column-inner">
                                    <div className="wpb_wrapper">
                                      <div className="mkd-iwt clearfix  mkd-iwt-icon-left mkd-iwt-icon-medium">
                                        <div className="mkd-iwt-content">
                                          <DynamicHoverIconComponent 
                                            id="icon7-hover"
                                            defaultSrc="assets/services/7.svg"
                                            hoverSrc="assets/animated/7.svg"
                                            iconStyle={{ width: 100, height: 100 }}
                                          /> 
                                          <h4 className="mkd-iwt-title">
                                            Plumbing &amp; <br />
                                            Electrical
                                          </h4>
                                          <ul>
                                            <li>
                                              Expert plumbing and electrical
                                              services for seamless property
                                              functionality.
                                            </li>
                                          </ul>
                                        </div>
                                      </div>
                                      <div
                                        className="vc_empty_space"
                                        style={{ height: 38 }}
                                      >
                                        <span className="vc_empty_space_inner" />
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div className="wpb_column vc_column_container vc_col-sm-12 vc_col-lg-4 vc_col-md-12 vc_col-xs-12">
                                  <div className="vc_column-inner">
                                    <div className="wpb_wrapper">
                                      <div className="mkd-iwt clearfix  mkd-iwt-icon-left mkd-iwt-icon-medium">
                                        <div className="mkd-iwt-content">
                                          <DynamicHoverIconComponent 
                                            id="icon8-hover"
                                            defaultSrc="assets/services/8.svg"
                                            hoverSrc="assets/animated/8.svg"
                                            iconStyle={{ width: 100, height: 100 }}
                                          />
                                          <h4 className="mkd-iwt-title">
                                            Asset <br />
                                            Management
                                          </h4>
                                          <ul>
                                            <li>
                                              Effective asset management to prolong
                                              the lifespan of your property
                                              investments
                                            </li>
                                          </ul>
                                        </div>
                                      </div>
                                      <div
                                        className="vc_empty_space"
                                        style={{ height: 38 }}
                                      >
                                        <span className="vc_empty_space_inner" />
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div className="wpb_column vc_column_container vc_col-sm-12 vc_col-lg-4 vc_col-md-12 vc_col-xs-12">
                                  <div className="vc_column-inner">
                                    <div className="wpb_wrapper">
                                      <div className="mkd-iwt clearfix  mkd-iwt-icon-left mkd-iwt-icon-medium">
                                        <div className="mkd-iwt-content">
                                          <DynamicHoverIconComponent 
                                            id="icon9-hover"
                                            defaultSrc="assets/services/9.svg"
                                            hoverSrc="assets/animated/9.svg"
                                            iconStyle={{ width: 100, height: 100 }}
                                          />
                                          <h4 className="mkd-iwt-title">
                                            HVAC <br />
                                            Maintenance
                                          </h4>
                                          <ul>
                                            <li>
                                              Maintaining comfortable indoor
                                              environments with reliable HVAC system
                                              maintenance.
                                            </li>
                                          </ul>
                                        </div>
                                      </div>
                                      <div
                                        className="vc_empty_space"
                                        style={{ height: 38 }}
                                      >
                                        <span className="vc_empty_space_inner" />
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div className="wpb_column vc_column_container vc_col-sm-12 vc_col-lg-4 vc_col-md-12 vc_col-xs-12">
                                  <div className="vc_column-inner">
                                    <div className="wpb_wrapper">
                                      <div className="mkd-iwt clearfix  mkd-iwt-icon-left mkd-iwt-icon-medium">
                                        <div className="mkd-iwt-content">
                                          <DynamicHoverIconComponent 
                                            id="icon10-hover"
                                            defaultSrc="assets/services/10.svg"
                                            hoverSrc="assets/animated/10.svg"
                                            iconStyle={{ width: 100, height: 100 }}
                                          />
                                          <h4 className="mkd-iwt-title">
                                            Eco-friendly <br />
                                            Practices
                                          </h4>
                                          <ul>
                                            <li>
                                              Commitment to sustainability with
                                              strategic planning for eco-friendly
                                              practices.
                                            </li>
                                          </ul>
                                        </div>
                                      </div>
                                      <div
                                        className="vc_empty_space"
                                        style={{ height: 38 }}
                                      >
                                        <span className="vc_empty_space_inner" />
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div className="wpb_column vc_column_container vc_col-sm-12 vc_col-lg-4 vc_col-md-12 vc_col-xs-12">
                                  <div className="vc_column-inner">
                                    <div className="wpb_wrapper">
                                      <div className="mkd-iwt clearfix  mkd-iwt-icon-left mkd-iwt-icon-medium">
                                        <div className="mkd-iwt-content">
                                          <DynamicHoverIconComponent 
                                            id="icon11-hover"
                                            defaultSrc="assets/services/11.svg"
                                            hoverSrc="assets/animated/11.svg"
                                            iconStyle={{ width: 100, height: 100 }}
                                          />
                                          <h4 className="mkd-iwt-title">
                                            Landscaping <br />
                                            Services
                                          </h4>
                                          <ul>
                                            <li>
                                              Enhancing outdoor aesthetics with
                                              professional landscaping services
                                              &amp; adding to sustainability.
                                            </li>
                                          </ul>
                                        </div>
                                      </div>
                                      <div
                                        className="vc_empty_space"
                                        style={{ height: 38 }}
                                      >
                                        <span className="vc_empty_space_inner" />
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div className="wpb_column vc_column_container vc_col-sm-12 vc_col-lg-4 vc_col-md-12 vc_col-xs-12">
                                  <div className="vc_column-inner">
                                    <div className="wpb_wrapper">
                                      <div className="mkd-iwt clearfix  mkd-iwt-icon-left mkd-iwt-icon-medium">
                                        <div className="mkd-iwt-content">
                                          <DynamicHoverIconComponent 
                                            id="icon12-hover"
                                            defaultSrc="assets/services/12.svg"
                                            hoverSrc="assets/animated/12.svg"
                                            iconStyle={{ width: 100, height: 100 }}
                                          />
                                          <h4 className="mkd-iwt-title">
                                            Grievance <br />
                                            Redressal
                                          </h4>
                                          <ul>
                                            <li>
                                              Proactively addressing customer
                                              grievances to ensure satisfaction and
                                              peace of mind.
                                            </li>
                                          </ul>
                                        </div>
                                      </div>
                                      <div
                                        className="vc_empty_space"
                                        style={{ height: 38 }}
                                      >
                                        <span className="vc_empty_space_inner" />
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </section>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

  </>;
};

export default Services;