import React, { useState, useEffect } from 'react';

const EnquiryForm = ({ clickedButton }) => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phone: '',
  });
  const [errors, setErrors] = useState({});

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Perform client-side validation
    const validationErrors = {};
    if (!formData.name) {
      validationErrors.name = 'Name is required';
    }
    if (!formData.email) {
      validationErrors.email = 'Email is required';
    } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
      validationErrors.email = 'Invalid email format';
    }
    if (!formData.phone) {
      validationErrors.phone = 'Phone is required';
    } else if (!/^\d+$/.test(formData.phone)) {
      validationErrors.phone = 'Phone must contain only digits';
    }

    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      return;
    }

    try {
      // If validation passes, submit the form
      const response = await fetch('https://newry.co.in/test-optima1/submit_form.php', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData),
      });
      const data = await response.text();

      // Trigger PDF download based on clicked button
      if (clickedButton === 'Astor Brochure') {
        window.open('https://newry.co.in/assets/pdf/astor_floorplan.pdf', '_blank');
      } else if (clickedButton === 'Astor Master Plan') {
        window.open('https://newry.co.in/assets/pdf/astor-map.pdf', '_blank');
      } else if (clickedButton === 'Astor Floor Plan') {
        window.open('https://newry.co.in/assets/pdf/astor_floorplan.pdf', '_blank');
      }else if (clickedButton === 'Adora Brochure') {
        window.open('https://newry.co.in/assets/pdf/astor-map.pdf', '_blank');
      } else if (clickedButton === 'Adora Floor Plan') {
        window.open('https://newry.co.in/assets/pdf/adora_floorplan.pdf', '_blank');
      }else if (clickedButton === 'Adora Master Plan') {
        window.open('https://newry.co.in/assets/pdf/astor-map.pdf', '_blank');
      }
    } catch (error) {
      console.error('Error submitting form data:', error);
    }
  };

  return (
    <div className="wpb_column vc_column_container vc_col-sm-12 vc_col-lg-6 vc_col-md-6 vc_col-xs-12">
      <div className="vc_column-inner">
        <div className="wpb_wrapper">
          <div className="mkd-elements-holder mkd-one-column mkd-responsive-mode-768">
            <div className="mkd-eh-item mkd-element-from-bottom" data-item-class="mkd-eh-custom-5789" data-animation="mkd-element-from-bottom" data-1280-1600="30px 0 0 5px" data-1024-1280="30px 0 0 65px" data-768-1024="30px 0 0 0" data-680-768="30px 0 0 0" data-680="30px 0 0 0">
              <div className="mkd-eh-item-inner">
                <div className="mkd-eh-item-content mk6eh-custom-5789">
                  <div role="form" className="wpcf7" id="wpcf7-f1911-p1871-o2" lang="en-US" dir="ltr">
                    <div className="screen-reader-response">
                      <p role="status" aria-live="polite" aria-atomic="true"></p>
                      <ul></ul>
                    </div>
                    <form style={{ marginTop: '40px' }} method="post" className="wpcf7-form init demo" noValidate="novalidate" data-status="init" onSubmit={handleSubmit}>
                      <div className="mkd-cf7-contact-form">
                        <span className="wpcf7-form-control-wrap" data-name="name">
                          <input type="text" name="name" value={formData.name} onChange={handleChange} size={40} className="wpcf7-form-control wpcf7-text wpcf7-validates-as-required" aria-required="true" aria-invalid="false" placeholder="Name" />
                        </span>
                        <br />
                        {errors.name && <span className="error-message">{errors.name}</span>}
                        <span className="wpcf7-form-control-wrap" data-name="email-333">
                          <input type="email" name="email" value={formData.email} onChange={handleChange} size={40} className="wpcf7-form-control wpcf7-text wpcf7-email wpcf7-validates-as-email" aria-invalid="false" placeholder="E-mail" />
                        </span>
                        <br />
                        {errors.email && <span className="error-message">{errors.email}</span>}
                        <span className="wpcf7-form-control-wrap" data-name="email-333">
                          <input type="text" name="phone" value={formData.phone} onChange={handleChange} size={40} className="wpcf7-form-control wpcf7-text wpcf7-email wpcf7-validates-as-email" aria-invalid="false" placeholder="Phone" />
                        </span>
                        <br />
                        {errors.phone && <span className="error-message">{errors.phone}</span>}
                        <span className="mkd-submit-button-wrapper">
                          <br />
                          <input type="submit" defaultValue="Submit" className="wpcf7-form-control has-spinner wpcf7-submit" />
                          <span className="mkd-submit-btn-icon" />
                          <br />
                        </span>
                      </div>
                      <div className="wpcf7-response-output" aria-hidden="true"></div>
                    </form>
                    <br />
                    <br />
                    <br />
                    <br />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EnquiryForm;