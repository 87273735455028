const Commercial = () => {
    return <>
    <link rel="stylesheet" href="css/Commercial.module.css" />
        <div className="mkd-content">
            {/*SLIDER START*/}
            <div className="mkd-ps-image-holder">
                <div className="mkd-ps-image mkd-owl-slider">
                    <div className="mkd-ps-image">
                        <picture>
                            <source media="(min-width: 481px)" srcSet="assets/commercial/banner.jpg" />
                            <source
                                media="(max-width: 480px)"
                                srcSet="assets/commercial/banner-mobile.jpg"
                            />
                            <img
                                className="d-block w-100"
                                src="assets/commercial/banner-mobile.jpg"
                                alt="Banner"
                            />
                        </picture>
                        {/*<div class="banner-description">*/}
                        {/*   <h2>Leadersh</h2>*/}
                        {/*   <h5>A Perfect Lifestyle </h5> */}
                        {/*</div> */}
                    </div>
                </div>
            </div>
            {/*SLIDER END*/}
            <div className="vc_empty_space" style={{ height: 60 }}>
                <span className="vc_empty_space_inner" />
            </div>
            <div className="mkd-content-inner">
                <div className="mkd-full-width">
                    <div className="mkd-full-width-inner">
                        <div className="mkd-grid-row">
                            <div className="mkd-page-content-holder mkd-grid-col-12">
                                <section className="wpb-content-wrapper location-advantages">
                                    <div className="mkd-row-grid-section-wrapper ">
                                        <div className="mkd-row-grid-section">
                                            <div className="vc_row wpb_row vc_row-fluid vc_custom_1516021087353">
                                                <div className="wpb_column vc_column_container vc_col-sm-12">
                                                    <div className="vc_column-inner vc_custom_1516007647796">
                                                        <div className="wpb_wrapper">
                                                            <div className="mkd-elements-holder   mkd-one-column  mkd-responsive-mode-768 ">
                                                                <div
                                                                    className="mkd-eh-item    "
                                                                    data-item-class="mkd-eh-custom-8773"
                                                                    data-1280-1600="0 0 20px 0"
                                                                    data-1024-1280="0 0 20px 0"
                                                                    data-768-1024="0 0 20px 0"
                                                                    data-680-768="0 0 20px 0"
                                                                    data-680="0px"
                                                                >
                                                                    <div className="mkd-eh-item-inner">
                                                                        <div
                                                                            className="mkd-eh-item-content mkd-eh-custom-8773"
                                                                            style={{ padding: "0 0 20px 0" }}
                                                                        >
                                                                            <div className="mkd-section-title-holder  ">
                                                                                <div className="mkd-st-inner road">
                                                                                    <h2 className="mkd-st-title">
                                                                                        Commercial Projects
                                                                                    </h2>
                                                                                    <div
                                                                                        className="vc_empty_space"
                                                                                        style={{ height: 40 }}
                                                                                    >
                                                                                        <span className="vc_empty_space_inner" />
                                                                                    </div>
                                                                                    <p style={{ textAlign: "center" }}>
                                                                                        Explore tailored property solutions at
                                                                                        Newry Builder, where we specialize in
                                                                                        crafting spaces to suit your unique
                                                                                        business needs. From retail to office
                                                                                        spaces, our offerings cater to diverse
                                                                                        requirements. Elevate your ventures with
                                                                                        our commitment to excellence and client
                                                                                        satisfaction.{" "}
                                                                                    </p>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </section>
                                <div className="vc_empty_space" style={{ height: 40 }}>
                                    <span className="vc_empty_space_inner" />
                                </div>
                                <div className="mkd-row-grid-section-wrapper ">
                                    <div className="mkd-row-grid-section">
                                        <div className="vc_row wpb_row vc_row-fluid vc_custom_1515425824843 vc_row-o-equal-height vc_row-flex">
                                            <div className="wpb_column vc_column_container vc_col-sm-6">
                                                <div className="vc_column-inner vc_custom_1515423400287">
                                                    <div className="wpb_wrapper">
                                                        <div className="mkd-elements-holder   mkd-one-column  mkd-responsive-mode-768 ">
                                                            <div
                                                                className="mkd-eh-item    "
                                                                data-item-class="mkd-eh-custom-4736"
                                                                data-1280-1600="0 18px 0 17px"
                                                                data-1024-1280="0 18px 0 17px"
                                                                data-768-1024="0 18px 0 6px"
                                                                data-680-768="0 18px 0 2px"
                                                                data-680="0 0 20px 0"
                                                            >
                                                                <div className="mkd-eh-item-inner">
                                                                    <div
                                                                        className="mkd-eh-item-content mkd-eh-custom-4736"
                                                                        style={{ padding: "0 18px 0 20px" }}
                                                                    >
                                                                        <div className="mkd-single-image-holder  mkd-image-behavior-lightbox   ">
                                                                            <div className="mkd-si-inner">
                                                                                <a
                                                                                    itemProp="image"
                                                                                    href="#"
                                                                                    data-rel="prettyPhoto[si_pretty_photo]"
                                                                                    title="Goldy vase"
                                                                                >
                                                                                    <img
                                                                                        width="100%"
                                                                                        src="assets/commercial/grandeur.jpg"
                                                                                        className="attachment-full size-full"
                                                                                        alt="Goldy vase"
                                                                                        loading="lazy"
                                                                                    />
                                                                                </a>
                                                                                <h3 className="certification-heading">
                                                                                    Newry Grandeur
                                                                                </h3>
                                                                                <h3 className="certification-title">
                                                                                    Anna Nagar
                                                                                </h3>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="wpb_column vc_column_container vc_col-sm-6">
                                                <div className="vc_column-inner vc_custom_1515423400287">
                                                    <div className="wpb_wrapper">
                                                        <div className="mkd-elements-holder   mkd-one-column  mkd-responsive-mode-768 ">
                                                            <div
                                                                className="mkd-eh-item    "
                                                                data-item-class="mkd-eh-custom-4736"
                                                                data-1280-1600="0 18px 0 17px"
                                                                data-1024-1280="0 18px 0 17px"
                                                                data-768-1024="0 18px 0 6px"
                                                                data-680-768="0 18px 0 2px"
                                                                data-680="0 0 20px 0"
                                                            >
                                                                <div className="mkd-eh-item-inner">
                                                                    <div
                                                                        className="mkd-eh-item-content mkd-eh-custom-4736"
                                                                        style={{ padding: "0 18px 0 20px" }}
                                                                    >
                                                                        <div className="mkd-single-image-holder  mkd-image-behavior-lightbox   ">
                                                                            <div className="mkd-si-inner">
                                                                                <a
                                                                                    itemProp="image"
                                                                                    href="#"
                                                                                    data-rel="prettyPhoto[si_pretty_photo]"
                                                                                    title="Goldy vase"
                                                                                >
                                                                                    <img
                                                                                        width="100%"
                                                                                        src="assets/commercial/tower.jpg"
                                                                                        className="attachment-full size-full"
                                                                                        alt="Goldy vase"
                                                                                        loading="lazy"
                                                                                    />
                                                                                </a>
                                                                                <h3 className="certification-heading">
                                                                                    Newry Tower
                                                                                </h3>
                                                                                <h3 className="certification-title">
                                                                                    Anna Nagar
                                                                                </h3>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </>;
};

export default Commercial;