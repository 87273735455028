import React, { useState, useEffect } from 'react';


const CareerForm = ({ formText }) => {
  
  useEffect(() => {
    setFormData(prevData => ({
      ...prevData,
      position: formText
    }));
  }, [formText]);

  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phone: '',
    position: '',
    designation: '',
    experience: '',
    resume: null,
  });

  const [formErrors, setFormErrors] = useState({});
  const [formSuccess, setFormSuccess] = useState('');

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleFileChange = (e) => {
    setFormData({
      ...formData,
      resume: e.target.files[0],
    });
  };

  const validateForm = () => {
    let errors = {};
    if (!formData.name) errors.name = 'Name is required';
    if (!formData.email) errors.email = 'Email is required';
    if (!formData.phone) errors.phone = 'Phone is required';
    if (!formData.position) errors.position = 'Applying position is required';
    if (!formData.designation) errors.designation = 'Current designation is required';
    if (!formData.experience) errors.experience = 'Relevant experience is required';
    if (!formData.resume) {
      errors.resume = 'Resume is required';
    } else {
      const fileExtension = formData.resume.name.split('.').pop().toLowerCase();
      const allowedExtensions = ['pdf', 'docx', 'doc'];
      if (!allowedExtensions.includes(fileExtension)) {
        errors.resume = 'Invalid file type. Only pdf, docx, doc are allowed';
      }
      if (formData.resume.size > 5 * 1024 * 1024) {
        errors.resume = 'File size should not exceed 5MB';
      }
    }
    return errors;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const errors = validateForm();
    setFormErrors(errors);

    if (Object.keys(errors).length === 0) {
      const formDataToSend = new FormData();
      for (let key in formData) {
        formDataToSend.append(key, formData[key]);
      }
      fetch('https://newry.co.in/test-optima1/careers_submit.php', {
        method: 'POST',
        body: formDataToSend,
      })
        .then((response) => response.text())
        .then((data) => {
          setFormSuccess(data);
        })
        .catch((error) => {
          console.error('Error:', error);
        });
    }
  };
  return (
    <div className="wpb_wrapper">
      <form onSubmit={handleSubmit} className="wpcf7-form init demo" noValidate data-status="init" enctype='multipart/form-data'>
        <div className="vc_row wpb_row vc_inner vc_row-fluid">
          <div className="wpb_column vc_column_container vc_col-sm-12 vc_col-lg-6 vc_col-md-6 vc_col-xs-12">
            <div className="vc_column-inner">
              <div className="wpb_wrapper">
                <div className="mkd-elements-holder mkd-one-column mkd-responsive-mode-768">
                  <div className="mkd-eh-item mkd-element-from-bottom" data-item-class="mkd-eh-custom-8629" data-animation="mkd-element-from-bottom" data-1280-1600="30px 0 0 8px" data-1024-1280="30px 0 0 8px" data-768-1024="30px 0 0 8px" data-680-768="30px 0 0 8px" data-680="30px 0 0 8px">
                    <div className="mkd-eh-item-inner">
                      <div className="mkd-eh-item-content mkd-eh-custom-8629" style={{ padding: "30px 0 0 8px !important" }}>
                        <div className="mkd-cf7-contact-form">
                          <span className="wpcf7-form-control-wrap" data-name="name">
                            <input
                              type="text"
                              name="name"
                              value={formData.name}
                              onChange={handleInputChange}
                              size={40}
                              className={`wpcf7-form-control wpcf7-text wpcf7-validates-as-required ${formErrors.name ? 'error' : ''}`}
                              aria-required="true"
                              placeholder="Name*"
                            />
                          </span>
                          {formErrors.name && <span className="error">{formErrors.name}</span>}
                          <br />
                          <span className="wpcf7-form-control-wrap" data-name="email">
                            <input
                              type="email"
                              name="email"
                              value={formData.email}
                              onChange={handleInputChange}
                              size={40}
                              className={`wpcf7-form-control wpcf7-text wpcf7-email wpcf7-validates-as-email ${formErrors.email ? 'error' : ''}`}
                              placeholder="E-mail*"
                            />
                          </span>
                          {formErrors.email && <span className="error">{formErrors.email}</span>}
                          <br />
                          <span className="wpcf7-form-control-wrap" data-name="phone">
                            <input
                              type="text"
                              name="phone"
                              value={formData.phone}
                              onChange={handleInputChange}
                              size={40}
                              className={`wpcf7-form-control wpcf7-text ${formErrors.phone ? 'error' : ''}`}
                              placeholder="Phone*"
                            />
                          </span>
                          {formErrors.phone && <span className="error">{formErrors.phone}</span>}
                          <br />
                          <span className="wpcf7-form-control-wrap" data-name="position">
                            <input
                              type="text"
                              name="position"
                              value={formData.position}
                              onChange={handleInputChange}
                              size={40}
                              className={`wpcf7-form-control wpcf7-text ${formErrors.position ? 'error' : ''}`}
                              placeholder="Applying position*"
                            />
                          </span>
                          {formErrors.position && <span className="error">{formErrors.position}</span>}
                          <br />
                        </div>
                        <div className="wpcf7-response-output" aria-hidden="true"></div>
                        <div className="vc_empty_space" style={{ height: 28 }}>
                          <span className="vc_empty_space_inner"></span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="wpb_column vc_column_container vc_col-sm-12 vc_col-lg-6 vc_col-md-6 vc_col-xs-12">
            <div className="vc_column-inner">
              <div className="wpb_wrapper">
                <div className="mkd-elements-holder mkd-one-column mkd-responsive-mode-768">
                  <div className="mkd-eh-item mkd-element-from-bottom" data-item-class="mkd-eh-custom-5789" data-animation="mkd-element-from-bottom" data-1280-1600="218px 0 0 5px" data-1024-1280="217px 0 0 65px" data-768-1024="210px 0 0 0" data-680-768="160px 0 0 0" data-680="90px 0 0 0">
                    <div className="mkd-eh-item-inner">
                      <div className="mkd-eh-item-content mk6eh-custom-5789" style={{ padding: "30px 0 0 5px !important" }}>
                        <div role="form" className="wpcf7" id="wpcf7-f1911-p1871-o2" lang="en-US" dir="ltr">
                          <div className="screen-reader-response">
                            <p role="status" aria-live="polite" aria-atomic="true"></p>
                            <ul></ul>
                          </div>
                          <div className="mkd-cf7-contact-form">
                            <span className="wpcf7-form-control-wrap" data-name="designation">
                              <input
                                type="text"
                                name="designation"
                                value={formData.designation}
                                onChange={handleInputChange}
                                size={40}
                                className={`wpcf7-form-control wpcf7-text ${formErrors.designation ? 'error' : ''}`}
                                placeholder="Currrent Designation*"
                              />
                            </span>
                            {formErrors.designation && <span className="error">{formErrors.designation}</span>}
                            <br />
                            <span className="wpcf7-form-control-wrap" data-name="experience">
                              <input
                                type="text"
                                name="experience"
                                value={formData.experience}
                                onChange={handleInputChange}
                                size={40}
                                className={`wpcf7-form-control wpcf7-text ${formErrors.experience ? 'error' : ''}`}
                                placeholder="Relevant Experience*"
                              />
                            </span>
                            {formErrors.experience && <span className="error">{formErrors.experience}</span>}
                            <br />
                            <span className="wpcf7-form-control-wrap" data-name="resume">
                              <input
                                type="file"
                                name="resume"
                                onChange={handleFileChange}
                                size={40}
                                className={`wpcf7-form-control wpcf7-text ${formErrors.resume ? 'error' : ''}`}
                              />
                              *Document Mandate: pdf, docx, doc only | Max file size: 5MB
                            </span>
                            {formErrors.resume && <span className="error">{formErrors.resume}</span>}
                            <br />
                            <span className="mkd-submit-button-wrapper">
                              <br />
                              <input
                                type="submit"
                                value="Apply"
                                className="wpcf7-form-control has-spinner wpcf7-submit hide-mobile"
                              />
                              <span className="mkd-submit-btn-icon"></span>
                              <br />
                            </span>
                            {formSuccess && <div className="form-success">{formSuccess}</div>}
                          </div>
                          <div className="wpcf7-response-output" aria-hidden="true"></div>
                          <br />
                          <br />
                          <br />
                          <br />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
       
      </form>
      <div className="mkd-elements-holder mkd-one-column mkd-responsive-mode-768"></div>
    </div>
  );
};

export default CareerForm;