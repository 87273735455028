const Founders = () => {
    return <>
    <link rel="stylesheet" href="css/Founders.module.css" />
        <div className="mkd-content">
            <div className="mkd-ps-image-holder">
                <div className="mkd-ps-image mkd-owl-slider">
                    <div className="mkd-ps-image">
                        <picture>
                            <source media="(min-width: 481px)" srcSet="assets/freepik/8.jpg" />
                            <source media="(max-width: 480px)" srcSet="assets/freepik/9.jpg" />
                            <img
                                className="d-block w-100"
                                src="assets/freepik/9.jpg"
                                alt="Banner"
                            />
                        </picture>
                    </div>
                </div>
            </div>
            <div className="vc_empty_space" style={{ height: 20 }}>
                <span className="vc_empty_space_inner" />
            </div>
            <div className="mkd-content-inner">
                <div className="mkd-full-width">
                    <div className="mkd-full-width-inner">
                        <div className="mkd-grid-row">
                            <div className="mkd-page-content-holder mkd-grid-col-12">
                                <section className="wpb-content-wrapper">
                                    <div className="mkd-row-grid-section-wrapper ">
                                        <div className="mkd-row-grid-section">
                                            <div className="vc_row wpb_row vc_row-fluid vc_custom_1517921277402">
                                                <div className="wpb_column vc_column_container vc_col-sm-12">
                                                    <div className="vc_column-inner">
                                                        <div className="wpb_wrapper">
                                                            <div
                                                                className="mkd-portfolio-list-holder mkd-pl-gallery mkd-huge-space mkd-pl-one-column mkd-pl-floating-portfolio-item    mkd-pl-pag-no-pagination     "
                                                                data-type="gallery"
                                                                data-number-of-columns={1}
                                                                data-space-between-items="huge"
                                                                data-number-of-items={3}
                                                                data-image-proportions="full"
                                                                data-enable-fixed-proportions="no"
                                                                data-enable-image-shadow="no"
                                                                data-category="about-project"
                                                                data-orderby="date"
                                                                data-order="ASC"
                                                                data-item-style="floating-portfolio-item"
                                                                data-enable-title="yes"
                                                                data-title-tag="h3"
                                                                data-enable-category="yes"
                                                                data-category-tag="p"
                                                                data-enable-count-images="no"
                                                                data-enable-excerpt="yes"
                                                                data-excerpt-length={125}
                                                                data-pagination-type="no-pagination"
                                                                data-filter="no"
                                                                data-filter-order-by="name"
                                                                data-enable-article-animation="no"
                                                                data-enable-parallax-animation="no"
                                                                data-portfolio-slider-on="no"
                                                                data-enable-loop="yes"
                                                                data-enable-autoplay="yes"
                                                                data-slider-speed={5000}
                                                                data-slider-speed-animation={600}
                                                                data-enable-navigation="yes"
                                                                data-enable-pagination="yes"
                                                                data-max-num-pages={1}
                                                                data-next-page={2}
                                                            >
                                                                <div className="mkd-pl-inner mkd-outer-space  clearfix">
                                                                    <article className="mkd-pl-item mkd-item-space  post-1337 portfolio-item type-portfolio-item status-publish has-post-thumbnail hentry portfolio-category-about-project">
                                                                        <div className="mkd-pl-item-inner">
                                                                            <div className="mkd-pli-image-holder founder-img">
                                                                                <div className="mkd-pli-image">
                                                                                    <img
                                                                                        width={1100}
                                                                                        height={820}
                                                                                        src="assets/aboutus/founder.jpg"
                                                                                        className="attachment-full size-full wp-post-image"
                                                                                        alt="a"
                                                                                    />
                                                                                </div>
                                                                                <div
                                                                                    className="mkd-pli-image-drop-shadow"
                                                                                    style={{ backgroundColor: "#f2e9e9" }}
                                                                                ></div>
                                                                            </div>
                                                                            <div className="mkd-pli-text-holder">
                                                                                <div className="mkd-pli-text-wrapper">
                                                                                    <div className="mkd-pli-text">
                                                                                        <h3
                                                                                            itemProp="name"
                                                                                            className="mkd-pli-title entry-title"
                                                                                        >
                                                                                            Late Mr. Manu Makhija
                                                                                        </h3>
                                                                                        <div
                                                                                            className="mkd-pli-category-holder"
                                                                                            style={{ textAlign: "right" }}
                                                                                        >
                                                                                            <p>
                                                                                                <a
                                                                                                    itemProp="url"
                                                                                                    className="mkd-pli-category"
                                                                                                    href="#"
                                                                                                >
                                                                                                    Founder
                                                                                                </a>
                                                                                            </p>
                                                                                        </div>
                                                                                        <p
                                                                                            itemProp="description"
                                                                                            className="mkd-pli-excerpt"
                                                                                        >
                                                                                            The late Mr. Manu Makhija was a
                                                                                            luminary in real estate. He envisioned
                                                                                            Newry and its affiliates as more than
                                                                                            just developers. His passion extended
                                                                                            beyond crafting quality, affordable
                                                                                            homes. He was also driven by a noble
                                                                                            ambition to uplift communities through
                                                                                            education. His legacy continues to
                                                                                            inspire us.
                                                                                        </p>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </article>
                                                                    <article className="mkd-pl-item mkd-item-space  post-1336 portfolio-item type-portfolio-item status-publish has-post-thumbnail hentry portfolio-category-about-project">
                                                                        <div className="mkd-pl-item-inner">
                                                                            <div className="mkd-pli-image-holder director-img">
                                                                                <div className="mkd-pli-image">
                                                                                    <img
                                                                                        width={1100}
                                                                                        height={1530}
                                                                                        src="assets/aboutus/director2.jpg"
                                                                                        className="attachment-full size-full wp-post-image"
                                                                                        alt="a"
                                                                                        loading="lazy"
                                                                                    />
                                                                                </div>
                                                                                <div
                                                                                    className="mkd-pli-image-drop-shadow"
                                                                                    style={{ backgroundColor: "#c3c7d0" }}
                                                                                ></div>
                                                                            </div>
                                                                            <div className="mkd-pli-text-holder">
                                                                                <div className="mkd-pli-text-wrapper">
                                                                                    <div className="mkd-pli-text">
                                                                                        <h3
                                                                                            itemProp="name"
                                                                                            className="mkd-pli-title entry-title"
                                                                                        >
                                                                                            Sanjay Manohar Makhija
                                                                                        </h3>
                                                                                        <div
                                                                                            className="mkd-pli-category-holder manohar"
                                                                                            style={{ textAlign: "right" }}
                                                                                        >
                                                                                            <p>
                                                                                                <a
                                                                                                    itemProp="url"
                                                                                                    className="mkd-pli-category"
                                                                                                    href="#"
                                                                                                >
                                                                                                    Director
                                                                                                </a>
                                                                                            </p>
                                                                                        </div>
                                                                                        <p
                                                                                            itemProp="description"
                                                                                            className="mkd-pli-excerpt"
                                                                                        >
                                                                                            The Promoter/Director of Newry Group
                                                                                            Entities. He has a rare blend of
                                                                                            entrepreneurial spirit and astute
                                                                                            business acumen. Spearheads the
                                                                                            group's strategic initiatives, drives
                                                                                            growth and innovation in the
                                                                                            competitive real estate landscape.
                                                                                        </p>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </article>
                                                                    <article className="mkd-pl-item mkd-item-space  post-4460 portfolio-item type-portfolio-item status-publish has-post-thumbnail hentry portfolio-category-about-project">
                                                                        <div className="mkd-pl-item-inner">
                                                                            <div className="mkd-pli-image-holder director-img">
                                                                                <div className="mkd-pli-image">
                                                                                    <img
                                                                                        width={1100}
                                                                                        height={820}
                                                                                        src="assets/aboutus/director1.jpg"
                                                                                        className="attachment-full size-full wp-post-image"
                                                                                        alt="a"
                                                                                        loading="lazy"
                                                                                    />
                                                                                </div>
                                                                                <div className="mkd-pli-image-drop-shadow"></div>
                                                                            </div>
                                                                            <div className="mkd-pli-text-holder">
                                                                                <div className="mkd-pli-text-wrapper">
                                                                                    <div className="mkd-pli-text">
                                                                                        <h3
                                                                                            itemProp="name"
                                                                                            className="mkd-pli-title entry-title"
                                                                                        >
                                                                                            Vijay Manohar Makhija
                                                                                        </h3>
                                                                                        <div
                                                                                            className="mkd-pli-category-holder"
                                                                                            style={{ textAlign: "right" }}
                                                                                        >
                                                                                            <p>
                                                                                                <a
                                                                                                    itemProp="url"
                                                                                                    className="mkd-pli-category"
                                                                                                    href="#"
                                                                                                >
                                                                                                    Director
                                                                                                </a>
                                                                                            </p>
                                                                                        </div>
                                                                                        <p
                                                                                            itemProp="description"
                                                                                            className="mkd-pli-excerpt"
                                                                                        >
                                                                                            The Promoter/Director of Newry Group
                                                                                            Entities. Vijay Makhija, Director at
                                                                                            Newry Group, played a pivotal role in
                                                                                            the growth of Newry. He expanded into
                                                                                            real estate and diverse investments,
                                                                                            including a venture into the charter
                                                                                            flight industry.
                                                                                        </p>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </article>
                                                                    <article className="mkd-pl-item mkd-item-space  post-1336 portfolio-item type-portfolio-item status-publish has-post-thumbnail hentry portfolio-category-about-project">
                                                                        <div className="mkd-pl-item-inner">
                                                                            <div className="mkd-pli-image-holder director-img">
                                                                                <div className="mkd-pli-image">
                                                                                    <img
                                                                                        width={1100}
                                                                                        height={1530}
                                                                                        src="assets/aboutus/director3.jpg"
                                                                                        className="attachment-full size-full wp-post-image"
                                                                                        alt="a"
                                                                                        loading="lazy"
                                                                                    />
                                                                                </div>
                                                                                <div
                                                                                    className="mkd-pli-image-drop-shadow"
                                                                                    style={{ backgroundColor: "#c3c7d0" }}
                                                                                ></div>
                                                                            </div>
                                                                            <div className="mkd-pli-text-holder">
                                                                                <div className="mkd-pli-text-wrapper">
                                                                                    <div className="mkd-pli-text">
                                                                                        <h3
                                                                                            itemProp="name"
                                                                                            className="mkd-pli-title entry-title"
                                                                                        >
                                                                                            Sanjeev GopalaKrishnan
                                                                                        </h3>
                                                                                        <div
                                                                                            className="mkd-pli-category-holder manohar"
                                                                                            style={{ textAlign: "right" }}
                                                                                        >
                                                                                            <p>
                                                                                                <a
                                                                                                    itemProp="url"
                                                                                                    className="mkd-pli-category"
                                                                                                    href="#"
                                                                                                >
                                                                                                    Director
                                                                                                </a>
                                                                                            </p>
                                                                                        </div>
                                                                                        <p
                                                                                            itemProp="description"
                                                                                            className="mkd-pli-excerpt"
                                                                                        >
                                                                                            Sanjeev GopalaKrishnan is an astute
                                                                                            Director shaping Newry Group's
                                                                                            strategic vision. He emphasizes
                                                                                            excellence in project execution and
                                                                                            growth.
                                                                                        </p>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </article>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </section>
                                <br />
                                <section className="wpb-content-wrapper location-advantages">
                                    <div className="mkd-row-grid-section-wrapper ">
                                        <div className="mkd-row-grid-section">
                                            <div className="vc_row wpb_row vc_row-fluid vc_custom_1516021087353">
                                                <div className="wpb_column vc_column_container vc_col-sm-12">
                                                    <div className="vc_column-inner vc_custom_1516007647796">
                                                        <div className="wpb_wrapper">
                                                            <div className="mkd-elements-holder   mkd-one-column  mkd-responsive-mode-768 ">
                                                                <div
                                                                    className="mkd-eh-item    "
                                                                    data-item-class="mkd-eh-custom-8773"
                                                                    data-1280-1600="0 0 20px 0"
                                                                    data-1024-1280="0 0 20px 0"
                                                                    data-768-1024="0 0 20px 0"
                                                                    data-680-768="0 0 20px 0"
                                                                    data-680="0px"
                                                                >
                                                                    <div className="mkd-eh-item-inner">
                                                                        <div
                                                                            className="mkd-eh-item-content mkd-eh-custom-8773"
                                                                            style={{ padding: "0 0 20px 0" }}
                                                                        >
                                                                            <div className="mkd-section-title-holder  ">
                                                                                <div className="mkd-st-inner">
                                                                                    <h2 className="mkd-st-title">
                                                                                        Core Team
                                                                                    </h2>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </section>
                                <br />
                                <br />
                                <div className="mkd-full-width core-team">
                                    <div className="mkd-full-width-inner">
                                        <div className="mkd-grid-row">
                                            <div className="mkd-page-content-holder mkd-grid-col-12">
                                                <section className="wpb-content-wrapper">
                                                    <div className="mkd-row-grid-section-wrapper ">
                                                        <div className="mkd-row-grid-section">
                                                            <div className="vc_row wpb_row vc_row-fluid vc_custom_1517919743643">
                                                                <div className="wpb_column vc_column_container vc_col-sm-12">
                                                                    <div className="vc_column-inner">
                                                                        <div className="wpb_wrapper">
                                                                            <div
                                                                                className="mkd-portfolio-list-holder mkd-pl-gallery mkd-normal-space mkd-pl-three-columns mkd-pl-standard-shader    mkd-pl-pag-no-pagination     "
                                                                                data-type="gallery"
                                                                                data-number-of-columns={3}
                                                                                data-space-between-items="normal"
                                                                                data-number-of-items={9}
                                                                                data-image-proportions="full"
                                                                                data-enable-fixed-proportions="no"
                                                                                data-enable-image-shadow="no"
                                                                                data-category="visualization"
                                                                                data-orderby="date"
                                                                                data-order="ASC"
                                                                                data-item-style="standard-shader"
                                                                                data-enable-title="yes"
                                                                                data-title-tag="h4"
                                                                                data-enable-category="yes"
                                                                                data-category-tag="p"
                                                                                data-enable-count-images="yes"
                                                                                data-enable-excerpt="no"
                                                                                data-excerpt-length={20}
                                                                                data-pagination-type="no-pagination"
                                                                                data-filter="no"
                                                                                data-filter-order-by="name"
                                                                                data-enable-article-animation="no"
                                                                                data-portfolio-slider-on="no"
                                                                                data-enable-loop="yes"
                                                                                data-enable-autoplay="yes"
                                                                                data-slider-speed={5000}
                                                                                data-slider-speed-animation={600}
                                                                                data-enable-navigation="yes"
                                                                                data-enable-pagination="yes"
                                                                                data-max-num-pages={3}
                                                                                data-next-page={2}
                                                                            >
                                                                                <div className="mkd-pl-inner mkd-outer-space  clearfix">
                                                                                    <article className="mkd-pl-item mkd-item-space  post-1617 portfolio-item type-portfolio-item status-publish has-post-thumbnail hentry portfolio-category-visualization">
                                                                                        <div className="mkd-pl-item-inner">
                                                                                            <div className="mkd-pli-image">
                                                                                                <img
                                                                                                    width={800}
                                                                                                    height={573}
                                                                                                    src="assets/Soundrapandian.jpg"
                                                                                                    className="attachment-full size-full wp-post-image"
                                                                                                    alt="a"
                                                                                                />
                                                                                            </div>
                                                                                            <div className="mkd-pli-text-holder">
                                                                                                <div className="mkd-pli-text-wrapper">
                                                                                                    <div className="mkd-pli-text">
                                                                                                        <h4
                                                                                                            itemProp="name"
                                                                                                            className="mkd-pli-title entry-title"
                                                                                                        >
                                                                                                            Soundrapandian D
                                                                                                        </h4>
                                                                                                        <div className="mkd-pli-category-holder">
                                                                                                            <p>
                                                                                                                <a
                                                                                                                    itemProp="url"
                                                                                                                    className="mkd-pli-category"
                                                                                                                >
                                                                                                                    Vice President Legal &amp;
                                                                                                                    Operations
                                                                                                                </a>
                                                                                                            </p>
                                                                                                            <p>
                                                                                                                A seasoned legal expert with
                                                                                                                over 29 years of experience
                                                                                                                in real estate, corporate,
                                                                                                                and banking sectors.
                                                                                                            </p>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </article>
                                                                                    {/* <article className="mkd-pl-item mkd-item-space  post-1618 portfolio-item type-portfolio-item status-publish has-post-thumbnail hentry portfolio-category-visualization">
                                                                                        <div className="mkd-pl-item-inner">
                                                                                            <div className="mkd-pli-image">
                                                                                                <img
                                                                                                    width={800}
                                                                                                    height={573}
                                                                                                    src="assets/ravishankar.jpg"
                                                                                                    className="attachment-full size-full wp-post-image"
                                                                                                    alt="a"
                                                                                                    loading="lazy"
                                                                                                />
                                                                                            </div>
                                                                                            <div className="mkd-pli-text-holder">
                                                                                                <div className="mkd-pli-text-wrapper">
                                                                                                    <div className="mkd-pli-text">
                                                                                                        <h4
                                                                                                            itemProp="name"
                                                                                                            className="mkd-pli-title entry-title"
                                                                                                        >
                                                                                                            Ravishankar B
                                                                                                        </h4>
                                                                                                        <div className="mkd-pli-category-holder">
                                                                                                            <p>
                                                                                                                <a
                                                                                                                    itemProp="url"
                                                                                                                    className="mkd-pli-category"
                                                                                                                >
                                                                                                                    Finance Consultant
                                                                                                                </a>
                                                                                                            </p>
                                                                                                            <p>
                                                                                                                A global finance veteran and
                                                                                                                Chartered Accountant with
                                                                                                                over 35 years of expertise
                                                                                                                in finance and real estate.
                                                                                                            </p>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </article> */}
                                                                                    <article className="mkd-pl-item mkd-item-space  post-4468 portfolio-item type-portfolio-item status-publish has-post-thumbnail hentry portfolio-category-visualization">
                                                                                        <div className="mkd-pl-item-inner">
                                                                                            <div className="mkd-pli-image">
                                                                                                <img
                                                                                                    width={800}
                                                                                                    height={573}
                                                                                                    src="assets/kavithaa.jpg"
                                                                                                    className="attachment-full size-full wp-post-image"
                                                                                                    alt="a"
                                                                                                    loading="lazy"
                                                                                                />
                                                                                            </div>
                                                                                            <div className="mkd-pli-text-holder">
                                                                                                <div className="mkd-pli-text-wrapper">
                                                                                                    <div className="mkd-pli-text">
                                                                                                        <h4
                                                                                                            itemProp="name"
                                                                                                            className="mkd-pli-title entry-title"
                                                                                                        >
                                                                                                            Kavithaa Suresh
                                                                                                        </h4>
                                                                                                        <div className="mkd-pli-category-holder">
                                                                                                            <p>
                                                                                                                <a
                                                                                                                    itemProp="url"
                                                                                                                    className="mkd-pli-category"
                                                                                                                >
                                                                                                                    Director of Finance
                                                                                                                </a>
                                                                                                            </p>
                                                                                                            <p>
                                                                                                                A Chartered Accountant. She
                                                                                                                has over 10+ years of experience
                                                                                                                from Big Four firms. She
                                                                                                                brings financial acumen to
                                                                                                                Newry's leadership.
                                                                                                            </p>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </article>
                                                                                    <article className="mkd-pl-item mkd-item-space  post-1620 portfolio-item type-portfolio-item status-publish has-post-thumbnail hentry portfolio-category-visualization">
                                                                                        <div className="mkd-pl-item-inner">
                                                                                            <div className="mkd-pli-image">
                                                                                                <img
                                                                                                    width={800}
                                                                                                    height={573}
                                                                                                    src="assets/Mahendiran.jpg"
                                                                                                    className="attachment-full size-full wp-post-image"
                                                                                                    alt="a"
                                                                                                    loading="lazy"
                                                                                                />
                                                                                            </div>
                                                                                            <div className="mkd-pli-text-holder">
                                                                                                <div className="mkd-pli-text-wrapper">
                                                                                                    <div className="mkd-pli-text">
                                                                                                        <h4
                                                                                                            itemProp="name"
                                                                                                            className="mkd-pli-title entry-title"
                                                                                                        >
                                                                                                            Mahendiran
                                                                                                        </h4>
                                                                                                        <div className="mkd-pli-category-holder">
                                                                                                            <p>
                                                                                                                <a
                                                                                                                    itemProp="url"
                                                                                                                    className="mkd-pli-category"
                                                                                                                >
                                                                                                                    General Manager - Projects
                                                                                                                </a>
                                                                                                            </p>
                                                                                                            <p>
                                                                                                                20+ years of construction
                                                                                                                expertise with involvement
                                                                                                                in projects from SEZ
                                                                                                                development for Bharath
                                                                                                                Forge Group to high-end
                                                                                                                projects in Bangalore and
                                                                                                                township development in
                                                                                                                Chennai.
                                                                                                            </p>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </article>
                                                                                    <article className="mkd-pl-item mkd-item-space  post-1621 portfolio-item type-portfolio-item status-publish has-post-thumbnail hentry portfolio-category-visualization">
                                                                                        <div className="mkd-pl-item-inner">
                                                                                            <div className="mkd-pli-image">
                                                                                                <img
                                                                                                    width={800}
                                                                                                    height={573}
                                                                                                    src="assets/suresh.jpg"
                                                                                                    className="attachment-full size-full wp-post-image"
                                                                                                    alt="a"
                                                                                                    loading="lazy"
                                                                                                />
                                                                                            </div>
                                                                                            <div className="mkd-pli-text-holder">
                                                                                                <div className="mkd-pli-text-wrapper">
                                                                                                    <div className="mkd-pli-text">
                                                                                                        <h4
                                                                                                            itemProp="name"
                                                                                                            className="mkd-pli-title entry-title"
                                                                                                        >
                                                                                                            Suresh Kumar K
                                                                                                        </h4>
                                                                                                        <div className="mkd-pli-category-holder">
                                                                                                            <p>
                                                                                                                <a
                                                                                                                    itemProp="url"
                                                                                                                    className="mkd-pli-category"
                                                                                                                >
                                                                                                                    Assistant General Manager
                                                                                                                    - Sales and Marketing
                                                                                                                </a>
                                                                                                            </p>
                                                                                                            <p>
                                                                                                                An MBA and over 10 years in
                                                                                                                the real estate industry. He
                                                                                                                drives sales and marketing
                                                                                                                strategies with precision.
                                                                                                            </p>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </article>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </section>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </>;
};

export default Founders;