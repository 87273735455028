import React, { useRef, useEffect } from 'react';

const CSRIcons = ({ id, defaultSrc, hoverSrc, titleText, listItemText }) => {
    const iconRef = useRef(null);

    useEffect(() => {
        const handleMouseOver = () => {
            iconRef.current.setAttribute('data', hoverSrc);
        };

        const handleMouseOut = () => {
            iconRef.current.setAttribute('data', defaultSrc);
        };

        const iconElement = iconRef.current;
        if (iconElement) {
            iconElement.addEventListener('mouseover', handleMouseOver);
            iconElement.addEventListener('mouseout', handleMouseOut);
        }

        // Cleanup function to remove event listeners
        return () => {
            if (iconElement) {
                iconElement.removeEventListener('mouseover', handleMouseOver);
                iconElement.removeEventListener('mouseout', handleMouseOut);
            }
        };
    }, [hoverSrc, defaultSrc]);

    return (
        <div className="mkd-iwt-content">
            <object
                ref={iconRef}
                id={id}
                data={defaultSrc}
                type="image/svg+xml"
                style={{ width: 100, height: 100 }}
            ></object>
            <object
                data={hoverSrc}
                type="image/svg+xml"
                style={{ display: 'none', width: 100, height: 100 }}
            ></object>
            <h3 className="mkd-iwt-title" style={{ color: '#464646', marginTop: 4 }}>
                <span className="mkd-iwt-title-text">{titleText}</span>
            </h3>
            <ul>
                <li style={{ fontFamily: 'metropolis', lineHeight: 2, fontSize: 16 }}>
                    {listItemText}
                </li>
            </ul>
        </div>
    );
};


export default CSRIcons;