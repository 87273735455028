const Blog2 = () =>{
    return <>
    <link rel="stylesheet" href="css/BlogDetails.module.css" />
        {/*BLOG START*/}
<div className="mkd-content">
  <div className="mkd-content-inner"> 
    {/*SLIDER START*/}
    <div className="mkd-ps-image-holder">
      <div className="mkd-ps-image mkd-owl-slider">
        <div className="mkd-ps-image">
          <picture>
            <source media="(min-width: 481px)" srcSet="assets/blog21.webp" />
            <source media="(max-width: 480px)" srcSet="assets/blog2-mobile.webp" />
            <img className="d-block w-100" src="assets/blog2-mobile.webp" alt="Banner" />
          </picture> 
        </div>  
      </div>
    </div>
    {/*SLIDER END*/}
    <div className="vc_empty_space" style={{height: '60px'}}>
      <span className="vc_empty_space_inner" />
    </div>
    <div className="mkd-container">
      <div className="mkd-container-inner clearfix">
        <div className="mkd-grid-row mkd-content-has-sidebar mkd-grid-medium-gutter">
          <div className="mkd-page-content-holder mkd-grid-col-8">
            <div className="mkd-blog-holder mkd-blog-single mkd-blog-single-standard">
              <article id="post-176" className="post-176 post type-post status-publish format-standard has-post-thumbnail hentry category-uncategorized tag-design">
                <div className="mkd-post-content"> 
                  <div className="mkd-post-text">
                    <div className="mkd-post-text-inner">
                      <div className="mkd-post-info-top">
                        <div itemProp="dateCreated" className="mkd-post-info-date entry-date published updated">
                          <a itemProp="url" href="#">
                          October 15, 2022</a>
                          <meta itemProp="interactionCount" content="UserComments: 2" />
                        </div> 
                      </div>
                      <div className="mkd-post-text-main">
                        <h3 itemProp="name" className="entry-title mkd-post-title">
                        What is Normal Plot Development & Integrated Township?</h3>
                        <div className="vc_empty_space" style={{height: '20px'}}>
                          <span className="vc_empty_space_inner" />
                        </div>
                        <section className="wpb-content-wrapper">
                          <div className="vc_row wpb_row vc_row-fluid">
                            <div className="wpb_column vc_column_container vc_col-sm-12">
                              <div className="vc_column-inner">
                                <div className="wpb_wrapper">
                                  <div className="vc_empty_space" style={{height: '4px'}}><span className="vc_empty_space_inner" />
                                  </div>
                                  <p>
                                    <strong>Normal Plotted developments</strong> are subdivisions of land into
                                    plots or lots for sale or lease to homebuilders for new housing developments.
                                    The plots typically have similar dimensions, with no land between, and are
                                    usually divided by a network of roads. One would have to build a home there
                                    over a readily built home available to move in. They offer the benefits of low
                                    maintenance, privacy, and security along with an attractive surrounding
                                    landscape. In addition, the location of a plotted development can be
                                    particularly appealing if chosen correctly. But plots are usually smaller than
                                    those in custom-built homes although they’re built to the same building code.
                                    Buyers have to coordinate their house with their neighbor’s home and/or allow
                                    room for expansion. The developer is responsible for essential services such
                                    as roads and drainage systems.
                                    </p>
                                    <p>
                                    <ul>
                                        <li>
                                            <strong>Integrated Townships</strong> are large-scale real estate projects
                                            that combine residential and commercial complexes. They come with associated
                                            infrastructure like roads, schools, colleges, hospitals, shopping centers,
                                            water treatment plants, drainage and sewage facilities, places of worship
                                            etc,. This makes it an amalgamation of great amenities and services that
                                            come together to form a miniature urban ecosystem. There are many reasons
                                            why Integrated Townships are fast becoming known as the optimal new age
                                            residential format. From the warmth and security of living in a vibrant
                                            community, to the sheer convenience of having everything close at hand,
                                            homebuyers in India are fast realizing that Township Living is the best way
                                            forward. <br />
                                        </li>
                                        <li>
                                            <strong>Connectivity of Poonamallee – Avadi High Road</strong> Chennai is
                                            situated on the shores of the Bay of Bengal is the capital of the Tamil Nadu
                                            state and it is the fourth largest metropolis in India. Chennai is a major
                                            transportation hub for road, rail, air and sea transport connecting major
                                            cities inland and abroad. And is ever expanding. And one of its latest
                                            upcoming areas is the Poonamallee – Avadi High Road. <br />
                                            <br />
                                            The Poonamallee Avadi High Road stretches for a length of 9.29 Kms in the
                                            suburbs of Chennai. It connects the areas between poonamallee, avadi,
                                            vasatham nagar and the city. The road is a gateway to the city and provides
                                            great access to important hubs and industries. The road is very well
                                            connected with the buses, with a number of buses going by that route. The
                                            area also has suburban rail access for quick transport. Now with the Chennai
                                            metro rail coming up to Poonamallee, it only makes the road and the
                                            surrounding areas a prime location in the city.
                                        </li>
                                    </ul>
                                    </p>
                                </div>
                              </div>
                            </div>
                          </div>  
                        </section>
                      </div> 
                    </div>
                  </div>
                </div>
              </article>   
            </div>
          </div>
          <div className="mkd-sidebar-holder mkd-grid-col-4">
            <aside className="mkd-sidebar">
              {/* <div id="search-2" className="widget widget_search">
                <form role="search" method="get" className="searchform" id="searchform-925" action>
                  <label className="screen-reader-text">Search for:</label>
                  <div className="input-holder clearfix">
                    <input value="" type="search" className="search-field" placeholder="Search" name="s" title="Search for:" />
                    <button type="submit" className="mkd-search-submit"><img src="https://entre.qodeinteractive.com/wp-content/themes/entre/assets/img/search_icon_dark.png" alt="search-icon" /></button>
                  </div>
                </form>
              </div> */}
              <div className="widget mkd-separator-widget">
                <div className="mkd-separator-holder clearfix  mkd-separator-center mkd-separator-normal">
                  <div className="mkd-separator" style={{borderStyle: 'solid', marginTop: '-11px'}} />
                </div>
              </div>
              <div className="widget mkd-blog-slider-widget">
                <div className="mkd-widget-title-holder">
                  <h4 className="mkd-widget-title">Recent posts</h4>
                </div>
                <div className="mkd-blog-slider-holder mkd-bs-slider">
                  <ul className="mkd-blog-slider mkd-owl-slider" data-number-of-items={1} data-enable-pagination="yes">
                    <li className="mkd-blog-slider-item">
                      <div className="mkd-blog-slider-item-inner">  
                        <div className="mkd-item-image">
                          <a itemProp="url" href="https://www.newry.co.in/blog-details">
                            <img width={757} height={418} src="assets/blog/blog1-thumb.jpg" className="attachment-full size-full wp-post-image" alt="a" loading="lazy" /> </a>
                        </div>
                        <div className="mkd-item-text-wrapper">
                          <div className="mkd-item-text-holder">
                            <div className="mkd-item-text-holder-inner">
                              <div className="mkd-item-info-section">
                              </div>
                              <h4 itemProp="name" className="entry-title mkd-post-title">
                                <a itemProp="url" href="https://www.newry.co.in/blog-details" title="Home decore">
                                  Why Should You Invest in Premium Villa Plots </a>
                              </h4>
                              <div itemProp="dateCreated" className="mkd-post-info-date entry-date published updated">
                                <a itemProp="url" href="https://www.newry.co.in/blog-details">
                                  November 26, 2022 </a>
                                <meta itemProp="interactionCount" content="UserComments: 2" />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </li>
                    <li className="mkd-blog-slider-item">
                      <div className="mkd-blog-slider-item-inner">
                        <div className="mkd-item-image">
                          <a itemProp="url" href="https://www.newry.co.in/blog1">
                            <img width={757} height={418} src="assets/blog/blog2-thumb.jpg" className="attachment-full size-full wp-post-image" alt="a" loading="lazy" /> </a>
                        </div>
                        <div className="mkd-item-text-wrapper">
                          <div className="mkd-item-text-holder">
                            <div className="mkd-item-text-holder-inner">
                              <div className="mkd-item-info-section">
                              </div>
                              <h4 itemProp="name" className="entry-title mkd-post-title">
                                <a itemProp="url" href="https://www.newry.co.in/blog1" title="Spring set up">
                                  Find The Home of Your Dreams and The Lifestyle You Deserve </a>
                              </h4>
                              <div itemProp="dateCreated" className="mkd-post-info-date entry-date published updated">
                                <a itemProp="url" href="https://www.newry.co.in/blog1">
                                  November 12, 2022</a>
                                <meta itemProp="interactionCount" content="UserComments: 2" />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </li>
                    <li className="mkd-blog-slider-item">
                      <div className="mkd-blog-slider-item-inner">
                        <div className="mkd-item-image">
                          <a itemProp="url" href="https://www.newry.co.in/blog2">
                            <img width={757} height={418} src="assets/blog/blog3-thumb.jpg" className="attachment-full size-full wp-post-image" alt="a" loading="lazy" /> </a>
                        </div>
                        <div className="mkd-item-text-wrapper">
                          <div className="mkd-item-text-holder">
                            <div className="mkd-item-text-holder-inner">
                              <div className="mkd-item-info-section">
                              </div>
                              <h4 itemProp="name" className="entry-title mkd-post-title">
                                <a itemProp="url" href="https://www.newry.co.in/blog2" title="Bed Rooms">
                                  What is Normal Plot Development &amp; Integrated Township?</a>
                              </h4>
                              <div itemProp="dateCreated" className="mkd-post-info-date entry-date published updated">
                                <a itemProp="url" href="https://www.newry.co.in/blog2">
                                  October 15, 2022 </a>
                                <meta itemProp="interactionCount" content="UserComments: 2" />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="widget mkd-separator-widget">
                <div className="mkd-separator-holder clearfix  mkd-separator-center mkd-separator-normal">
                  <div className="mkd-separator" style={{borderStyle: 'solid', marginTop: '-10px'}} />
                </div>
              </div>
              <div id="categories-2" className="widget widget_categories">
                <div className="mkd-widget-title-holder">
                  <h4 className="mkd-widget-title">Categories</h4>
                </div>
                <ul>
                  <li className="cat-item cat-item-85"><a href="#">Project</a>
                  </li>
                  <li className="cat-item cat-item-31"><a href="#">Financial</a>
                  </li>
                  <li className="cat-item cat-item-30"><a href="#">Real Estate</a>
                  </li>
                  <li className="cat-item cat-item-33"><a href="#">Home Guide</a>
                  </li>
                  <li className="cat-item cat-item-34"><a href="#">Locations</a>
                  </li> 
                  <li className="cat-item cat-item-34"> 
                  </li> 
                </ul>
              </div>
              <div className="widget mkd-separator-widget">
                <div className="mkd-separator-holder clearfix  mkd-separator-center mkd-separator-normal">
                  <div className="mkd-separator" style={{borderStyle: 'solid', marginTop: '-12px'}} />
                </div>
              </div> 
            </aside>
          </div>
        </div>
      </div>
    </div>
  </div></div>

    </>
};

export default Blog2;