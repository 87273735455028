const CustomerSpeaks = () => {
    return <>
        <link rel="stylesheet" href="css/CustomerSpeaks.module.css" />
        <div className="mkd-content">
            <div className="mkd-ps-image-holder">
                <div className="mkd-ps-image mkd-owl-slider">
                    <div className="mkd-ps-image">
                        <picture>
                            <source media="(min-width: 481px)" srcSet="assets/freepik/15.jpg" />
                            <source media="(max-width: 480px)" srcSet="assets/freepik/16.jpg" />
                            <img className="d-block w-100" src="assets/freepik/16.jpg" alt="Banner" />
                        </picture>
                    </div>
                </div>
            </div>
            {/*SLIDER END*/}
            <div className="vc_empty_space" style={{ height: 20 }}>
                <span className="vc_empty_space_inner" />
            </div>
            <div className="mkd-content-inner">
                <div className="mkd-full-width">
                    <div className="mkd-full-width-inner">
                        <div className="mkd-grid-row">
                            <div className="mkd-page-content-holder mkd-grid-col-12">
                                {/*ABOUT OVERVIEW*/}
                                <section className="wpb-content-wrapper location-advantages">
                                    <div className="mkd-row-grid-section-wrapper ">
                                        <div className="mkd-row-grid-section">
                                            <div className="vc_row wpb_row vc_row-fluid vc_custom_1516021087353">
                                                <div className="wpb_column vc_column_container vc_col-sm-12">
                                                    <div className="vc_column-inner vc_custom_1516007647796">
                                                        <div className="wpb_wrapper">
                                                            <div className="mkd-elements-holder   mkd-one-column  mkd-responsive-mode-768 ">
                                                                <div
                                                                    className="mkd-eh-item    "
                                                                    data-item-class="mkd-eh-custom-8773"
                                                                    data-1280-1600="0 0 20px 0"
                                                                    data-1024-1280="0 0 20px 0"
                                                                    data-768-1024="0 0 20px 0"
                                                                    data-680-768="0 0 20px 0"
                                                                    data-680="0px"
                                                                >
                                                                    <div className="mkd-eh-item-inner">
                                                                        <div
                                                                            className="mkd-eh-item-content mkd-eh-custom-8773"
                                                                            style={{ padding: "0 0 20px 0" }}
                                                                        >
                                                                            <div className="mkd-section-title-holder  ">
                                                                                <div className="mkd-st-inner road">
                                                                                    <h2 className="mkd-st-title">
                                                                                        Hear What Our Customers Have to Say
                                                                                    </h2>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </section>
                            </div>
                            {/*QUOTE*/}
                            <div className="mkd-row-grid-section-wrapper ">
                                <div className="mkd-row-grid-section">
                                    <div className="vc_row wpb_row vc_row-fluid vc_custom_1516876821869 vc_column-gap-35">
                                        <div className="wpb_column vc_column_container vc_col-sm-12">
                                            <div className="vc_column-inner">
                                                <div className="wpb_wrapper">
                                                    <div className="vc_row wpb_row vc_inner vc_row-fluid">
                                                        <div className="wpb_column vc_column_container vc_col-sm-12 vc_col-lg-6 vc_col-md-6 vc_col-xs-12">
                                                            <div className="vc_column-inner">
                                                                <div className="wpb_wrapper">
                                                                    <div className="wpb_text_column wpb_content_element ">
                                                                        <div className="wpb_wrapper">
                                                                            <p>
                                                                                "The work of Mr. Krishna who showed this
                                                                                place.And Mr. Selva from legal team who
                                                                                assisted in the registration of the deed was
                                                                                excellent, courteous and easy. But the only
                                                                                miscellaneous charges in this company are
                                                                                high. Can be reduced.But I like this place.I
                                                                                Thank God for this opportunity. <br />I
                                                                                bought one more plot from Newry. I'm happy!
                                                                                "
                                                                            </p>
                                                                        </div>
                                                                        <br />
                                                                        <div className="wpb_text_column wpb_content_element ">
                                                                            <div className="wpb_wrapper">
                                                                                <h4>- Prince Priya </h4>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div
                                                                        className="vc_empty_space"
                                                                        style={{ height: 32 }}
                                                                    >
                                                                        <span className="vc_empty_space_inner" />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="wpb_column vc_column_container vc_col-sm-12 vc_col-lg-6 vc_col-md-6 vc_col-xs-12">
                                                            <div className="vc_column-inner">
                                                                <div className="wpb_wrapper">
                                                                    <div className="wpb_text_column wpb_content_element ">
                                                                        <div className="wpb_wrapper">
                                                                            <p>
                                                                                "It was amazing and seamless service
                                                                                delivered by Newry properties right from
                                                                                plot booking till sale deed registration.
                                                                                Newry has coordinated very nicely with all
                                                                                the concerned and took complete
                                                                                responsibility to close the property deal.
                                                                                Glad to be part of Newry properties."
                                                                            </p>
                                                                        </div>
                                                                        <br />
                                                                        <div className="wpb_text_column wpb_content_element ">
                                                                            <div className="wpb_wrapper">
                                                                                <h4>- Sundara Vel</h4>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div
                                                                        className="vc_empty_space"
                                                                        style={{ height: 32 }}
                                                                    >
                                                                        <span className="vc_empty_space_inner" />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/*QUOTE*/}
                            <div className="mkd-row-grid-section-wrapper ">
                                <div className="mkd-row-grid-section">
                                    <div className="vc_row wpb_row vc_row-fluid vc_custom_1516876821869 vc_column-gap-35">
                                        <div className="wpb_column vc_column_container vc_col-sm-12">
                                            <div className="vc_column-inner">
                                                <div className="wpb_wrapper">
                                                    <div className="vc_row wpb_row vc_inner vc_row-fluid">
                                                        <div className="wpb_column vc_column_container vc_col-sm-12 vc_col-lg-6 vc_col-md-6 vc_col-xs-12">
                                                            <div className="vc_column-inner">
                                                                <div className="wpb_wrapper">
                                                                    <div className="wpb_text_column wpb_content_element ">
                                                                        <div className="wpb_wrapper">
                                                                            <p>
                                                                                "Over all, good customer service! Mr.Suresh,
                                                                                the manager, very calm person, understands
                                                                                the customer requirements and provide
                                                                                suggestions accordingly. Mr.Selva, legal
                                                                                executive came at the climax , and did a
                                                                                smooth registration process. Thanks Team,
                                                                                continue the momentum!! "
                                                                            </p>
                                                                        </div>
                                                                        <br />
                                                                        <div className="wpb_text_column wpb_content_element ">
                                                                            <div className="wpb_wrapper">
                                                                                <h4>- Sathiya Sai</h4>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div
                                                                        className="vc_empty_space"
                                                                        style={{ height: 32 }}
                                                                    >
                                                                        <span className="vc_empty_space_inner" />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="wpb_column vc_column_container vc_col-sm-12 vc_col-lg-6 vc_col-md-6 vc_col-xs-12">
                                                            <div className="vc_column-inner">
                                                                <div className="wpb_wrapper">
                                                                    <div className="wpb_text_column wpb_content_element ">
                                                                        <div className="wpb_wrapper">
                                                                            <p>
                                                                                "Excellent service provided by Manager ,
                                                                                Nivedha Priya. She is very prompt in
                                                                                response and provided very good service. Mr.
                                                                                Krishna was also very much helpful while
                                                                                selecting my plot. Thank you Newry. "
                                                                            </p>
                                                                        </div>
                                                                        <br />
                                                                        <div className="wpb_text_column wpb_content_element ">
                                                                            <div className="wpb_wrapper">
                                                                                <h4>- Varadharajan gattupalli </h4>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div
                                                                        className="vc_empty_space"
                                                                        style={{ height: 32 }}
                                                                    >
                                                                        <span className="vc_empty_space_inner" />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="vc_empty_space" style={{ height: 50 }}>
                                <span className="vc_empty_space_inner" />
                            </div>
                            <section className="wpb-content-wrapper ">
                                <div className="mkd-row-grid-section-wrapper ">
                                    <div className="mkd-row-grid-section">
                                        <div className="vc_row wpb_row vc_row-fluid vc_custom_1516021087353">
                                            <div className="wpb_column vc_column_container vc_col-sm-12">
                                                <div className="vc_column-inner vc_custom_1516007647796">
                                                    <div className="wpb_wrapper">
                                                        <div className="mkd-elements-holder   mkd-one-column  mkd-responsive-mode-768 ">
                                                            <div
                                                                className="mkd-eh-item    "
                                                                data-item-class="mkd-eh-custom-8773"
                                                                data-1280-1600="0 0 20px 0"
                                                                data-1024-1280="0 0 20px 0"
                                                                data-768-1024="0 0 20px 0"
                                                                data-680-768="0 0 20px 0"
                                                                data-680="0px"
                                                            >
                                                                <div className="mkd-eh-item-inner">
                                                                    <div
                                                                        className="mkd-eh-item-content mkd-eh-custom-8773"
                                                                        style={{ padding: "0 0 20px 0" }}
                                                                    >
                                                                        <div className="mkd-section-title-holder  ">
                                                                            <div className="mkd-st-inner">
                                                                                <h2 className="mkd-st-title">
                                                                                    Video Testimonials
                                                                                </h2>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </section>
                            <div className="vc_empty_space" style={{ height: 30 }}>
                                <span className="vc_empty_space_inner" />
                            </div>
                            <h3
                                style={{
                                    textAlign: "center",
                                    marginBottom: 25,
                                    letterSpacing: "3.1px"
                                }}
                            >

                                Astor
                            </h3>
                            <section className="wpb-content-wrapper">
                                <div className="vc_row wpb_row vc_row-fluid">
                                    <div className="wpb_column vc_column_container vc_col-sm-12">
                                        <div className="vc_column-inner">
                                            <div className="wpb_wrapper">
                                                <div
                                                    className="mkd-image-gallery  mkd-ig-carousel-type mkd-small-space  mkd-image-behavior-zoom"
                                                    style={{ height: "315px !important" }}
                                                >
                                                    <div
                                                        className="mkd-ig-slider mkd-owl-slider"
                                                        data-number-of-items={3}
                                                        data-enable-loop="yes"
                                                        data-enable-autoplay="yes"
                                                        data-slider-speed={5000}
                                                        data-slider-speed-animation={600}
                                                        data-slider-padding="no"
                                                        data-fullscreen-carousel="yes"
                                                        data-enable-navigation="yes"
                                                        data-enable-pagination="no"
                                                    >
                                                        <div className="mkd-ig-image">
                                                            <iframe
                                                                width={560}
                                                                height={315}
                                                                src="https://www.youtube.com/embed/OWQmAIWZG3A?si=e-O9KV6iIUtsuh7J"
                                                                title="YouTube video player"
                                                                frameBorder={0}
                                                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                                                allowFullScreen=""
                                                            />
                                                        </div>
                                                        <div className="mkd-ig-image">
                                                            <iframe
                                                                width={560}
                                                                height={315}
                                                                src="https://www.youtube.com/embed/Kv9BBDXJHiA?si=KRVrRvd1QhBuJ99s"
                                                                title="YouTube video player"
                                                                frameBorder={0}
                                                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                                                allowFullScreen=""
                                                            />
                                                        </div>
                                                        <div className="mkd-ig-image">
                                                            <iframe
                                                                width={560}
                                                                height={315}
                                                                src="https://www.youtube.com/embed/ba_FKYSkpek?si=IKNtVCAy8yl6la7E"
                                                                title="YouTube video player"
                                                                frameBorder={0}
                                                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                                                allowFullScreen=""
                                                            />
                                                        </div>
                                                        <div className="mkd-ig-image">
                                                            <iframe
                                                                width={560}
                                                                height={315}
                                                                src="https://www.youtube.com/embed/WdxURBFUKlA?si=i6t25LUF0wiikphx"
                                                                title="YouTube video player"
                                                                frameBorder={0}
                                                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                                                allowFullScreen=""
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </section>
                            <div className="vc_empty_space" style={{ height: 50 }}>
                                <span className="vc_empty_space_inner" />
                            </div>
                            <h3
                                style={{
                                    textAlign: "center",
                                    marginBottom: 25,
                                    letterSpacing: "3.1px"
                                }}
                            >

                                Adora
                            </h3>
                            <section className="wpb-content-wrapper">
                                <div className="vc_row wpb_row vc_row-fluid">
                                    <div className="wpb_column vc_column_container vc_col-sm-12">
                                        <div className="vc_column-inner">
                                            <div className="wpb_wrapper">
                                                <div
                                                    className="mkd-image-gallery  mkd-ig-carousel-type mkd-small-space  mkd-image-behavior-zoom"
                                                    style={{ height: "315px !important" }}
                                                >
                                                    <div
                                                        className="mkd-ig-slider mkd-owl-slider"
                                                        data-number-of-items={3}
                                                        data-enable-loop="yes"
                                                        data-enable-autoplay="yes"
                                                        data-slider-speed={5000}
                                                        data-slider-speed-animation={600}
                                                        data-slider-padding="no"
                                                        data-fullscreen-carousel="yes"
                                                        data-enable-navigation="yes"
                                                        data-enable-pagination="no"
                                                    >
                                                        <div className="mkd-ig-image">
                                                            <iframe
                                                                width={560}
                                                                height={315}
                                                                src="https://www.youtube.com/embed/XNwl__R6MkI?si=6ESCfyV50sx9S3uh"
                                                                title="YouTube video player"
                                                                frameBorder={0}
                                                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                                                allowFullScreen=""
                                                            />
                                                        </div>
                                                        <div className="mkd-ig-image">
                                                            <iframe
                                                                width={560}
                                                                height={315}
                                                                src="https://www.youtube.com/embed/7OOAI_XPdI4?si=klmwkAnKf2r_MYRh"
                                                                title="YouTube video player"
                                                                frameBorder={0}
                                                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                                                allowFullScreen=""
                                                            />
                                                        </div>
                                                        <div className="mkd-ig-image">
                                                            <iframe
                                                                width={560}
                                                                height={315}
                                                                src="https://www.youtube.com/embed/xPpPR1JuF8I?si=eTbWcmKHBeCoWl1T"
                                                                title="YouTube video player"
                                                                frameBorder={0}
                                                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                                                allowFullScreen=""
                                                            />
                                                        </div>
                                                        <div className="mkd-ig-image">
                                                            <iframe
                                                                width={560}
                                                                height={315}
                                                                src="https://www.youtube.com/embed/XJihBjGxjtk?si=9H4qYG0Wdy-r1-Eg"
                                                                title="YouTube video player"
                                                                frameBorder={0}
                                                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                                                allowFullScreen=""
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </section>
                            <div className="vc_empty_space" style={{ height: 50 }}>
                                <span className="vc_empty_space_inner" />
                            </div>
                            <div
                                className="mkd-pl-pag-load-more "
                                data-type="gallery"
                                data-number-of-columns={2}
                                data-space-between-items="normal"
                                data-number-of-items={4}
                                data-image-proportions="full"
                                data-enable-fixed-proportions="no"
                                data-enable-image-shadow="no"
                                data-category="architecture-project"
                                data-orderby="date"
                                data-order="ASC"
                                data-item-style="gallery-slide-from-left-midway"
                                data-enable-title="yes"
                                data-title-tag="h4"
                                data-enable-category="yes"
                                data-category-tag="p"
                                data-enable-count-images="yes"
                                data-enable-excerpt="no"
                                data-excerpt-length={20}
                                data-pagination-type="load-more"
                                data-load-more-top-margin="57px"
                                data-filter="no"
                                data-filter-order-by="name"
                                data-enable-article-animation="no"
                                data-portfolio-slider-on="no"
                                data-enable-loop="yes"
                                data-enable-autoplay="yes"
                                data-slider-speed={5000}
                                data-slider-speed-animation={600}
                                data-enable-navigation="yes"
                                data-enable-pagination="yes"
                                data-max-num-pages={3}
                                data-next-page={2}
                            >
                                <div className="mkd-pl-loading">
                                    <div className="vc_empty_space" style={{ height: 50 }}>
                                        <span className="vc_empty_space_inner" />
                                    </div>
                                    <h3
                                        style={{
                                            textAlign: "center",
                                            marginBottom: 25,
                                            letterSpacing: "3.1px"
                                        }}
                                    >

                                        Arista
                                    </h3>
                                    <section className="wpb-content-wrapper">
                                        <div className="vc_row wpb_row vc_row-fluid">
                                            <div className="wpb_column vc_column_container vc_col-sm-12">
                                                <div className="vc_column-inner">
                                                    <div className="wpb_wrapper">
                                                        <div
                                                            className="mkd-image-gallery  mkd-ig-carousel-type mkd-small-space  mkd-image-behavior-zoom"
                                                            style={{ height: "400px !important" }}
                                                        >
                                                            <div
                                                                className="mkd-ig-slider mkd-owl-slider"
                                                                data-number-of-items={4}
                                                                data-enable-loop="yes"
                                                                data-enable-autoplay="yes"
                                                                data-slider-speed={3000}
                                                                data-slider-speed-animation={600}
                                                                data-slider-padding="no"
                                                                data-fullscreen-carousel="yes"
                                                                data-enable-navigation="yes"
                                                                data-enable-pagination="no"
                                                            >
                                                                <div className="mkd-ig-image">
                                                                    <img
                                                                        width={474}
                                                                        height={1122}
                                                                        src="https://via.placeholder.com/450x500"
                                                                        className="attachment-full size-full"
                                                                        alt="a"
                                                                        loading="lazy"
                                                                    />
                                                                </div>
                                                                <div className="mkd-ig-image">
                                                                    <img
                                                                        width={474}
                                                                        height={1122}
                                                                        src="https://via.placeholder.com/450x500"
                                                                        className="attachment-full size-full"
                                                                        alt="a"
                                                                        loading="lazy"
                                                                    />
                                                                </div>
                                                                <div className="mkd-ig-image">
                                                                    <img
                                                                        width={474}
                                                                        height={1122}
                                                                        src="https://via.placeholder.com/450x500"
                                                                        className="attachment-full size-full"
                                                                        alt="a"
                                                                        loading="lazy"
                                                                    />
                                                                </div>
                                                                <div className="mkd-ig-image">
                                                                    <img
                                                                        width={474}
                                                                        height={1122}
                                                                        src="https://via.placeholder.com/450x500"
                                                                        className="attachment-full size-full"
                                                                        alt="a"
                                                                        loading="lazy"
                                                                    />
                                                                </div>
                                                                <div className="mkd-ig-image">
                                                                    <img
                                                                        width={474}
                                                                        height={1122}
                                                                        src="https://via.placeholder.com/450x500"
                                                                        className="attachment-full size-full"
                                                                        alt="a"
                                                                        loading="lazy"
                                                                    />
                                                                </div>
                                                                <div className="mkd-ig-image">
                                                                    <img
                                                                        width={474}
                                                                        height={1122}
                                                                        src="https://via.placeholder.com/450x500"
                                                                        className="attachment-full size-full"
                                                                        alt="a"
                                                                        loading="lazy"
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </section>
                                </div>
                                <div className="mkd-pl-load-more-holder">
                                    <div className="mkd-pl-load-more" style={{ marginTop: 57 }}>
                                        <a
                                            itemProp="url"
                                            href=""
                                            target="_self"
                                            className="mkd-btn mkd-btn-medium mkd-btn-outline"
                                        >
                                            <span className="mkd-btn-text">load more</span>
                                        </a>
                                    </div>
                                </div>
                            </div>
                            <div className="vc_empty_space" style={{ height: 20 }}>
                                <span className="vc_empty_space_inner" />
                            </div>
                            <div className="vc_empty_space" style={{ height: 32 }}>
                                <span className="vc_empty_space_inner" />
                            </div>
                            <section className="wpb-content-wrapper ">
                                <div className="mkd-row-grid-section-wrapper ">
                                    <div className="mkd-row-grid-section">
                                        <div className="vc_row wpb_row vc_row-fluid vc_custom_1516021087353">
                                            <div className="wpb_column vc_column_container vc_col-sm-12">
                                                <div className="vc_column-inner vc_custom_1516007647796">
                                                    <div className="wpb_wrapper">
                                                        <div className="mkd-elements-holder   mkd-one-column  mkd-responsive-mode-768 ">
                                                            <div
                                                                className="mkd-eh-item    "
                                                                data-item-class="mkd-eh-custom-8773"
                                                                data-1280-1600="0 0 20px 0"
                                                                data-1024-1280="0 0 20px 0"
                                                                data-768-1024="0 0 20px 0"
                                                                data-680-768="0 0 20px 0"
                                                                data-680="0px"
                                                            >
                                                                <div className="mkd-eh-item-inner">
                                                                    <div
                                                                        className="mkd-eh-item-content mkd-eh-custom-8773"
                                                                        style={{ padding: "0 0 20px 0" }}
                                                                    >
                                                                        <div className="mkd-section-title-holder  ">
                                                                            <div className="mkd-st-inner">
                                                                                <h2 className="mkd-st-title">
                                                                                    Words of trust
                                                                                </h2>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </section>
                            <div className="mkd-row-grid-section-wrapper ">
                                <div className="mkd-row-grid-section">
                                    <div className="vc_row wpb_row vc_row-fluid vc_custom_1516876821869 vc_column-gap-35">
                                        <div className="wpb_column vc_column_container vc_col-sm-12">
                                            <div className="vc_column-inner">
                                                <div className="wpb_wrapper">
                                                    <div className="vc_row wpb_row vc_inner vc_row-fluid">
                                                        <div className="wpb_column vc_column_container vc_col-sm-12 vc_col-lg-6 vc_col-md-6 vc_col-xs-12">
                                                            <div className="vc_column-inner">
                                                                <div className="wpb_wrapper">
                                                                    <div className="wpb_text_column wpb_content_element ">
                                                                        <div className="wpb_wrapper">
                                                                            <img src="assets/vijayakumar.jpg" />
                                                                            <p>
                                                                                "I was renting this apartment in Aura, as I
                                                                                was so pumped about the launch of Adora! I
                                                                                had a chat with Mr. Arun, who's like super
                                                                                easy to approach. We went over the brochure
                                                                                together, it gave me such a clear
                                                                                understanding of what Adora's all about. It
                                                                                got me even more excited about the whole
                                                                                thing!"
                                                                            </p>
                                                                        </div>
                                                                        <br />
                                                                        <div className="wpb_text_column wpb_content_element ">
                                                                            <div className="wpb_wrapper">
                                                                                <h4>- Vijayakumar </h4>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div
                                                                        className="vc_empty_space"
                                                                        style={{ height: 32 }}
                                                                    >
                                                                        <span className="vc_empty_space_inner" />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="wpb_column vc_column_container vc_col-sm-12 vc_col-lg-6 vc_col-md-6 vc_col-xs-12">
                                                            <div className="vc_column-inner">
                                                                <div className="wpb_wrapper">
                                                                    <div className="wpb_text_column wpb_content_element ">
                                                                        <div className="wpb_wrapper">
                                                                            <img src="assets/sankar.jpg" />
                                                                            <p>
                                                                                "I heard about their fantastic service, how
                                                                                they build their properties, always deliver
                                                                                on time, and provide great facilities. It
                                                                                got me really interested in buying a flat at
                                                                                Adora. Plus, they have multiple projects in
                                                                                different locations too."
                                                                            </p>
                                                                        </div>
                                                                        <br />
                                                                        <div className="wpb_text_column wpb_content_element ">
                                                                            <div className="wpb_wrapper">
                                                                                <h4>- Sankar </h4>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div
                                                                        className="vc_empty_space"
                                                                        style={{ height: 32 }}
                                                                    >
                                                                        <span className="vc_empty_space_inner" />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </>;
}
export default CustomerSpeaks;