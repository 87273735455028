import React from "react";
import ProjectLocationIcons from "../modules/ProjectLocationIcons";
import ProjectAmenity from "../modules/ProjectAmenity";
import EnquiryForm from "../modules/EnquiryForm";

const Arista = () => {
  return <>
    <link rel="stylesheet" href="css/Arista.module.css" />
    <a id="mkd-back-to-top" href="#">
      <span className="mkd-icon-stack">
        <span aria-hidden="true" className="mkd-icon-font-elegant arrow_up  " />
      </span>
    </a>
    <div className="mkd-content">
      <div className="mkd-content-inner">
        <div className="mkd-container">
          <div className="mkd-container clearfix">
            <div className="mkd-portfolio-single-holder mkd-ps-slider-layout home-banner">
              <div className="mkd-ps-image-holder">
                <div className="mkd-ps-image mkd-owl-slider">
                  <div className="mkd-ps-image">
                    <picture>
                      <source media="(min-width: 481px)" srcSet="assets/arista1.webp" />
                      <source
                        media="(max-width: 480px)"
                        srcSet="assets/arista1-mobile.webp"
                      />
                      <img
                        className="d-block w-100"
                        src="assets/arista1-mobile.webp"
                        alt="Banner"
                      />
                    </picture>
                    <div className="banner-description">
                      <h2>NEWRY ARISTA</h2>
                      <h5>Adorable Lifestyle Homes</h5>
                      <h3>Kolathur | 2.5 BHK | Ready to Move-In</h3>
                      <div className="banner-enquire hide-desktop">
                        <h3>Price Starts at ₹75L*</h3>
                        <a
                          itemProp="url"
                          href="#"
                          target="_self"
                          style={{
                            color: "#fff",
                            backgroundImage:
                              "linear-gradient(to right,#414347 50%,#fff 50%),linear-gradient(to right,#fff 50%,transparent 50%)",
                            borderColor: "#fff"
                          }}
                          className="mkd-btn mkd-btn-medium mkd-btn-solid mkd-btn-custom-border-hover mkd-btn-custom-hover-color"
                          data-hover-color="#414347"
                          data-hover-border-color="#fff"
                        >
                          <span className="mkd-btn-text">ENQUIRE NOW</span>
                        </a>
                      </div>
                    </div>
                    <div className="banner-enquire hide-mobile">
                      <h6 style={{ color: "#fff" }}>Price Starts at ₹75L*</h6>
                      <a
                        itemProp="url"
                        href="#"
                        target="_self"
                        style={{
                          color: "#fff",
                          backgroundImage:
                            "linear-gradient(to right,#414347 50%,#fff 50%),linear-gradient(to right,#fff 50%,transparent 50%)",
                          borderColor: "#fff"
                        }}
                        className="mkd-btn mkd-btn-medium mkd-btn-solid mkd-btn-custom-border-hover mkd-btn-custom-hover-color"
                        data-hover-color="#414347"
                        data-hover-border-color="#fff"
                      >
                        <span className="mkd-btn-text">ENQUIRE NOW</span>
                      </a>
                    </div>
                  </div>
                  <div className="mkd-ps-image">
                    <picture>
                      <source media="(min-width: 481px)" srcSet="assets/arista2.webp" />
                      <source
                        media="(max-width: 480px)"
                        srcSet="assets/arista2-mobile.webp"
                      />
                      <img
                        className="d-block w-100"
                        src="assets/arista2-mobile.webp"
                        alt="Banner"
                      />
                    </picture>
                    <div className="banner-description">
                      <h2>NEWRY ARISTA</h2>
                      <h5>Adorable Lifestyle Homes</h5>
                      <h3>Kolathur | 2.5 BHK | Ready to Move-In</h3>
                      <div className="banner-enquire hide-desktop">
                        <h3>Price Starts at ₹75L*</h3>
                        <a
                          itemProp="url"
                          href="#"
                          target="_self"
                          style={{
                            color: "#fff",
                            backgroundImage:
                              "linear-gradient(to right,#414347 50%,#fff 50%),linear-gradient(to right,#fff 50%,transparent 50%)",
                            borderColor: "#fff"
                          }}
                          className="mkd-btn mkd-btn-medium mkd-btn-solid mkd-btn-custom-border-hover mkd-btn-custom-hover-color"
                          data-hover-color="#414347"
                          data-hover-border-color="#fff"
                        >
                          <span className="mkd-btn-text">ENQUIRE NOW</span>
                        </a>
                      </div>
                    </div>
                    <div className="banner-enquire hide-mobile">
                      <h6 style={{ color: "#fff" }}>Price Starts at ₹75L*</h6>
                      <a
                        itemProp="url"
                        href="#"
                        target="_self"
                        style={{
                          color: "#fff",
                          backgroundImage:
                            "linear-gradient(to right,#414347 50%,#fff 50%),linear-gradient(to right,#fff 50%,transparent 50%)",
                          borderColor: "#fff"
                        }}
                        className="mkd-btn mkd-btn-medium mkd-btn-solid mkd-btn-custom-border-hover mkd-btn-custom-hover-color"
                        data-hover-color="#414347"
                        data-hover-border-color="#fff"
                      >
                        <span className="mkd-btn-text">ENQUIRE NOW</span>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <div className="vc_empty_space" style={{ height: 20 }}>
                <span className="vc_empty_space_inner" />
              </div>
              <section className="wpb-content-wrapper hide-desktop">
                <div className="vc_row wpb_row vc_row-fluid vc_custom_1517410755942">
                  <div className="wpb_column vc_col-sm-12">
                    <div className="vc_column-inner">
                      <div className="wpb_wrapper">
                        <div className="vc_empty_space" style={{ height: 13 }}>
                          <span className="vc_empty_space_inner" />
                        </div>
                        <div className="mkd-accordion-holder mkd-ac-default  mkd-toggle mkd-ac-boxed  clearfix">
                          <h6 className="mkd-accordion-title">
                            <span className="mkd-accordion-mark">
                              <span className="mkd_icon_plus icon_plus" />
                              <span className="mkd_icon_minus icon_minus-06" />
                            </span>
                            <span className="mkd-tab-title">OVERVIEW </span>
                          </h6>
                          <div className="mkd-accordion-content">
                            <div className="mkd-accordion-content-inner">
                              <div className="wpb_text_column wpb_content_element ">
                                <div className="wpb_wrapper">
                                  <p>
                                    Arista is a Sanskrit word that means perfect,
                                    complete, imperishable, secure, safe and
                                    happy. These are the qualities that define
                                    Arista, a premium residential project by Newry
                                    in Kolathur. Arista offers you a world of
                                    happiness, where you can live the life of your
                                    dreams. Arista features spacious and elegant
                                    apartments, with modern amenities and security
                                    systems. Arista is also well-connected to the
                                    city and surrounded by nature. Arista is not
                                    just a home, it is a lifestyle. Come and
                                    experience the perfection of Arista by Newry.{" "}
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                          <h6 className="mkd-accordion-title">
                            <span className="mkd-accordion-mark">
                              <span className="mkd_icon_plus icon_plus" />
                              <span className="mkd_icon_minus icon_minus-06" />
                            </span>
                            <span className="mkd-tab-title">HIGHLIGHTS</span>
                          </h6>
                          <div className="mkd-accordion-content">
                            <div className="mkd-accordion-content-inner">
                              <div className="wpb_text_column wpb_content_element ">
                                <div className="wpb_wrapper">
                                  <ul className="overview-features">
                                    <li>
                                      45 flats, built on 2 BHK and 2.5 BHK with
                                      stilt and 5 floors in 18,379 sq.Ft.{" "}
                                    </li>
                                    <li>
                                      Constructed using the finest quality
                                      materials to ensure durability and
                                      aesthetics.
                                    </li>
                                    <li>
                                      24/7 Security, Power Backup, and Lift
                                      Facilities for an uninterrupted, and
                                      convenient living.
                                    </li>
                                    <li>
                                      Suitable Landscaping Shall be done as per
                                      Architect design
                                    </li>
                                    <li>
                                      Rainwater Harvesting provision will be done
                                      as per CMWSSB norms.
                                    </li>
                                    <li>
                                      Underground sump of adequate capacity will
                                      be provided.
                                    </li>
                                  </ul>
                                </div>
                              </div>
                            </div>
                          </div>
                          <h6 className="mkd-accordion-title">
                            <span className="mkd-accordion-mark">
                              <span className="mkd_icon_plus icon_plus" />
                              <span className="mkd_icon_minus icon_minus-06" />
                            </span>
                            <span className="mkd-tab-title">DOWNLOADS </span>
                          </h6>
                          <div className="mkd-accordion-content">
                            <div className="mkd-accordion-content-inner">
                              <div className="wpb_text_column wpb_content_element ">
                                <div className="wpb_wrapper">
                                  <div className="pdf-icons">
                                    <a href="#enquire-now">
                                      <img src="assets/brouchure-icon.png" />
                                      <p>Brochure</p>
                                    </a>
                                  </div>
                                  <div className="pdf-icons">
                                    <a href="#enquire-now">
                                      <img src="assets/masterplan.png" />
                                      <p>Master Plan</p>
                                    </a>
                                  </div>
                                  <div className="pdf-icons">
                                    <a href="#enquire-now">
                                      <img src="assets/floorplan.png" />
                                      <p>Floor Plan</p>
                                    </a>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <h6 className="mkd-accordion-title">
                            <span className="mkd-accordion-mark">
                              <span className="mkd_icon_plus icon_plus" />
                              <span className="mkd_icon_minus icon_minus-06" />
                            </span>
                            <span className="mkd-tab-title">RERA </span>
                          </h6>
                          <div className="mkd-accordion-content">
                            <div className="mkd-accordion-content-inner">
                              <div className="wpb_text_column wpb_content_element ">
                                <div className="wpb_wrapper">
                                  <p>RERA No - TN/29/Building/0422/2020 </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
              {/*OVERVIEW - MOBILE*/}
              <div className="mkd-grid-row">
                <div className="mkd-grid-col-12">
                  <div className="mkd-ps-info-item mkd-ps-content-item">
                    <div className="vc_empty_space" style={{ height: 10 }}>
                      <span className="vc_empty_space_inner" />
                    </div>
                    <br />
                    <div className="mkd-container mkd-default-page-template">
                      <div className="mkd-container-inner clearfix">
                        <div className="mkd-grid-row">
                          <div className="mkd-page-content-holder mkd-grid-col-12">
                            <section className="wpb-content-wrapper">
                              <div className="vc_row wpb_row vc_row-fluid vc_custom_1517410987782">
                                <div className="wpb_column vc_column_container vc_col-sm-12">
                                  <div className="vc_column-inner vc_custom_1516800266412">
                                    <div className="wpb_wrapper">
                                      <div className="mkd-elements-holder   mkd-two-columns  mkd-responsive-mode-1024 ">
                                        <div
                                          className="mkd-eh-item    "
                                          data-item-class="mkd-eh-custom-2530"
                                          data-1280-1600={0}
                                          data-1024-1280={0}
                                          data-768-1024="0%"
                                          data-680-768="0%"
                                          data-680="0%"
                                        >
                                          <div className="mkd-eh-item-inner">
                                            <div className="mkd-eh-item-content mkd-eh-custom-2530">
                                              <div className="mkd-single-image-holder  mkd-image-behavior-zoom   ">
                                                <div className="mkd-si-inner">
                                                  {/* <img width="800"
                                                                              height="618"
                                                                              src="https://via.placeholder.com/800x618"
                                                                              class="attachment-full size-full"
                                                                              alt="Agam chair"
                                                                              loading="lazy"
                                                                              srcset="https://entre.qodeinteractive.com/wp-content/uploads/2018/01/h1-img-5.jpg 800w, https://entre.qodeinteractive.com/wp-content/uploads/2018/01/h1-img-5-670x518.jpg 670w, https://entre.qodeinteractive.com/wp-content/uploads/2018/01/h1-img-5-300x232.jpg 300w, https://entre.qodeinteractive.com/wp-content/uploads/2018/01/h1-img-5-768x593.jpg 768w"
                                                                              sizes="(max-width: 800px) 100vw, 800px" /> */}
                                                  <iframe
                                                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3885.480416186022!2d80.1185836!3d13.132068299999998!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3a52637a24742def%3A0xb57f6474c9d75e9a!2sNewry%20Adora!5e0!3m2!1sen!2sin!4v1709138669894!5m2!1sen!2sin"
                                                    width={600}
                                                    height={450}
                                                    style={{
                                                      border: 0,
                                                      minHeight: 400
                                                    }}
                                                    allowFullScreen=""
                                                    loading="lazy"
                                                    referrerPolicy="no-referrer-when-downgrade"
                                                  />
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                        <div
                                          className="mkd-eh-item    "
                                          data-item-class="mkd-eh-custom-8795"
                                          data-1280-1600="0 4% 14px 0"
                                          data-1024-1280="0 0% 14px 0"
                                          data-768-1024="30px 0% 14px 0"
                                          data-680-768="30px 0% 14px 0"
                                          data-680="30px 0% 14px 0"
                                          style={{ padding: "0 0 0 4%" }}
                                        >
                                          <div className="mkd-eh-item-inner hide-mobile">
                                            <div
                                              className="mkd-eh-item-content mkd-eh-custom-8795"
                                              style={{ padding: "0 4% 14px 0" }}
                                            >
                                              <div className="mkd-tabs  mkd-tabs-simple">
                                                <ul className="mkd-tabs-nav clearfix">
                                                  <li>
                                                    <a href="#tab-easy-import">
                                                      OVERVIEW
                                                    </a>{" "}
                                                    |
                                                  </li>
                                                  <li>
                                                    <a href="#tab-layouts">
                                                      HIGHLIGHTS
                                                    </a>{" "}
                                                    |
                                                  </li>
                                                  <li>
                                                    <a href="#tab-no-coding">
                                                      DOWNLOADS
                                                    </a>
                                                  </li>
                                                </ul>
                                                <div
                                                  className="mkd-tab-container"
                                                  id="tab-easy-import-525"
                                                >
                                                  <div className="wpb_text_column wpb_content_element ">
                                                    <div className="wpb_wrapper">
                                                      <p>
                                                        Arista is a Sanskrit word
                                                        that means perfect,
                                                        complete, imperishable,
                                                        secure, safe and happy.
                                                        These are the qualities
                                                        that define Arista, a
                                                        premium residential
                                                        project by Newry in
                                                        Kolathur. Arista offers
                                                        you a world of happiness,
                                                        where you can live the
                                                        life of your dreams.
                                                        Arista features spacious
                                                        and elegant apartments,
                                                        with modern amenities and
                                                        security systems. Arista
                                                        is also well-connected to
                                                        the city and surrounded by
                                                        nature. Arista is not just
                                                        a home, it is a lifestyle.
                                                        Come and experience the
                                                        perfection of Arista by
                                                        Newry.
                                                      </p>
                                                    </div>
                                                  </div>
                                                </div>
                                                <div
                                                  className="mkd-tab-container"
                                                  id="tab-layouts-728"
                                                >
                                                  <div className="wpb_text_column wpb_content_element ">
                                                    <div className="wpb_wrapper">
                                                      <ul className="overview-features">
                                                        <li>
                                                          45 flats, built on 2 BHK
                                                          and 2.5 BHK with stilt
                                                          and 5 floors in 18,379
                                                          sq.Ft.{" "}
                                                        </li>
                                                        <li>
                                                          Constructed using the
                                                          finest quality materials
                                                          to ensure durability and
                                                          aesthetics.
                                                        </li>
                                                        <li>
                                                          24/7 Security, Power
                                                          Backup, and Lift
                                                          Facilities for an
                                                          uninterrupted, and
                                                          convenient living.
                                                        </li>
                                                        <li>
                                                          Suitable Landscaping
                                                          Shall be done as per
                                                          Architect design
                                                        </li>
                                                        <li>
                                                          Rainwater Harvesting
                                                          provision will be done
                                                          as per CMWSSB norms.
                                                        </li>
                                                        <li>
                                                          Underground sump of
                                                          adequate capacity will
                                                          be provided.
                                                        </li>
                                                      </ul>
                                                    </div>
                                                  </div>
                                                </div>
                                                <div
                                                  className="mkd-tab-container"
                                                  id="tab-no-coding-243"
                                                >
                                                  <div className="wpb_text_column wpb_content_element ">
                                                    <div className="wpb_wrapper">
                                                      <div className="pdf-icons">
                                                        <a href="#form-landing">
                                                          <img src="assets/brouchure-icon.png" />
                                                          <p>Brochure</p>
                                                        </a>
                                                      </div>
                                                      <div className="pdf-icons">
                                                        <a href="#form-landing">
                                                          <img src="assets/masterplan.png" />
                                                          <p>Master Plan</p>
                                                        </a>
                                                      </div>
                                                      <div className="pdf-icons">
                                                        <a href="#form-landing">
                                                          <img src="assets/floorplan.png" />
                                                          <p>Floor Plan</p>
                                                        </a>
                                                      </div>
                                                      <p
                                                        className="hide-mobile"
                                                        style={{
                                                          marginTop: 30,
                                                          color: "#191919",
                                                          textAlign: "center",
                                                          border:
                                                            "1px solid #191919"
                                                        }}
                                                      >
                                                        RERA No -
                                                        TN/29/Building/0422/2020{" "}
                                                      </p>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                              <div
                                                className="vc_empty_space"
                                                style={{ height: 7 }}
                                              >
                                                <span className="vc_empty_space_inner" />
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </section>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="vc_empty_space" style={{ height: 40 }}>
                <span className="vc_empty_space_inner" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <section className="wpb-content-wrapper amenities-title">
        <div className="mkd-row-grid-section-wrapper ">
          <div className="mkd-row-grid-section">
            <div className="vc_row wpb_row vc_row-fluid vc_custom_1516021087353">
              <div className="wpb_column vc_column_container vc_col-sm-12">
                <div className="vc_column-inner vc_custom_1516007647796">
                  <div className="wpb_wrapper">
                    <div className="mkd-elements-holder   mkd-one-column  mkd-responsive-mode-768 ">
                      <div
                        className="mkd-eh-item    "
                        data-item-class="mkd-eh-custom-8773"
                        data-1280-1600="0 0 20px 0"
                        data-1024-1280="0 0 20px 0"
                        data-768-1024="0 0 20px 0"
                        data-680-768="0 0 20px 0"
                        data-680="0px"
                      >
                        <div className="mkd-eh-item-inner">
                          <div
                            className="mkd-eh-item-content mkd-eh-custom-8773"
                            style={{ padding: "0 0 20px 0" }}
                          >
                            <div className="mkd-section-title-holder  ">
                              <div className="mkd-st-inner">
                                <h2 className="mkd-st-title">Amenities</h2>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <div className="mkd-full-width">
        <div className="mkd-full-width-inner">
          <div className="mkd-grid-row">
            <div className="mkd-page-content-holder mkd-grid-col-12">
              <section className="wpb-content-wrapper">
                <div className="mkd-row-grid-section-wrapper ">
                  <div className="mkd-row-grid-section">
                    <div className="vc_row wpb_row vc_row-fluid vc_column-gap-35">
                      <div className="vc_empty_space" style={{ height: 40 }}>
                        <span className="vc_empty_space_inner" />
                      </div>
                      <div
                        className="wpb_column vc_column_container vc_col-sm-12 vc_col-lg-3 vc_col-md-11 vc_col-xs-12"
                        style={{ textAlign: "center" }}
                      >
                        <div className="vc_column-inner">
                          <div className="wpb_wrapper">
                            <div className="mkd-iwt clearfix  mkd-iwt-icon-left mkd-iwt-icon-medium">
                              <div
                                className="elementor-icon-box-wrapper"
                                style={{ textAlign: "center" }}
                              >
                                <div className="elementor-icon-box-svg">
                                  <object
                                    id="cctvv"
                                    data="assets/icons/cctvv.svg"
                                    type="image/svg+xml"
                                    style={{ width: 100, height: 100 }}
                                  >
                                    {" "}
                                  </object>
                                  <object
                                    data="assets/ongoing/cctvv-animated.svg"
                                    type="image/svg+xml"
                                    style={{ display: "none" }}
                                  >
                                    {" "}
                                  </object>
                                </div>
                                <div className="elementor-icon-box-content">
                                  <h3 className="elementor-icon-box-title">
                                    <span />
                                  </h3>
                                  <p className="elementor-icon-box-description">
                                    CCTV
                                  </p>
                                </div>
                              </div>
                            </div>
                            <div
                              className="vc_empty_space"
                              style={{ height: 38 }}
                            >
                              <span className="vc_empty_space_inner" />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        className="wpb_column vc_column_container vc_col-sm-12 vc_col-lg-3 vc_col-md-12 vc_col-xs-12"
                        style={{ textAlign: "center" }}
                      >
                        <div className="vc_column-inner">
                          <div className="wpb_wrapper">
                            <div className="mkd-iwt clearfix  mkd-iwt-icon-left mkd-iwt-icon-medium">
                              <div
                                className="elementor-icon-box-wrapper"
                                style={{ textAlign: "center" }}
                              >
                                <div className="elementor-icon-box-svg">
                                  <object
                                    id="rain"
                                    data="assets/animated/rain.svg"
                                    type="image/svg+xml"
                                    style={{ width: 100, height: 100 }}
                                  >
                                    {" "}
                                  </object>
                                  <object
                                    data="assets/animated/RAIN.svg"
                                    type="image/svg+xml"
                                    style={{ display: "none" }}
                                  >
                                    {" "}
                                  </object>
                                </div>
                                <div className="elementor-icon-box-content">
                                  <h3 className="elementor-icon-box-title">
                                    <span />
                                  </h3>
                                  <p className="elementor-icon-box-description">
                                    Rainwater Harvesting
                                  </p>
                                </div>
                              </div>
                            </div>
                            <div
                              className="vc_empty_space"
                              style={{ height: 38 }}
                            >
                              <span className="vc_empty_space_inner" />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        className="wpb_column vc_column_container vc_col-sm-12 vc_col-lg-3 vc_col-md-12 vc_col-xs-12"
                        style={{ textAlign: "center" }}
                      >
                        <div className="vc_column-inner">
                          <div className="wpb_wrapper">
                            <div className="mkd-iwt clearfix  mkd-iwt-icon-left mkd-iwt-icon-medium">
                              <div
                                className="elementor-icon-box-wrapper"
                                style={{ textAlign: "center" }}
                              >
                                <div className="elementor-icon-box-svg">
                                  <object
                                    id="badminton"
                                    data="assets/services/battery.svg"
                                    type="image/svg+xml"
                                    style={{ width: 100, height: 100 }}
                                  >
                                    {" "}
                                  </object>
                                  <object
                                    data="assets/services/battery.svg"
                                    type="image/svg+xml"
                                    style={{ display: "none" }}
                                  >
                                    {" "}
                                  </object>
                                </div>
                                <div className="elementor-icon-box-content">
                                  <h3 className="elementor-icon-box-title">
                                    <span />
                                  </h3>
                                  <p className="elementor-icon-box-description">
                                    Power Backup
                                  </p>
                                </div>
                              </div>
                            </div>
                            <div
                              className="vc_empty_space"
                              style={{ height: 38 }}
                            >
                              <span className="vc_empty_space_inner" />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        className="wpb_column vc_column_container vc_col-sm-12 vc_col-lg-3 vc_col-md-12 vc_col-xs-12"
                        style={{ textAlign: "center" }}
                      >
                        <div className="vc_column-inner">
                          <div className="wpb_wrapper">
                            <div className="mkd-iwt clearfix  mkd-iwt-icon-left mkd-iwt-icon-medium">
                              <div
                                className="elementor-icon-box-wrapper"
                                style={{ textAlign: "center" }}
                              >
                                <div className="elementor-icon-box-svg">
                                  <object
                                    id="lift"
                                    data="assets/animated/lift.svg"
                                    type="image/svg+xml"
                                    style={{ width: 100, height: 100 }}
                                  >
                                    {" "}
                                  </object>
                                  <object
                                    data="assets/animated/LIFT.svg"
                                    type="image/svg+xml"
                                    style={{ display: "none" }}
                                  >
                                    {" "}
                                  </object>
                                </div>
                                <div className="elementor-icon-box-content">
                                  <h3 className="elementor-icon-box-title">
                                    <span />
                                  </h3>
                                  <p className="elementor-icon-box-description">
                                    Lift
                                  </p>
                                </div>
                              </div>
                            </div>
                            <div
                              className="vc_empty_space"
                              style={{ height: 38 }}
                            >
                              <span className="vc_empty_space_inner" />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="mkd-row-grid-section-wrapper ">
                  <div className="mkd-row-grid-section">
                    <div className="vc_row wpb_row vc_row-fluid vc_custom_1516871268525 vc_column-gap-35">
                      <div
                        className="wpb_column vc_column_container vc_col-sm-12 vc_col-lg-3 vc_col-md-12 vc_col-xs-12"
                        style={{ textAlign: "center" }}
                      >
                        <div className="vc_column-inner">
                          <div className="wpb_wrapper">
                            <div className="mkd-iwt clearfix  mkd-iwt-icon-left mkd-iwt-icon-medium">
                              <div
                                className="elementor-icon-box-wrapper"
                                style={{ textAlign: "center" }}
                              >
                                <div className="elementor-icon-box-svg">
                                  <object
                                    id="parking"
                                    data="assets/services/car.svg"
                                    type="image/svg+xml"
                                    style={{ width: 100, height: 100 }}
                                  >
                                    {" "}
                                  </object>
                                  <object
                                    data="assets/services/car-animated.svg"
                                    type="image/svg+xml"
                                    style={{ display: "none" }}
                                  >
                                    {" "}
                                  </object>
                                </div>
                                <div className="elementor-icon-box-content">
                                  <h3 className="elementor-icon-box-title">
                                    <span />
                                  </h3>
                                  <p className="elementor-icon-box-description">
                                    Car Parking
                                  </p>
                                </div>
                              </div>
                            </div>
                            <div
                              className="vc_empty_space"
                              style={{ height: 38 }}
                            >
                              <span className="vc_empty_space_inner" />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        className="wpb_column vc_column_container vc_col-sm-12 vc_col-lg-3 vc_col-md-12 vc_col-xs-12"
                        style={{ textAlign: "center" }}
                      >
                        <div className="vc_column-inner">
                          <div className="wpb_wrapper">
                            <div className="mkd-iwt clearfix  mkd-iwt-icon-left mkd-iwt-icon-medium">
                              <div
                                className="elementor-icon-box-wrapper"
                                style={{ textAlign: "center" }}
                              >
                                <div className="elementor-icon-box-svg">
                                  <object
                                    id="gym"
                                    data="assets/icons/gyms.svg"
                                    type="image/svg+xml"
                                    style={{ width: 100, height: 100 }}
                                  >
                                    {" "}
                                  </object>
                                  <object
                                    data="assets/ongoing/gym-animated.svg"
                                    type="image/svg+xml"
                                    style={{ display: "none" }}
                                  >
                                    {" "}
                                  </object>
                                </div>
                                <div className="elementor-icon-box-content">
                                  <h3 className="elementor-icon-box-title">
                                    <span />
                                  </h3>
                                  <p className="elementor-icon-box-description">
                                    GYM
                                  </p>
                                </div>
                              </div>
                            </div>
                            <div
                              className="vc_empty_space"
                              style={{ height: 38 }}
                            >
                              <span className="vc_empty_space_inner" />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        className="wpb_column vc_column_container vc_col-sm-12 vc_col-lg-3 vc_col-md-11 vc_col-xs-12"
                        style={{ textAlign: "center" }}
                      >
                        <div className="vc_column-inner">
                          <div className="wpb_wrapper">
                            <div className="mkd-iwt clearfix  mkd-iwt-icon-left mkd-iwt-icon-medium">
                              <div
                                className="elementor-icon-box-wrapper"
                                style={{ textAlign: "center" }}
                              >
                                <div className="elementor-icon-box-svg">
                                  <object
                                    id="caretaker"
                                    data="assets/animated/caretaker.svg"
                                    type="image/svg+xml"
                                    style={{ width: 100, height: 100 }}
                                  >
                                    {" "}
                                  </object>
                                  <object
                                    data="assets/animated/CARETAKER.svg"
                                    type="image/svg+xml"
                                    style={{ display: "none" }}
                                  >
                                    {" "}
                                  </object>
                                </div>
                                <div className="elementor-icon-box-content">
                                  <h3 className="elementor-icon-box-title">
                                    <span />
                                  </h3>
                                  <p className="elementor-icon-box-description">
                                    Caretaker Home
                                  </p>
                                </div>
                              </div>
                            </div>
                            <div
                              className="vc_empty_space"
                              style={{ height: 38 }}
                            >
                              <span className="vc_empty_space_inner" />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        className="wpb_column vc_column_container vc_col-sm-12 vc_col-lg-3 vc_col-md-12 vc_col-xs-12"
                        style={{ textAlign: "center" }}
                      >
                        <div className="vc_column-inner">
                          <div className="wpb_wrapper">
                            <div className="mkd-iwt clearfix  mkd-iwt-icon-left mkd-iwt-icon-medium">
                              <div
                                className="elementor-icon-box-wrapper"
                                style={{ textAlign: "center" }}
                              >
                                <div className="elementor-icon-box-svg">
                                  <object
                                    id="security"
                                    data="assets/animated/security.svg"
                                    type="image/svg+xml"
                                    style={{ width: 100, height: 100 }}
                                  >
                                    {" "}
                                  </object>
                                  <object
                                    data="assets/animated/SECURITY.svg"
                                    type="image/svg+xml"
                                    style={{ display: "none" }}
                                  >
                                    {" "}
                                  </object>
                                </div>
                                <div className="elementor-icon-box-content">
                                  <h3 className="elementor-icon-box-title">
                                    <span />
                                  </h3>
                                  <p className="elementor-icon-box-description">
                                    Security Cabin
                                  </p>
                                </div>
                              </div>
                            </div>
                            <div
                              className="vc_empty_space"
                              style={{ height: 38 }}
                            >
                              <span className="vc_empty_space_inner" />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            </div>
          </div>
        </div>
      </div>
      <br />
      <br />
      <br />
      <section className="wpb-content-wrapper location-advantages">
        <div className="mkd-row-grid-section-wrapper ">
          <div className="mkd-row-grid-section">
            <div className="vc_row wpb_row vc_row-fluid vc_custom_1516021087353">
              <div className="wpb_column vc_column_container vc_col-sm-12">
                <div className="vc_column-inner vc_custom_1516007647796">
                  <div className="wpb_wrapper">
                    <div className="mkd-elements-holder   mkd-one-column  mkd-responsive-mode-768 ">
                      <div
                        className="mkd-eh-item    "
                        data-item-class="mkd-eh-custom-8773"
                        data-1280-1600="0 0 20px 0"
                        data-1024-1280="0 0 20px 0"
                        data-768-1024="0 0 20px 0"
                        data-680-768="0 0 20px 0"
                        data-680="0px"
                      >
                        <div className="mkd-eh-item-inner">
                          <div
                            className="mkd-eh-item-content mkd-eh-custom-8773"
                            style={{ padding: "0 0 20px 0" }}
                          >
                            <div className="mkd-section-title-holder  ">
                              <div className="mkd-st-inner">
                                <h2 className="mkd-st-title">
                                  Location Advantage
                                </h2>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <div className="mkd-full-width">
        <div className="mkd-full-width-inner">
          <div className="mkd-grid-row">
            <div className="mkd-page-content-holder mkd-grid-col-12">
              <section className="wpb-content-wrapper location-advantages">
                <div className="mkd-row-grid-section-wrapper ">
                  <div className="mkd-row-grid-section">
                    <div className="vc_row wpb_row vc_row-fluid vc_column-gap-35">
                      <div className="wpb_column vc_column_container vc_col-sm-12 vc_col-lg-4 vc_col-md-12 vc_col-xs-12">
                        <div className="vc_column-inner">
                          <div className="wpb_wrapper">
                            <div className="mkd-iwt clearfix  mkd-iwt-icon-left mkd-iwt-icon-medium">
                              <div className="mkd-iwt-content">
                                <h3
                                  className="mkd-iwt-title"
                                  style={{ color: "#464646", marginTop: 4 }}
                                >
                                  <object
                                    id="schools"
                                    data="assets/animated/schools1.svg"
                                    type="image/svg+xml"
                                    style={{
                                      width: 100,
                                      height: 100,
                                      marginBottom: "-35px"
                                    }}
                                  >
                                    {" "}
                                  </object>
                                  <object
                                    data="assets/animated/SCHOOLS.svg"
                                    type="image/svg+xml"
                                    style={{ display: "none" }}
                                  >
                                    {" "}
                                  </object>
                                  <span className="mkd-iwt-title-text">
                                    Schools
                                  </span>
                                </h3>
                                <ul>
                                  <li>The Temple School</li>
                                  <li>Everwin Vidhyashram</li>
                                  <li>Don Bosco Matriculation Hr. Sec. School</li>
                                </ul>
                              </div>
                            </div>
                            <div
                              className="vc_empty_space"
                              style={{ height: 38 }}
                            >
                              <span className="vc_empty_space_inner" />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="wpb_column vc_column_container vc_col-sm-12 vc_col-lg-4 vc_col-md-12 vc_col-xs-12">
                        <div className="vc_column-inner">
                          <div className="wpb_wrapper">
                            <div className="mkd-iwt clearfix  mkd-iwt-icon-left mkd-iwt-icon-medium">
                              <div className="mkd-iwt-content">
                              <ProjectLocationIcons
                                id="schools"
                                defaultSrc="assets/animated/schools1.svg"
                                hoverSrc="assets/animated/SCHOOLS.svg"
                                titleText="Schools"
                                iconStyle={{ width: 80, height: 80, marginBottom: '-20px' }}
                            />
                                <ul>
                                  <li>
                                    SBOA School &amp; Junior College: 4 Kms{" "}
                                  </li>
                                  <li>
                                    Chevalier T. Thomas Elizabeth College for
                                    Women | Arts and Science College{" "}
                                  </li>
                                  <li>St. Anne's arts and science college </li>
                                </ul>
                              </div>
                            </div>
                            <div
                              className="vc_empty_space"
                              style={{ height: 38 }}
                            >
                              <span className="vc_empty_space_inner" />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="wpb_column vc_column_container vc_col-sm-12 vc_col-lg-4 vc_col-md-12 vc_col-xs-12">
                        <div className="vc_column-inner">
                          <div className="wpb_wrapper">
                            <div className="mkd-iwt clearfix  mkd-iwt-icon-left mkd-iwt-icon-medium">
                              <div className="mkd-iwt-content">
                                <h3
                                  className="mkd-iwt-title"
                                  style={{ color: "#464646", marginTop: 4 }}
                                >
                                  <object
                                    id="shopping"
                                    data="assets/animated/shopping1.svg"
                                    type="image/svg+xml"
                                    style={{
                                      width: 100,
                                      height: 100,
                                      marginBottom: "-35px"
                                    }}
                                  >
                                    {" "}
                                  </object>
                                  <object
                                    data="assets/animated/SHOPPING.svg"
                                    type="image/svg+xml"
                                    style={{ display: "none" }}
                                  >
                                    {" "}
                                  </object>
                                  <span className="mkd-iwt-title-text">
                                    Shopping{" "}
                                  </span>
                                </h3>
                                <ul>
                                  <li>
                                    Fresh Supermarket: <strong>1.4 km</strong>
                                  </li>
                                  <li>
                                    Siva SuperMarket: <strong>1.6 km</strong>
                                  </li>
                                  <li>
                                    Nissi Fresh Supermarket:{" "}
                                    <strong>1.6 km</strong>
                                  </li>
                                  <li>
                                    Saravana Stores: <strong>3.5 kms</strong>
                                  </li>
                                </ul>
                              </div>
                            </div>
                            <div
                              className="vc_empty_space"
                              style={{ height: 38 }}
                            >
                              <span className="vc_empty_space_inner" />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="mkd-row-grid-section-wrapper ">
                  <div className="mkd-row-grid-section">
                    <div className="vc_row wpb_row vc_row-fluid vc_custom_1516871268525 vc_column-gap-35">
                      <div className="wpb_column vc_column_container vc_col-sm-12 vc_col-lg-4 vc_col-md-12 vc_col-xs-12">
                        <div className="vc_column-inner">
                          <div className="wpb_wrapper">
                            <div className="mkd-iwt clearfix  mkd-iwt-icon-left mkd-iwt-icon-medium">
                              <div className="mkd-iwt-content">
                                <h3
                                  className="mkd-iwt-title"
                                  style={{ color: "#464646", marginTop: 4 }}
                                >
                                  <object
                                    id="publicspaces"
                                    data="assets/animated/publicspaces.svg"
                                    type="image/svg+xml"
                                    style={{
                                      width: 100,
                                      height: 100,
                                      marginBottom: "-35px"
                                    }}
                                  >
                                    {" "}
                                  </object>
                                  <object
                                    data="assets/animated/PUBLICSPACES.svg"
                                    type="image/svg+xml"
                                    style={{ display: "none" }}
                                  >
                                    {" "}
                                  </object>
                                  <span className="mkd-iwt-title-text">
                                    Public spaces{" "}
                                  </span>
                                </h3>
                                <ul>
                                  <li>
                                    AGS Cinemas: <strong>3 kms</strong>
                                  </li>
                                  <li>
                                    Sri Ganga Cinemas: <strong>3.5 kms</strong>
                                  </li>
                                  <li>
                                    S2 Cinemas: <strong>4 kms</strong>
                                  </li>
                                </ul>
                              </div>
                            </div>
                            <div
                              className="vc_empty_space"
                              style={{ height: 38 }}
                            >
                              <span className="vc_empty_space_inner" />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="wpb_column vc_column_container vc_col-sm-12 vc_col-lg-4 vc_col-md-11 vc_col-xs-12">
                        <div className="vc_column-inner">
                          <div className="wpb_wrapper">
                            <div className="mkd-iwt clearfix  mkd-iwt-icon-left mkd-iwt-icon-medium">
                              <div className="mkd-iwt-content">
                                <h3
                                  className="mkd-iwt-title"
                                  style={{ color: "#464646", marginTop: 4 }}
                                >
                                  <object
                                    id="healthcare"
                                    data="assets/animated/healthcare.svg"
                                    type="image/svg+xml"
                                    style={{
                                      width: 100,
                                      height: 100,
                                      marginBottom: "-35px"
                                    }}
                                  >
                                    {" "}
                                  </object>
                                  <object
                                    data="assets/animated/HEALTHCARE.svg"
                                    type="image/svg+xml"
                                    style={{ display: "none" }}
                                  >
                                    {" "}
                                  </object>
                                  <span className="mkd-iwt-title-text">
                                    Health care{" "}
                                  </span>
                                </h3>
                                <ul>
                                  <li>
                                    DRJ Hospital: <strong>1.5 Km</strong>
                                  </li>
                                  <li>
                                    Meridian Hospital: <strong>1.7 Km</strong>{" "}
                                  </li>
                                  <li>
                                    Prashanth Super Specialty Hospital:{" "}
                                    <strong>2.1 Kms</strong>
                                  </li>
                                </ul>
                              </div>
                            </div>
                            <div
                              className="vc_empty_space"
                              style={{ height: 38 }}
                            >
                              <span className="vc_empty_space_inner" />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="wpb_column vc_column_container vc_col-sm-12 vc_col-lg-4 vc_col-md-12 vc_col-xs-12">
                        <div className="vc_column-inner">
                          <div className="wpb_wrapper">
                            <div className="mkd-iwt clearfix  mkd-iwt-icon-left mkd-iwt-icon-medium">
                              <div className="mkd-iwt-content">
                                <h3
                                  className="mkd-iwt-title"
                                  style={{ color: "#464646", marginTop: 4 }}
                                >
                                  <object
                                    id="others"
                                    data="assets/animated/others.svg"
                                    type="image/svg+xml"
                                    style={{
                                      width: 80,
                                      height: 80,
                                      marginBottom: "-20px"
                                    }}
                                  >
                                    {" "}
                                  </object>
                                  <object
                                    data="assets/animated/OTHERS.svg"
                                    type="image/svg+xml"
                                    style={{ display: "none" }}
                                  >
                                    {" "}
                                  </object>
                                  <span className="mkd-iwt-title-text">
                                    Others{" "}
                                  </span>
                                </h3>
                                <ul>
                                  <li>
                                    Viilivakkam Railway station:{" "}
                                    <strong>3 kms</strong>
                                  </li>
                                  <li>
                                    Padi Flyover: <strong>3.5 kms</strong>
                                  </li>
                                  <li>
                                    Korattur Railway Station:{" "}
                                    <strong>4.5 kms</strong>
                                  </li>
                                </ul>
                              </div>
                            </div>
                            <div
                              className="vc_empty_space"
                              style={{ height: 38 }}
                            >
                              <span className="vc_empty_space_inner" />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            </div>
          </div>
        </div>
      </div>
      <br />
      <div
        data-parallax-bg-image="assets/walkthrough.jpg"
        data-parallax-bg-speed={1}
        className="vc_row wpb_row vc_row-fluid vc_custom_1518019336900 vc_row-has-fill mkd-parallax-row-holder mkd-content-aligment-center"
      >
        <div className="wpb_column vc_column_container vc_col-sm-12">
          <div className="vc_column-inner">
            <div className="wpb_wrapper">
              <div className="mkd-elements-holder   mkd-one-column  mkd-responsive-mode-768 ">
                <div
                  className="mkd-eh-item    "
                  data-item-class="mkd-eh-custom-9497"
                  data-1280-1600="12.3% 0 15.4%"
                  data-1024-1280="12.3% 0 15.4%"
                  data-768-1024="11.3% 0 15.4%"
                  data-680-768="10.3% 0 15.4%"
                  data-680="10.3% 0 15.4%"
                >
                  <div className="mkd-eh-item-inner">
                    <div
                      className="mkd-eh-item-content mkd-eh-custom-9497"
                      style={{ padding: "10.2% 0 12.3%" }}
                    >
                      <h1
                        className="mkd-custom-font-holder  mkd-cf-6143  "
                        style={{ color: "#ffffff" }}
                        data-item-class="mkd-cf-6143"
                      >
                        Walkthrough
                      </h1>
                      <div className="mkd-video-button-holder  ">
                        <div className="mkd-video-button-image"></div>
                        <a
                          className="mkd-video-button-play"
                          style={{ color: "#ffffff", fontSize: 50 }}
                          href="https://www.youtube.com/watch?v=MK_bqICoG4U"
                          target="_self"
                          data-rel="prettyPhoto[video_button_pretty_photo_984]"
                        >
                          <span className="mkd-video-button-play-inner">
                            <span className="arrow_triangle-right_alt" />
                          </span>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="mkd-container mkd-default-page-template">
        <div className="mkd-container-inner clearfix">
          <div className="mkd-grid-row">
            <div className="mkd-page-content-holder mkd-grid-col-12">
              <br />
              <br />
              <br />
              <br />
              <section className="wpb-content-wrapper">
                <div className="mkd-row-grid-section-wrapper ">
                  <div className="mkd-row-grid-section">
                    <div className="vc_row wpb_row vc_row-fluid vc_custom_1516021087353">
                      <div className="wpb_column vc_column_container vc_col-sm-12">
                        <div className="vc_column-inner vc_custom_1516007647796">
                          <div className="wpb_wrapper">
                            <div className="mkd-elements-holder   mkd-one-column  mkd-responsive-mode-768 ">
                              <div
                                className="mkd-eh-item    "
                                data-item-class="mkd-eh-custom-8773"
                                data-1280-1600="0 0 20px 0"
                                data-1024-1280="0 0 20px 0"
                                data-768-1024="0 0 20px 0"
                                data-680-768="0 0 20px 0"
                                data-680="0px"
                              >
                                <div className="mkd-eh-item-inner">
                                  <div
                                    className="mkd-eh-item-content mkd-eh-custom-8773"
                                    style={{ padding: "0 0 20px 0" }}
                                  >
                                    <div className="mkd-section-title-holder  ">
                                      <div className="mkd-st-inner">
                                        <h2 className="mkd-st-title">
                                          Frequently Asked Questions
                                        </h2>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
              <section className="wpb-content-wrapper">
                <div className="vc_row wpb_row vc_row-fluid vc_custom_1517410755942">
                  <div className="wpb_column vc_col-sm-12">
                    <div className="vc_column-inner">
                      <div className="wpb_wrapper">
                        <div className="vc_empty_space" style={{ height: 13 }}>
                          <span className="vc_empty_space_inner" />
                        </div>
                        <div className="mkd-accordion-holder mkd-ac-default  mkd-toggle mkd-ac-boxed  clearfix">
                          <h6 className="mkd-accordion-title">
                            <span className="mkd-accordion-mark">
                              <span className="mkd_icon_plus icon_plus" />
                              <span className="mkd_icon_minus icon_minus-06" />
                            </span>
                            <span className="mkd-tab-title">
                              What is the starting price of Newry Arista?
                            </span>
                          </h6>
                          <div className="mkd-accordion-content">
                            <div className="mkd-accordion-content-inner">
                              <div className="wpb_text_column wpb_content_element ">
                                <div className="wpb_wrapper">
                                  <p>
                                    The starting price of Newry Arista is INR 70L*
                                    onwards.
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                          <h6 className="mkd-accordion-title">
                            <span className="mkd-accordion-mark">
                              <span className="mkd_icon_plus icon_plus" />
                              <span className="mkd_icon_minus icon_minus-06" />
                            </span>
                            <span className="mkd-tab-title">
                              What are the types of houses available at Newry
                              Arista?
                            </span>
                          </h6>
                          <div className="mkd-accordion-content">
                            <div className="mkd-accordion-content-inner">
                              <div className="wpb_text_column wpb_content_element ">
                                <div className="wpb_wrapper">
                                  <p>
                                    The Apartments of 2 BHK &amp; 2.5 BHK
                                    Available.
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                          <h6 id="form-landing" className="mkd-accordion-title">
                            <span className="mkd-accordion-mark">
                              <span className="mkd_icon_plus icon_plus" />
                              <span className="mkd_icon_minus icon_minus-06" />
                            </span>
                            <span className="mkd-tab-title">
                              What is the overall area of the Newry Arista
                              Project?
                            </span>
                          </h6>
                          <div className="mkd-accordion-content">
                            <div className="mkd-accordion-content-inner">
                              <div className="wpb_text_column wpb_content_element ">
                                <div className="wpb_wrapper">
                                  <p>
                                    The total area size of Newry Arista is 18,379
                                    sq.ft.
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                          <h6 className="mkd-accordion-title">
                            <span className="mkd-accordion-mark">
                              <span className="mkd_icon_plus icon_plus" />
                              <span className="mkd_icon_minus icon_minus-06" />
                            </span>
                            <span className="mkd-tab-title">
                              Where is Newry Arista located?
                            </span>
                          </h6>
                          <div className="mkd-accordion-content">
                            <div className="mkd-accordion-content-inner">
                              <div className="wpb_text_column wpb_content_element ">
                                <div className="wpb_wrapper">
                                  <p>
                                    Newry Arista is located at Kolathur, Chennai,
                                    Tamil Nadu 600099
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                          <h6 className="mkd-accordion-title">
                            <span className="mkd-accordion-mark">
                              <span className="mkd_icon_plus icon_plus" />
                              <span className="mkd_icon_minus icon_minus-06" />
                            </span>
                            <span className="mkd-tab-title">
                              What are the key amenities at Newry Arista?
                            </span>
                          </h6>
                          <div className="mkd-accordion-content">
                            <div className="mkd-accordion-content-inner">
                              <div className="wpb_text_column wpb_content_element ">
                                <div className="wpb_wrapper">
                                  <p>
                                    The amenities at Newry Arista include 24/7
                                    security, power backup, lift facilities, GYM
                                    and more.
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
              <br />
              <br />
            </div>
          </div>
        </div>
      </div>
      <br />
      <div className="">
        <br />
        <br />
        <div className="mkd-content-inner">
          <div className="mkd-full-width">
            <div className="mkd-full-width-inner">
              <div className="mkd-grid-row">
                <div className="mkd-page-content-holder mkd-grid-col-12">
                  <section className="wpb-content-wrapper">
                    <div className="vc_row wpb_row vc_row-fluid">
                      <div className="wpb_column vc_column_container vc_col-sm-12">
                        <div className="vc_column-inner">
                          <div className="wpb_wrapper">
                            <div className="mkd-google-map-holder">
                              <input
                                type="hidden"
                                className="mkd-snazzy-map"
                                defaultValue=""
                              />
                              <div className="mkd-google-map-overlay" />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    {/*<div id="enquire-now" class="mkd-row-grid-section-wrapper mkd-disabled-bg-image-bellow-768" style="background-color:#ebebeb; background-image: url(https://entre.qodeinteractive.com/wp-content/uploads/2018/01/contact-background-img.jpg)">*/}
                    <div
                      id="enquire-now"
                      className="mkd-row-grid-section-wrapper mkd-disabled-bg-image-bellow-768"
                      style={{ backgroundColor: "#ebebeb" }}
                    >
                      <div className="mkd-row-grid-section">
                        <div className="vc_row wpb_row vc_row-fluid">
                          <div className="wpb_column vc_column_container vc_col-sm-12">
                            <div className="vc_column-inner">
                              <div className="wpb_wrapper">
                                <div className="vc_row wpb_row vc_inner vc_row-fluid">
                                  <div className="wpb_column vc_column_container vc_col-sm-12 vc_col-lg-6 vc_col-md-6 vc_col-xs-12">
                                    <div className="vc_column-inner">
                                      <div className="wpb_wrapper">
                                        <div className="mkd-elements-holder   mkd-one-column  mkd-responsive-mode-768 ">
                                          <div
                                            className="mkd-eh-item    mkd-element-from-bottom"
                                            data-item-class="mkd-eh-custom-8629"
                                            data-animation="mkd-element-from-bottom"
                                            data-1280-1600="60px 0 0 8px"
                                            data-1024-1280="60px 0 0 8px"
                                            data-768-1024="60px 0 0 8px"
                                            data-680-768="60px 0 0 8px"
                                            data-680="60px 0 0 8px"
                                          >
                                            <div className="mkd-eh-item-inner">
                                              <div
                                                className="mkd-eh-item-content mkd-eh-custom-8629"
                                                style={{
                                                  padding:
                                                    "60px 0 0 8px !important"
                                                }}
                                              >
                                                <div className="wpb_text_column wpb_content_element  vc_custom_1516695756662">
                                                  <div className="wpb_wrapper">
                                                    <h6
                                                      style={{
                                                        fontSize: 25,
                                                        paddingBottom: 6,
                                                        fontFamily: "metropolis"
                                                      }}
                                                    >
                                                      ENQUIRE NOW
                                                    </h6>
                                                  </div>
                                                </div>
                                                <div
                                                  className="vc_empty_space"
                                                  style={{ height: 23 }}
                                                >
                                                  <span className="vc_empty_space_inner" />
                                                </div>
                                                <div className="wpb_text_column wpb_content_element ">
                                                  <div className="wpb_wrapper">
                                                    <p>
                                                      Wish to get a call back from
                                                      our team? Fill in your
                                                      details.
                                                    </p>
                                                  </div>
                                                </div>
                                                <div className="wpb_text_column wpb_content_element ">
                                                  <div className="wpb_wrapper">
                                                    <p>
                                                      For bookings and more
                                                      information, <br />
                                                      <br />
                                                      <a href="tel:9994144999">
                                                        <span className="fa fa-phone" />{" "}
                                                        <strong>
                                                          +91 999 4144 999
                                                        </strong>
                                                      </a>
                                                      <br />
                                                      <br />
                                                      <a href="mailto:info@newry.co.in">
                                                        <span className="fa fa-envelope" />{" "}
                                                        <strong>
                                                          info@newry.co.in
                                                        </strong>
                                                      </a>
                                                    </p>
                                                  </div>
                                                </div>
                                                <div
                                                  className="vc_empty_space"
                                                  style={{ height: 28 }}
                                                >
                                                  <span className="vc_empty_space_inner" />
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="wpb_column vc_column_container vc_col-sm-12 vc_col-lg-6 vc_col-md-6 vc_col-xs-12">
                                    <div className="vc_column-inner">
                                      <div className="wpb_wrapper">
                                        <div className="mkd-elements-holder   mkd-one-column  mkd-responsive-mode-768 ">
                                          <div
                                            className="mkd-eh-item    mkd-element-from-bottom"
                                            data-item-class="mkd-eh-custom-5789"
                                            data-animation="mkd-element-from-bottom"
                                            data-1280-1600="60px 0 0 8px"
                                            data-1024-1280="60px 0 0 8px"
                                            data-768-1024="60px 0 0 8px"
                                            data-680-768="60px 0 0 8px"
                                            data-680="60px 0 0 8px"
                                          >
                                            <div className="mkd-eh-item-inner">
                                              <div
                                                className="mkd-eh-item-content mk6eh-custom-5789"
                                                style={{
                                                  padding:
                                                    "60px 0 0 5px !important"
                                                }}
                                              >
                                                <div
                                                  role="form"
                                                  className="wpcf7"
                                                  id="wpcf7-f1911-p1871-o2"
                                                  lang="en-US"
                                                  dir="ltr"
                                                >
                                                  <div className="screen-reader-response">
                                                    <p
                                                      role="status"
                                                      aria-live="polite"
                                                      aria-atomic="true"
                                                    />
                                                    <ul />
                                                  </div>
                                                  <form
                                                    method="post"
                                                    className="wpcf7-form init demo"
                                                    noValidate="novalidate"
                                                    data-status="init"
                                                  >
                                                    <div className="mkd-cf7-contact-form">
                                                      <span
                                                        className="wpcf7-form-control-wrap"
                                                        data-name="name"
                                                      >
                                                        <input
                                                          type="text"
                                                          name="name"
                                                          defaultValue=""
                                                          size={40}
                                                          className="wpcf7-form-control wpcf7-text wpcf7-validates-as-required"
                                                          aria-required="true"
                                                          aria-invalid="false"
                                                          placeholder="Name"
                                                        />
                                                      </span>
                                                      <br />
                                                      <span
                                                        className="wpcf7-form-control-wrap"
                                                        data-name="email-333"
                                                      >
                                                        <input
                                                          type="email"
                                                          name="email-333"
                                                          defaultValue=""
                                                          size={40}
                                                          className="wpcf7-form-control wpcf7-text wpcf7-email wpcf7-validates-as-email"
                                                          aria-invalid="false"
                                                          placeholder="E-mail"
                                                        />
                                                      </span>
                                                      <br />
                                                      <span
                                                        className="wpcf7-form-control-wrap"
                                                        data-name="email-333"
                                                      >
                                                        <input
                                                          type="text"
                                                          name="phone-333"
                                                          defaultValue=""
                                                          size={40}
                                                          className="wpcf7-form-control wpcf7-text wpcf7-email wpcf7-validates-as-email"
                                                          aria-invalid="false"
                                                          placeholder="Phone"
                                                        />
                                                      </span>
                                                      <br />
                                                      <span className="mkd-submit-button-wrapper">
                                                        <br />
                                                        <input
                                                          type="submit"
                                                          defaultValue="Submit"
                                                          className="wpcf7-form-control has-spinner wpcf7-submit"
                                                        />
                                                        <span className="mkd-submit-btn-icon" />
                                                        <br />
                                                      </span>
                                                    </div>
                                                    <div
                                                      className="wpcf7-response-output"
                                                      aria-hidden="true"
                                                    />
                                                  </form>
                                                  <br />
                                                  <br />
                                                  <br />
                                                  <br />
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div> 
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </section>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </>;
};

export default Arista;