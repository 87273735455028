const PrivacyPolicy = () => {
    return <>
    <link rel="stylesheet" href="css/BuyersGuide.moudle.css" />
    <div className="mkd-content">
  {/*SLIDER START*/}
  <div className="mkd-ps-image-holder">
    <div className="mkd-ps-image mkd-owl-slider">
      <div className="mkd-ps-image">
        <picture>
          <source media="(min-width: 481px)" srcSet="assets/banner-white.jpg" />
          <source media="(max-width: 480px)" srcSet="assets/banner-white-mobile.jpg" />
          <img
            className="d-block w-100"
            src="assets/banner-white-mobile.jpg"
            alt="Banner"
          />
        </picture>
      </div>
    </div>
  </div>
  {/*SLIDER END*/}
  <div className="vc_empty_space" style={{ height: 20 }}>
    <span className="vc_empty_space_inner" />
  </div>
  <section className="wpb-content-wrapper location-advantages">
    <div className="mkd-row-grid-section-wrapper ">
      <div className="mkd-row-grid-section">
        <div className="vc_row wpb_row vc_row-fluid vc_custom_1516021087353">
          <div className="wpb_column vc_column_container vc_col-sm-12">
            <div className="vc_column-inner vc_custom_1516007647796">
              <div className="wpb_wrapper">
                <div className="mkd-elements-holder   mkd-one-column  mkd-responsive-mode-768 ">
                  <div
                    className="mkd-eh-item    "
                    data-item-class="mkd-eh-custom-8773"
                    data-1280-1600="0 0 20px 0"
                    data-1024-1280="0 0 20px 0"
                    data-768-1024="0 0 20px 0"
                    data-680-768="0 0 20px 0"
                    data-680="0px"
                  >
                    <div className="mkd-eh-item-inner">
                      <div
                        className="mkd-eh-item-content mkd-eh-custom-8773"
                        style={{ padding: "0 0 20px 0" }}
                      >
                        <div className="mkd-section-title-holder  ">
                          <div className="mkd-st-inner road">
                            <h2 className="mkd-st-title">Privacy Policy</h2>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <div className="mkd-content-inner">
    <div className="mkd-full-width">
      <div className="mkd-full-width-inner">
        <div className="mkd-grid-row">
          <div className="mkd-page-content-holder mkd-grid-col-12">
            <div className="mkd-row-grid-section-wrapper ">
              <div className="mkd-row-grid-section">
                <div className="vc_row wpb_row vc_row-fluid vc_custom_1516877211553 vc_column-gap-35">
                  <div className="wpb_column vc_column_container vc_col-sm-12">
                    <div className="vc_column-inner">
                      <div className="wpb_wrapper">
                        <div className="vc_row wpb_row vc_inner vc_row-fluid">
                          <div className="wpb_column vc_column_container vc_col-sm-12">
                            <div className="vc_column-inner">
                              <div className="wpb_wrapper">
                                <div className="wpb_text_column wpb_content_element ">
                                  <div className="wpb_wrapper">
                                    <p>
                                      At www.newry.co.in, accessible from{" "}
                                      <a href="https://www.newry.co.in/">
                                        https://www.newry.co.in/
                                      </a>
                                      , one of our main priorities is the
                                      privacy of our visitors. This Privacy
                                      Policy document contains types of
                                      information that is collected and recorded
                                      by www.newry.co.in and how we use it.{" "}
                                    </p>
                                    <p>
                                      If you have additional questions or
                                      require more information about our Privacy
                                      Policy, do not hesitate to contact us.{" "}
                                    </p>
                                    <p>
                                      This Privacy Policy applies only to our
                                      online activities and is valid for
                                      visitors to our website with regards to
                                      the information that they shared and/or
                                      collect in www.newry.co.in. This policy is
                                      not applicable to any information
                                      collected offline or via channels other
                                      than this website. Our Privacy Policy was
                                      created with the help of the Free Privacy
                                      Policy Generator.
                                    </p>
                                    <br />
                                    <div className="wpb_text_column wpb_content_element ">
                                      <div className="wpb_wrapper">
                                        <h3 style={{ color: "#2c498a" }}>
                                          Consent
                                        </h3>
                                      </div>
                                    </div>
                                    <p>
                                      By using our website, you hereby consent
                                      to our Privacy Policy and agree to its
                                      terms.
                                    </p>
                                    <br />
                                    <div className="wpb_text_column wpb_content_element ">
                                      <div className="wpb_wrapper">
                                        <h3 style={{ color: "#2c498a" }}>
                                          Information we collect
                                        </h3>
                                      </div>
                                    </div>
                                    <p>
                                      The personal information that you are
                                      asked to provide, and the reasons why you
                                      are asked to provide it, will be made
                                      clear to you at the point we ask you to
                                      provide your personal information.
                                    </p>
                                    <p>
                                      If you contact us directly, we may receive
                                      additional information about you such as
                                      your name, email address, phone number,
                                      the contents of the message and/or
                                      attachments you may send us, and any other
                                      information you may choose to provide.
                                    </p>
                                    <p>
                                      When you register for an Account, we may
                                      ask for your contact information,
                                      including items such as name, company
                                      name, address, email address, and
                                      telephone number.
                                    </p>
                                    <br />
                                    <div className="wpb_text_column wpb_content_element ">
                                      <div className="wpb_wrapper">
                                        <h3 style={{ color: "#2c498a" }}>
                                          How we use your information
                                        </h3>
                                      </div>
                                    </div>
                                    <p>
                                      <strong>
                                        We use the information we collect in
                                        various ways, including to:
                                      </strong>
                                    </p>
                                    <ul>
                                      <li>
                                        Provide, operate, and maintain our
                                        website
                                      </li>
                                      <li>
                                        Improve, personalize, and expand our
                                        website
                                      </li>
                                      <li>
                                        Understand and analyze how you use our
                                        website
                                      </li>
                                      <li>
                                        Develop new products, services,
                                        features, and functionality
                                      </li>
                                      <li>
                                        Communicate with you, either directly or
                                        through one of our partners, including
                                        for customer service, to provide you
                                        with updates and other information
                                        relating to the website, and for
                                        marketing and promotional purposes
                                      </li>
                                      <li>Send you emails</li>
                                      <li>Find and prevent fraud</li>
                                    </ul>
                                    <br />
                                    <div className="wpb_text_column wpb_content_element ">
                                      <div className="wpb_wrapper">
                                        <h3 style={{ color: "#2c498a" }}>
                                          Log Files
                                        </h3>
                                      </div>
                                    </div>
                                    <p>
                                      www.newry.co.in follows a standard
                                      procedure of using log files. These files
                                      log visitors when they visit websites. All
                                      hosting companies do this and a part of
                                      hosting services’ analytics. The
                                      information collected by log files include
                                      internet protocol (IP) addresses, browser
                                      type, Internet Service Provider (ISP),
                                      date and time stamp, referring/exit pages,
                                      and possibly the number of clicks. These
                                      are not linked to any information that is
                                      personally identifiable. The purpose of
                                      the information is for analyzing trends,
                                      administering the site, tracking users’
                                      movement on the website, and gathering
                                      demographic information.
                                    </p>
                                    <br />
                                    <div className="wpb_text_column wpb_content_element ">
                                      <div className="wpb_wrapper">
                                        <h3 style={{ color: "#2c498a" }}>
                                          Cookies and Web Beacons
                                        </h3>
                                      </div>
                                    </div>
                                    <p>
                                      Like any other website, www.newry.co.in
                                      uses ‘cookies’. These cookies are used to
                                      store information including visitors’
                                      preferences, and the pages on the website
                                      that the visitor accessed or visited. The
                                      information is used to optimize the users’
                                      experience by customizing our web page
                                      content based on visitors’ browser type
                                      and/or other information.
                                    </p>
                                    <p>
                                      For more general information on cookies,
                                      please read the Cookies article on
                                      Generate Privacy Policy website.
                                    </p>
                                    <br />
                                    <div className="wpb_text_column wpb_content_element ">
                                      <div className="wpb_wrapper">
                                        <h3 style={{ color: "#2c498a" }}>
                                          Advertising Partners Privacy Policies
                                        </h3>
                                      </div>
                                    </div>
                                    <p>
                                      You may consult this list to find the
                                      Privacy Policy for each of the advertising
                                      partners of www.newry.co.in.
                                    </p>
                                    <p>
                                      Third-party ad servers or ad networks uses
                                      technologies like cookies, JavaScript, or
                                      Web Beacons that are used in their
                                      respective advertisements and links that
                                      appear on www.newry.co.in, which are sent
                                      directly to users’ browser. They
                                      automatically receive your IP address when
                                      this occurs. These technologies are used
                                      to measure the effectiveness of their
                                      advertising campaigns and/or to
                                      personalize the advertising content that
                                      you see on websites that you visit.
                                    </p>
                                    <p>
                                      Note that www.newry.co.in has no access to
                                      or control over these cookies that are
                                      used by third-party advertisers.
                                    </p>
                                    <br />
                                    <div className="wpb_text_column wpb_content_element ">
                                      <div className="wpb_wrapper">
                                        <h3 style={{ color: "#2c498a" }}>
                                          Third Party Privacy Policies
                                        </h3>
                                      </div>
                                    </div>
                                    <p>
                                      www.newry.co.in’s Privacy Policy does not
                                      apply to other advertisers or websites.
                                      Thus, we are advising you to consult the
                                      respective Privacy Policies of these
                                      third-party ad servers for more detailed
                                      information. It may include their
                                      practices and instructions about how to
                                      opt-out of certain options.
                                    </p>
                                    <p>
                                      You can choose to disable cookies through
                                      your individual browser options. To know
                                      more detailed information about cookie
                                      management with specific web browsers, it
                                      can be found at the browsers’ respective
                                      websites.
                                    </p>
                                    <br />
                                    <div className="wpb_text_column wpb_content_element ">
                                      <div className="wpb_wrapper">
                                        <h3 style={{ color: "#2c498a" }}>
                                          CCPA Privacy Rights (Do Not Sell My
                                          Personal Information)
                                        </h3>
                                      </div>
                                    </div>
                                    <p>
                                      Under the CCPA, among other rights,
                                      California consumers have the right to:
                                    </p>
                                    <p>
                                      Request that a business that collects a
                                      consumer’s personal data disclose the
                                      categories and specific pieces of personal
                                      data that a business has collected about
                                      consumers.
                                    </p>
                                    <p>
                                      Request that a business delete any
                                      personal data about the consumer that a
                                      business has collected.
                                    </p>
                                    <p>
                                      Request that a business that sells a
                                      consumer’s personal data, not sell the
                                      consumer’s personal data.
                                    </p>
                                    <p>
                                      If you make a request, we have one month
                                      to respond to you. If you would like to
                                      exercise any of these rights, please
                                      contact us.
                                    </p>
                                    <br />
                                    <div className="wpb_text_column wpb_content_element ">
                                      <div className="wpb_wrapper">
                                        <h3 style={{ color: "#2c498a" }}>
                                          GDPR Data Protection Rights
                                        </h3>
                                      </div>
                                    </div>
                                    <p>
                                      We would like to make sure you are fully
                                      aware of all of your data protection
                                      rights. Every user is entitled to the
                                      following:
                                    </p>
                                    <p>
                                      The right to access – You have the right
                                      to request copies of your personal data.
                                      We may charge you a small fee for this
                                      service.
                                    </p>
                                    <p>
                                      The right to rectification – You have the
                                      right to request that we correct any
                                      information you believe is inaccurate. You
                                      also have the right to request that we
                                      complete the information you believe is
                                      incomplete.
                                    </p>
                                    <p>
                                      The right to erasure – You have the right
                                      to request that we erase your personal
                                      data, under certain conditions.
                                    </p>
                                    <p>
                                      The right to restrict processing – You
                                      have the right to request that we restrict
                                      the processing of your personal data,
                                      under certain conditions.
                                    </p>
                                    <p>
                                      The right to object to processing – You
                                      have the right to object to our processing
                                      of your personal data, under certain
                                      conditions.
                                    </p>
                                    <p>
                                      The right to data portability – You have
                                      the right to request that we transfer the
                                      data that we have collected to another
                                      organization, or directly to you, under
                                      certain conditions.
                                    </p>
                                    <p>
                                      If you make a request, we have one month
                                      to respond to you. If you would like to
                                      exercise any of these rights, please
                                      contact us.
                                    </p>
                                    <br />
                                    <div className="wpb_text_column wpb_content_element ">
                                      <div className="wpb_wrapper">
                                        <h3 style={{ color: "#2c498a" }}>
                                          Children’s Information
                                        </h3>
                                      </div>
                                    </div>
                                    <p>
                                      Another part of our priority is adding
                                      protection for children while using the
                                      internet. We encourage parents and
                                      guardians to observe, participate in,
                                      and/or monitor and guide their online
                                      activity.
                                    </p>
                                    <p>
                                      www.newry.co.in does not knowingly collect
                                      any Personal Identifiable Information from
                                      children under the age of 13. If you think
                                      that your child provided this kind of
                                      information on our website, we strongly
                                      encourage you to contact us immediately
                                      and we will do our best efforts to
                                      promptly remove such information from our
                                      records.
                                    </p>
                                    <br />
                                  </div>
                                </div>
                                <div
                                  className="vc_empty_space"
                                  style={{ height: 32 }}
                                >
                                  <span className="vc_empty_space_inner" />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

    </>;
}

export default PrivacyPolicy;