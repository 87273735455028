const BlogDetails = () =>{
    return <>
    <link rel="stylesheet" href="css/BlogDetails.module.css" />
        {/*BLOG START*/}
<div className="mkd-content">
  <div className="mkd-content-inner"> 
    {/*SLIDER START*/}
    <div className="mkd-ps-image-holder">
      <div className="mkd-ps-image mkd-owl-slider">
        <div className="mkd-ps-image">
          <picture>
            <source media="(min-width: 481px)" srcSet="assets/blog/banner.jpg" />
            <source media="(max-width: 480px)" srcSet="assets/blog/banner-mobile.jpg" />
            <img className="d-block w-100" src="assets/blog/banner-mobile.jpg" alt="Banner" />
          </picture> 
        </div>  
      </div>
    </div>
    {/*SLIDER END*/}
    <div className="vc_empty_space" style={{height: '60px'}}>
      <span className="vc_empty_space_inner" />
    </div>
    <div className="mkd-container">
      <div className="mkd-container-inner clearfix">
        <div className="mkd-grid-row mkd-content-has-sidebar mkd-grid-medium-gutter">
          <div className="mkd-page-content-holder mkd-grid-col-8">
            <div className="mkd-blog-holder mkd-blog-single mkd-blog-single-standard">
              <article id="post-176" className="post-176 post type-post status-publish format-standard has-post-thumbnail hentry category-uncategorized tag-design">
                <div className="mkd-post-content"> 
                  <div className="mkd-post-text">
                    <div className="mkd-post-text-inner">
                      <div className="mkd-post-info-top">
                        <div itemProp="dateCreated" className="mkd-post-info-date entry-date published updated">
                          <a itemProp="url" href="#">
                            November 26, 2022</a>
                          <meta itemProp="interactionCount" content="UserComments: 2" />
                        </div> 
                      </div>
                      <div className="mkd-post-text-main">
                        <h3 itemProp="name" className="entry-title mkd-post-title">
                          Why Should You Invest in Premium Villa Plots</h3>
                        <div className="vc_empty_space" style={{height: '20px'}}>
                          <span className="vc_empty_space_inner" />
                        </div>
                        <section className="wpb-content-wrapper">
                          <div className="vc_row wpb_row vc_row-fluid">
                            <div className="wpb_column vc_column_container vc_col-sm-12">
                              <div className="vc_column-inner">
                                <div className="wpb_wrapper">
                                  <div className="vc_empty_space" style={{height: '4px'}}><span className="vc_empty_space_inner" />
                                  </div>
                                  <div className="wpb_text_column wpb_content_element ">
                                    <div className="wpb_wrapper">
                                      <p>There are some decisions that lead you to your dream life. Investing in your future home is the biggest one. Finding the ideal abode for your coming years makes you consider a ton of things. You search for the perfect location, hunt for vibrant amenities, look for a luxurious and peaceful lifestyle. It’s important for your new home to check all the boxes.</p>
                                    </div>
                                  </div>
                                  <div className="vc_empty_space" style={{height: '30px'}}><span className="vc_empty_space_inner" />
                                  </div>
                                  <h4 itemProp="name" className="mkd-pli-title entry-title">Newry Clover County: The best investment you can make in 2022</h4>
                                  <div className="wpb_text_column wpb_content_element ">
                                    <div className="wpb_wrapper">
                                      <p>Newry properties has recently introduced the perfect match for all your needs. Be it luxe or modernity, Newry Clover County gets it right. The property hosts 224 Premium Villa Plots, spread across 5.78 acres of a gorgeous landscape.</p>
                                      <p>Located at Poonamallee – Avadi Road, an investment in Clover County is destined to be your most profitable and productive investment. The locality is home to a vibrant neighbourhood on the line for further developments. It gives you the opportunity to build an extravagant lifestyle from the ground up.</p>
                                    </div>
                                  </div>
                                  <div className="vc_empty_space" style={{height: '30px'}}><span className="vc_empty_space_inner" />
                                  </div>
                                  <h4 itemProp="name" className="mkd-pli-title entry-title">A happening lifestyle</h4>
                                  <div className="wpb_text_column wpb_content_element ">
                                    <div className="wpb_wrapper">
                                      <p>Clover County forms a well-rounded and highly promising project. The land offers dedicated spaces for basketball and badminton courts, cricket field, and a children’s area.</p>
                                      <p>The meditation zone, yoga deck, jogging tracks in the property gives you a step into a high-spirited lifestyle. Clover County hosts an elegant environment lined with peaceful street lights, a landscaped park, and a relaxing Gazebo.</p>
                                    </div>
                                  </div>
                                  <div className="vc_empty_space" style={{height: '30px'}}><span className="vc_empty_space_inner" />
                                  </div>
                                  <h4 itemProp="name" className="mkd-pli-title entry-title">Everything you need</h4>
                                  <div className="wpb_text_column wpb_content_element ">
                                    <div className="wpb_wrapper">
                                      <p>The property promises to meet all your desires. Plots in Clover County are smartly designed with stormwater drains, electrical cable trenches and aesthetic blacktop roads. Your home will be in a tranquil environment, a move away from the hustle and bustle outside. With such an array of amenities, Clover County has every reason to become your dream home.</p> 
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>  
                        </section>
                      </div> 
                    </div>
                  </div>
                </div>
              </article>   
            </div>
          </div>
          <div className="mkd-sidebar-holder mkd-grid-col-4">
            <aside className="mkd-sidebar">
              <div id="search-2" className="widget widget_search">
                <form role="search" method="get" className="searchform" id="searchform-925" action>
                  <label className="screen-reader-text">Search for:</label>
                  <div className="input-holder clearfix">
                    <input value="" type="search" className="search-field" placeholder="Search" name="s" title="Search for:" />
                    <button type="submit" className="mkd-search-submit"><img src="https://entre.qodeinteractive.com/wp-content/themes/entre/assets/img/search_icon_dark.png" alt="search-icon" /></button>
                  </div>
                </form>
              </div>
              <div className="widget mkd-separator-widget">
                <div className="mkd-separator-holder clearfix  mkd-separator-center mkd-separator-normal">
                  <div className="mkd-separator" style={{borderStyle: 'solid', marginTop: '-11px'}} />
                </div>
              </div>
              <div className="widget mkd-blog-slider-widget">
                <div className="mkd-widget-title-holder">
                  <h4 className="mkd-widget-title">Recent posts</h4>
                </div>
                <div className="mkd-blog-slider-holder mkd-bs-slider">
                  <ul className="mkd-blog-slider mkd-owl-slider" data-number-of-items={1} data-enable-pagination="yes">
                    <li className="mkd-blog-slider-item">
                      <div className="mkd-blog-slider-item-inner">  
                        <div className="mkd-item-image">
                          <a itemProp="url" href="https://optimaresponse.com/development/newry/version1/blog-details.html">
                            <img width={757} height={418} src="assets/blog/blog1-thumb.jpg" className="attachment-full size-full wp-post-image" alt="a" loading="lazy" /> </a>
                        </div>
                        <div className="mkd-item-text-wrapper">
                          <div className="mkd-item-text-holder">
                            <div className="mkd-item-text-holder-inner">
                              <div className="mkd-item-info-section">
                              </div>
                              <h4 itemProp="name" className="entry-title mkd-post-title">
                                <a itemProp="url" href="https://optimaresponse.com/development/newry/version1/blog-details.html" title="Home decore">
                                  Why Should You Invest in Premium Villa Plots </a>
                              </h4>
                              <div itemProp="dateCreated" className="mkd-post-info-date entry-date published updated">
                                <a itemProp="url" href="https://optimaresponse.com/development/newry/version1/blog-details.html">
                                  November 26, 2022 </a>
                                <meta itemProp="interactionCount" content="UserComments: 2" />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </li>
                    <li className="mkd-blog-slider-item">
                      <div className="mkd-blog-slider-item-inner">
                        <div className="mkd-item-image">
                          <a itemProp="url" href="https://optimaresponse.com/development/newry/version1/blog1.html">
                            <img width={757} height={418} src="assets/blog/blog2-thumb.jpg" className="attachment-full size-full wp-post-image" alt="a" loading="lazy" /> </a>
                        </div>
                        <div className="mkd-item-text-wrapper">
                          <div className="mkd-item-text-holder">
                            <div className="mkd-item-text-holder-inner">
                              <div className="mkd-item-info-section">
                              </div>
                              <h4 itemProp="name" className="entry-title mkd-post-title">
                                <a itemProp="url" href="https://optimaresponse.com/development/newry/version1/blog1.html" title="Spring set up">
                                  Find The Home of Your Dreams and The Lifestyle You Deserve </a>
                              </h4>
                              <div itemProp="dateCreated" className="mkd-post-info-date entry-date published updated">
                                <a itemProp="url" href="https://optimaresponse.com/development/newry/version1/blog1.html">
                                  November 12, 2022</a>
                                <meta itemProp="interactionCount" content="UserComments: 2" />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </li>
                    <li className="mkd-blog-slider-item">
                      <div className="mkd-blog-slider-item-inner">
                        <div className="mkd-item-image">
                          <a itemProp="url" href="https://optimaresponse.com/development/newry/version1/blog2.html">
                            <img width={757} height={418} src="assets/blog/blog3-thumb.jpg" className="attachment-full size-full wp-post-image" alt="a" loading="lazy" /> </a>
                        </div>
                        <div className="mkd-item-text-wrapper">
                          <div className="mkd-item-text-holder">
                            <div className="mkd-item-text-holder-inner">
                              <div className="mkd-item-info-section">
                              </div>
                              <h4 itemProp="name" className="entry-title mkd-post-title">
                                <a itemProp="url" href="https://optimaresponse.com/development/newry/version1/blog2.html" title="Bed Rooms">
                                  What is Normal Plot Development &amp; Integrated Township?</a>
                              </h4>
                              <div itemProp="dateCreated" className="mkd-post-info-date entry-date published updated">
                                <a itemProp="url" href="https://optimaresponse.com/development/newry/version1/blog2.html">
                                  October 15, 2022 </a>
                                <meta itemProp="interactionCount" content="UserComments: 2" />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="widget mkd-separator-widget">
                <div className="mkd-separator-holder clearfix  mkd-separator-center mkd-separator-normal">
                  <div className="mkd-separator" style={{borderStyle: 'solid', marginTop: '-10px'}} />
                </div>
              </div>
              <div id="categories-2" className="widget widget_categories">
                <div className="mkd-widget-title-holder">
                  <h4 className="mkd-widget-title">Categories</h4>
                </div>
                <ul>
                  <li className="cat-item cat-item-85"><a href="#">Project</a>
                  </li>
                  <li className="cat-item cat-item-31"><a href="#">Financial</a>
                  </li>
                  <li className="cat-item cat-item-30"><a href="#">Real Estate</a>
                  </li>
                  <li className="cat-item cat-item-33"><a href="#">Home Guide</a>
                  </li>
                  <li className="cat-item cat-item-34"><a href="#">Locations</a>
                  </li> 
                  <li className="cat-item cat-item-34"> 
                  </li> 
                </ul>
              </div>
              <div className="widget mkd-separator-widget">
                <div className="mkd-separator-holder clearfix  mkd-separator-center mkd-separator-normal">
                  <div className="mkd-separator" style={{borderStyle: 'solid', marginTop: '-12px'}} />
                </div>
              </div> 
            </aside>
          </div>
        </div>
      </div>
    </div>
  </div></div>

    </>
};

export default BlogDetails;