const BlogListing = () => {
    return <>
        <link rel="stylesheet" href="css/BlogListing.module.css" />
            {/*BLOG START*/}
            <div className="mkd-content">
                <div className="mkd-content-inner">
                    {/*SLIDER START*/}
                    <div className="mkd-ps-image-holder">
                        <div className="mkd-ps-image mkd-owl-slider">
                            <div className="mkd-ps-image">
                                <picture>
                                    <source
                                        media="(min-width: 481px)"
                                        srcSet="assets/blog/blog-banner.jpg"
                                    />
                                    <source
                                        media="(max-width: 480px)"
                                        srcSet="assets/blog/blog-banner-mobile.jpg"
                                    />
                                    <img
                                        className="d-block w-100"
                                        src="assets/blog/blog-banner-mobile.jpg"
                                        alt="Banner"
                                    />
                                </picture>
                            </div>
                        </div>
                    </div>
                    {/*SLIDER END*/}
                    <div className="vc_empty_space" style={{ height: 20 }}>
                        <span className="vc_empty_space_inner" />
                    </div>
                    <div className="mkd-container">
                        <div className="mkd-container-inner clearfix">
                            <div className="mkd-grid-row mkd-grid-medium-gutter">
                                <div className="mkd-page-content-holder mkd-grid-col-8">
                                    <div
                                        className="mkd-blog-holder mkd-blog-standard mkd-blog-pagination-standard mkd-masonry-images-fixed"
                                        data-blog-type="standard"
                                        data-next-page={2}
                                        data-max-num-pages={2}
                                        data-post-number={4}
                                        data-excerpt-length={60}
                                        data-category="furniture"
                                    >
                                        <div className="mkd-blog-holder-inner">
                                            <article
                                                id="post-1191"
                                                className="mkd-post-has-media post-1191 post type-post status-publish format-standard has-post-thumbnail hentry category-furniture category-sport tag-design tag-sport"
                                            >
                                                <div className="mkd-post-content">
                                                    <div className="mkd-post-heading">
                                                        <div className="mkd-post-image">
                                                            <a
                                                                itemProp="url"
                                                                href="https://www.newry.co.in/blog-details"
                                                                title="Global Influence"
                                                            >
                                                                <img
                                                                    width={1200}
                                                                    height={1075}
                                                                    src="assets/blog/blog1.jpg"
                                                                    className="attachment-full size-full"
                                                                    alt="a"
                                                                    loading="lazy"
                                                                />
                                                            </a>
                                                        </div>
                                                    </div>
                                                    <div className="mkd-post-text">
                                                        <div className="mkd-post-text-inner">
                                                            <div className="mkd-post-info-top">
                                                                <div
                                                                    itemProp="dateCreated"
                                                                    className="mkd-post-info-date entry-date published updated"
                                                                >
                                                                    <a
                                                                        itemProp="url"
                                                                        href="https://www.newry.co.in/blog-details"
                                                                    >
                                                                        November 26, 2022
                                                                    </a>
                                                                    <meta
                                                                        itemProp="interactionCount"
                                                                        content="UserComments: 0"
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div className="mkd-post-text-main">
                                                                <h3
                                                                    itemProp="name"
                                                                    className="entry-title mkd-post-title"
                                                                >
                                                                    <a
                                                                        itemProp="url"
                                                                        href="https://www.newry.co.in/blog-details"
                                                                        title="Global Influence"
                                                                    >
                                                                        Why Should You Invest in Premium Villa Plots
                                                                    </a>
                                                                </h3>
                                                                <div className="mkd-post-excerpt-holder">
                                                                    <p
                                                                        itemProp="description"
                                                                        className="mkd-post-excerpt"
                                                                    >
                                                                        There are some decisions that lead you to your
                                                                        dream life. Investing in your future home is the
                                                                        biggest one. Finding the ideal abode for your
                                                                        coming years makes you consider a ton of things.
                                                                        You search for the perfect location, hunt for
                                                                        vibrant amenities, look for a luxurious and
                                                                        peaceful lifestyle. It's important for your new
                                                                        home to check all the boxes.
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </article>
                                            <article
                                                id="post-1191"
                                                className="mkd-post-has-media post-1191 post type-post status-publish format-standard has-post-thumbnail hentry category-furniture category-sport tag-design tag-sport"
                                            >
                                                <div className="mkd-post-content">
                                                    <div className="mkd-post-heading">
                                                        <div className="mkd-post-image">
                                                            <a
                                                                itemProp="url"
                                                                href="https://www.newry.co.in/blog1"
                                                                title="Global Influence"
                                                            >
                                                                <img
                                                                    width={1200}
                                                                    height={1075}
                                                                    src="assets/blog/blog2.jpg"
                                                                    className="attachment-full size-full"
                                                                    alt="a"
                                                                    loading="lazy"
                                                                />
                                                            </a>
                                                        </div>
                                                    </div>
                                                    <div className="mkd-post-text">
                                                        <div className="mkd-post-text-inner">
                                                            <div className="mkd-post-info-top">
                                                                <div
                                                                    itemProp="dateCreated"
                                                                    className="mkd-post-info-date entry-date published updated"
                                                                >
                                                                    <a
                                                                        itemProp="url"
                                                                        href="https://www.newry.co.in/blog1"
                                                                    >
                                                                        November 12, 2022
                                                                    </a>
                                                                    <meta
                                                                        itemProp="interactionCount"
                                                                        content="UserComments: 0"
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div className="mkd-post-text-main">
                                                                <h3
                                                                    itemProp="name"
                                                                    className="entry-title mkd-post-title"
                                                                >
                                                                    <a
                                                                        itemProp="url"
                                                                        href="https://www.newry.co.in/blog1"
                                                                        title="Global Influence"
                                                                    >
                                                                        Find The Home of Your Dreams and The Lifestyle
                                                                        You Deserve
                                                                    </a>
                                                                </h3>
                                                                <div className="mkd-post-excerpt-holder">
                                                                    <p
                                                                        itemProp="description"
                                                                        className="mkd-post-excerpt"
                                                                    >
                                                                        Chennai is the hub of mesmerising properties.
                                                                        It's a treat to live in this vibrant town and
                                                                        experiencing the thrill of city life. Having a
                                                                        luxurious abode here is the closest you'll get
                                                                        to heaven on earth. It makes the gateway to a
                                                                        sophisticated lifestyle filled with serenity and
                                                                        elegance.
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </article>
                                            <article
                                                id="post-1191"
                                                className="mkd-post-has-media post-1191 post type-post status-publish format-standard has-post-thumbnail hentry category-furniture category-sport tag-design tag-sport"
                                            >
                                                <div className="mkd-post-content">
                                                    <div className="mkd-post-heading">
                                                        <div className="mkd-post-image">
                                                            <a
                                                                itemProp="url"
                                                                href="https://www.newry.co.in/blog2"
                                                                title="Global Influence"
                                                            >
                                                                <img
                                                                    width={1200}
                                                                    height={1075}
                                                                    src="assets/blog/blog3.jpg"
                                                                    className="attachment-full size-full"
                                                                    alt="a"
                                                                    loading="lazy"
                                                                />
                                                            </a>
                                                        </div>
                                                    </div>
                                                    <div className="mkd-post-text">
                                                        <div className="mkd-post-text-inner">
                                                            <div className="mkd-post-info-top">
                                                                <div
                                                                    itemProp="dateCreated"
                                                                    className="mkd-post-info-date entry-date published updated"
                                                                >
                                                                    <a
                                                                        itemProp="url"
                                                                        href="https://www.newry.co.in/blog2"
                                                                    >
                                                                        October 15, 2022
                                                                    </a>
                                                                    <meta
                                                                        itemProp="interactionCount"
                                                                        content="UserComments: 0"
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div className="mkd-post-text-main">
                                                                <h3
                                                                    itemProp="name"
                                                                    className="entry-title mkd-post-title"
                                                                >
                                                                    <a
                                                                        itemProp="url"
                                                                        href="https://www.newry.co.in/blog2"
                                                                        title="Global Influence"
                                                                    >
                                                                        What is Normal Plot Development &amp; Integrated
                                                                        Township?
                                                                    </a>
                                                                </h3>
                                                                <div className="mkd-post-excerpt-holder">
                                                                    <p
                                                                        itemProp="description"
                                                                        className="mkd-post-excerpt"
                                                                    >
                                                                        Normal Plotted developments are subdivisions of
                                                                        land into plots or lots for sale or lease to
                                                                        homebuilders for new housing developments. The
                                                                        plots typically have similar dimensions, with no
                                                                        land between, and are usually divided by a
                                                                        network of roads. One would have to build...
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </article>
                                        </div>
                                        <div className="mkd-blog-pagination">
                                            <ul>
                                                <li className="mkd-pag-number">
                                                    <a className="mkd-pag-active" href="#">
                                                        1
                                                    </a>
                                                </li>
                                                <li className="mkd-pag-number">
                                                    <a itemProp="url" className="mkd-pag-inactive" href="#">
                                                        2
                                                    </a>
                                                </li>
                                                <li className="mkd-pag-next">
                                                    <a itemProp="url" href="#">
                                                        <span className="arrow_carrot-right" />
                                                    </a>
                                                </li>
                                            </ul>
                                        </div>
                                        <div className="mkd-blog-pagination-wp"></div>
                                    </div>
                                </div>
                                <div className="mkd-sidebar-holder mkd-grid-col-4">
                                    <aside className="mkd-sidebar">
                                        <div id="search-2" className="widget widget_search">
                                            <form
                                                role="search"
                                                method="get"
                                                className="searchform"
                                                id="searchform-925"
                                                action=""
                                            >
                                                <label className="screen-reader-text">Search for:</label>
                                                <div className="input-holder clearfix">
                                                    <input
                                                        type="search"
                                                        className="search-field"
                                                        placeholder="Search"
                                                        defaultValue=""
                                                        name="s"
                                                        title="Search for:"
                                                    />
                                                    <button type="submit" className="mkd-search-submit">
                                                        <img
                                                            src="https://entre.qodeinteractive.com/wp-content/themes/entre/assets/img/search_icon_dark.png"
                                                            alt="search-icon"
                                                        />
                                                    </button>
                                                </div>
                                            </form>
                                        </div>
                                        <div className="widget mkd-separator-widget">
                                            <div className="mkd-separator-holder clearfix  mkd-separator-center mkd-separator-normal">
                                                <div
                                                    className="mkd-separator"
                                                    style={{ borderStyle: "solid", marginTop: "-11px" }}
                                                />
                                            </div>
                                        </div>
                                        <div className="widget mkd-blog-slider-widget">
                                            <div className="mkd-widget-title-holder">
                                                <h4 className="mkd-widget-title">Recent posts</h4>
                                            </div>
                                            <div className="mkd-blog-slider-holder mkd-bs-slider">
                                                <ul
                                                    className="mkd-blog-slider mkd-owl-slider"
                                                    data-number-of-items={1}
                                                    data-enable-pagination="yes"
                                                >
                                                    <li className="mkd-blog-slider-item">
                                                        <div className="mkd-blog-slider-item-inner">
                                                            <div className="mkd-item-image">
                                                                <a
                                                                    itemProp="url"
                                                                    href="https://www.newry.co.in/blog-details"
                                                                >
                                                                    <img
                                                                        width={757}
                                                                        height={418}
                                                                        src="assets/blog/blog1-thumb.jpg"
                                                                        className="attachment-full size-full wp-post-image"
                                                                        alt="a"
                                                                        loading="lazy"
                                                                    />
                                                                </a>
                                                            </div>
                                                            <div className="mkd-item-text-wrapper">
                                                                <div className="mkd-item-text-holder">
                                                                    <div className="mkd-item-text-holder-inner">
                                                                        <div className="mkd-item-info-section"></div>
                                                                        <h4
                                                                            itemProp="name"
                                                                            className="entry-title mkd-post-title"
                                                                        >
                                                                            <a
                                                                                itemProp="url"
                                                                                href="https://www.newry.co.in/blog-details"
                                                                                title="Home decore"
                                                                            >
                                                                                Why Should You Invest in Premium Villa Plots
                                                                            </a>
                                                                        </h4>
                                                                        <div
                                                                            itemProp="dateCreated"
                                                                            className="mkd-post-info-date entry-date published updated"
                                                                        >
                                                                            <a
                                                                                itemProp="url"
                                                                                href="https://www.newry.co.in/blog-details"
                                                                            >
                                                                                November 26, 2022
                                                                            </a>
                                                                            <meta
                                                                                itemProp="interactionCount"
                                                                                content="UserComments: 2"
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </li>
                                                    <li className="mkd-blog-slider-item">
                                                        <div className="mkd-blog-slider-item-inner">
                                                            <div className="mkd-item-image">
                                                                <a
                                                                    itemProp="url"
                                                                    href="https://www.newry.co.in/blog1"
                                                                >
                                                                    <img
                                                                        width={757}
                                                                        height={418}
                                                                        src="assets/blog/blog2-thumb.jpg"
                                                                        className="attachment-full size-full wp-post-image"
                                                                        alt="a"
                                                                        loading="lazy"
                                                                    />
                                                                </a>
                                                            </div>
                                                            <div className="mkd-item-text-wrapper">
                                                                <div className="mkd-item-text-holder">
                                                                    <div className="mkd-item-text-holder-inner">
                                                                        <div className="mkd-item-info-section"></div>
                                                                        <h4
                                                                            itemProp="name"
                                                                            className="entry-title mkd-post-title"
                                                                        >
                                                                            <a
                                                                                itemProp="url"
                                                                                href="https://www.newry.co.in/blog1"
                                                                                title="Spring set up"
                                                                            >
                                                                                Find The Home of Your Dreams and The
                                                                                Lifestyle You Deserve
                                                                            </a>
                                                                        </h4>
                                                                        <div
                                                                            itemProp="dateCreated"
                                                                            className="mkd-post-info-date entry-date published updated"
                                                                        >
                                                                            <a
                                                                                itemProp="url"
                                                                                href="https://www.newry.co.in/blog1"
                                                                            >
                                                                                November 12, 2022
                                                                            </a>
                                                                            <meta
                                                                                itemProp="interactionCount"
                                                                                content="UserComments: 2"
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </li>
                                                    <li className="mkd-blog-slider-item">
                                                        <div className="mkd-blog-slider-item-inner">
                                                            <div className="mkd-item-image">
                                                                <a
                                                                    itemProp="url"
                                                                    href="https://www.newry.co.in/blog2"
                                                                >
                                                                    <img
                                                                        width={757}
                                                                        height={418}
                                                                        src="assets/blog/blog3-thumb.jpg"
                                                                        className="attachment-full size-full wp-post-image"
                                                                        alt="a"
                                                                        loading="lazy"
                                                                    />
                                                                </a>
                                                            </div>
                                                            <div className="mkd-item-text-wrapper">
                                                                <div className="mkd-item-text-holder">
                                                                    <div className="mkd-item-text-holder-inner">
                                                                        <div className="mkd-item-info-section"></div>
                                                                        <h4
                                                                            itemProp="name"
                                                                            className="entry-title mkd-post-title"
                                                                        >
                                                                            <a
                                                                                itemProp="url"
                                                                                href="https://www.newry.co.in/blog2"
                                                                                title="Bed Rooms"
                                                                            >
                                                                                What is Normal Plot Development &amp;
                                                                                Integrated Township?
                                                                            </a>
                                                                        </h4>
                                                                        <div
                                                                            itemProp="dateCreated"
                                                                            className="mkd-post-info-date entry-date published updated"
                                                                        >
                                                                            <a
                                                                                itemProp="url"
                                                                                href="https://www.newry.co.in/blog2"
                                                                            >
                                                                                October 15, 2022
                                                                            </a>
                                                                            <meta
                                                                                itemProp="interactionCount"
                                                                                content="UserComments: 2"
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                        <div className="widget mkd-separator-widget">
                                            <div className="mkd-separator-holder clearfix  mkd-separator-center mkd-separator-normal">
                                                <div
                                                    className="mkd-separator"
                                                    style={{ borderStyle: "solid", marginTop: "-10px" }}
                                                />
                                            </div>
                                        </div>
                                        <div id="categories-2" className="widget widget_categories">
                                            <div className="mkd-widget-title-holder">
                                                <h4 className="mkd-widget-title">Categories</h4>
                                            </div>
                                            <ul>
                                                <li className="cat-item cat-item-85">
                                                    <a href="#">Project</a>
                                                </li>
                                                <li className="cat-item cat-item-31">
                                                    <a href="#">Financial</a>
                                                </li>
                                                <li className="cat-item cat-item-30">
                                                    <a href="#">Real Estate</a>
                                                </li>
                                                <li className="cat-item cat-item-33">
                                                    <a href="#">Home Guide</a>
                                                </li>
                                                <li className="cat-item cat-item-34">
                                                    <a href="#">Locations</a>
                                                </li>
                                                <li className="cat-item cat-item-34"></li>
                                            </ul>
                                        </div>
                                        <div className="widget mkd-separator-widget">
                                            <div className="mkd-separator-holder clearfix  mkd-separator-center mkd-separator-normal">
                                                <div
                                                    className="mkd-separator"
                                                    style={{ borderStyle: "solid", marginTop: "-12px" }}
                                                />
                                            </div>
                                        </div>
                                    </aside>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

    </>
}

export default BlogListing;