const Awards = () => {
    return <>
        <link rel="stylesheet" href="css/Awards.module.css" />
        <div className="mkd-content">
            <div className="mkd-ps-image-holder">
                <div className="mkd-ps-image mkd-owl-slider">
                    <div className="mkd-ps-image">
                        <picture>
                            <source media="(min-width: 481px)" srcSet="assets/freepik/10.jpg" />
                            <source media="(max-width: 480px)" srcSet="assets/freepik/11.jpg" />
                            <img
                                className="d-block w-100"
                                src="assets/freepik/11.jpg"
                                alt="Banner"
                            />
                        </picture>
                    </div>
                </div>
            </div>
            <div className="vc_empty_space" style={{ height: 20 }}>
                <span className="vc_empty_space_inner" />
            </div>
            <div className="mkd-content-inner">
                <div className="mkd-full-width">
                    <div className="mkd-full-width-inner">
                        <div className="mkd-grid-row">
                            <section className="wpb-content-wrapper location-advantages">
                                <div className="mkd-row-grid-section-wrapper ">
                                    <div className="mkd-row-grid-section">
                                        <div className="vc_row wpb_row vc_row-fluid vc_custom_1516021087353">
                                            <div className="wpb_column vc_column_container vc_col-sm-12">
                                                <div className="vc_column-inner vc_custom_1516007647796">
                                                    <div className="wpb_wrapper">
                                                        <div className="mkd-elements-holder   mkd-one-column  mkd-responsive-mode-768 ">
                                                            <div
                                                                className="mkd-eh-item    "
                                                                data-item-class="mkd-eh-custom-8773"
                                                                data-1280-1600="0 0 20px 0"
                                                                data-1024-1280="0 0 20px 0"
                                                                data-768-1024="0 0 20px 0"
                                                                data-680-768="0 0 20px 0"
                                                                data-680="0px"
                                                            >
                                                                <div className="mkd-eh-item-inner">
                                                                    <div
                                                                        className="mkd-eh-item-content mkd-eh-custom-8773"
                                                                        style={{ padding: "0 0 20px 0" }}
                                                                    >
                                                                        <div className="mkd-section-title-holder  ">
                                                                            <div className="mkd-st-inner">
                                                                                <h2 className="mkd-st-title">Awards</h2>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </section>
                            <br />
                            <div className="mkd-page-content-holder mkd-grid-col-12">
                                <div className="mkd-row-grid-section-wrapper ">
                                    <div className="mkd-row-grid-section">
                                        <div className="vc_row wpb_row vc_row-fluid vc_custom_1517909237365">
                                            <div className="wpb_column vc_column_container vc_col-sm-12 vc_col-lg-6 vc_col-md-12 vc_col-xs-12">
                                                <div className="vc_column-inner vc_custom_1516720842737">
                                                    <div className="wpb_wrapper">
                                                        <div className="mkd-elements-holder   mkd-one-column  mkd-responsive-mode-768 ">
                                                            <div
                                                                className="mkd-eh-item    "
                                                                data-item-class="mkd-eh-custom-2053"
                                                                data-1280-1600="0 12px 0 25px"
                                                                data-1024-1280="0 12px 0 0"
                                                                data-768-1024="0 33px 0 0"
                                                                data-680-768="0 33px 0 0"
                                                                data-680="0 43px 0 10px"
                                                            >
                                                                <div className="mkd-eh-item-inner">
                                                                    <div
                                                                        className="mkd-eh-item-content mkd-eh-custom-2053"
                                                                        style={{ padding: "0 12px 0 25px" }}
                                                                    >
                                                                        <div className="mkd-single-image-holder   mkd-image-has-drop-shadow-effect  mkd-image-drop-shadow-left">
                                                                            <div className="mkd-si-inner">
                                                                                <img
                                                                                    width={800}
                                                                                    height={1166}
                                                                                    src="assets/aboutus/awards.jpg"
                                                                                    className="attachment-full size-full"
                                                                                    alt="a"
                                                                                    loading="lazy"
                                                                                />
                                                                                <div
                                                                                    className="mkd-si-drop-shadow"
                                                                                    style={{ backgroundColor: "#c3c7d0" }}
                                                                                ></div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="wpb_column vc_column_container vc_col-sm-12 vc_col-lg-6 vc_col-md-12 vc_col-xs-12">
                                                <div className="vc_column-inner vc_custom_1516720853580">
                                                    <div className="wpb_wrapper">
                                                        <div className="mkd-elements-holder   mkd-one-column  mkd-responsive-mode-768 ">
                                                            <div className="mkd-eh-item    ">
                                                                <div className="mkd-eh-item-inner">
                                                                    <div className="mkd-eh-item-content mkd-eh-custom-7445">
                                                                        <div className="mkd-progress-bar ">
                                                                            <h4 className="mkd-pb-title-holder">
                                                                                <span className="mkd-pb-title">
                                                                                    Golden Globe Tiger Awards 2018
                                                                                </span>
                                                                            </h4>
                                                                            <p>Commercial Property of the Year</p>
                                                                            <div className="mkd-pb-content-holder">
                                                                                <div
                                                                                    data-percentage={40}
                                                                                    className="mkd-pb-content"
                                                                                ></div>
                                                                            </div>
                                                                        </div>
                                                                        <div
                                                                            className="vc_empty_space"
                                                                            style={{ height: 32 }}
                                                                        >
                                                                            <span className="vc_empty_space_inner" />
                                                                        </div>
                                                                        <div className="mkd-progress-bar ">
                                                                            <h4 className="mkd-pb-title-holder">
                                                                                <span className="mkd-pb-title">
                                                                                    BAM Awards 2018
                                                                                </span>
                                                                            </h4>
                                                                            <p>Builder Award of the Year</p>
                                                                            <div className="mkd-pb-content-holder">
                                                                                <div
                                                                                    data-percentage={30}
                                                                                    className="mkd-pb-content"
                                                                                ></div>
                                                                            </div>
                                                                        </div>
                                                                        <div
                                                                            className="vc_empty_space"
                                                                            style={{ height: 37 }}
                                                                        >
                                                                            <span className="vc_empty_space_inner" />
                                                                        </div>
                                                                        <div className="mkd-progress-bar ">
                                                                            <h4 className="mkd-pb-title-holder">
                                                                                <span className="mkd-pb-title">
                                                                                    National Real Estate Excellence Awards
                                                                                    2017
                                                                                </span>
                                                                            </h4>
                                                                            <p>Best Luxury Real Estate Company</p>
                                                                            <div className="mkd-pb-content-holder">
                                                                                <div
                                                                                    data-percentage={40}
                                                                                    className="mkd-pb-content"
                                                                                ></div>
                                                                            </div>
                                                                        </div>
                                                                        <div
                                                                            className="vc_empty_space"
                                                                            style={{ height: 32 }}
                                                                        >
                                                                            <span className="vc_empty_space_inner" />
                                                                        </div>
                                                                        <div className="mkd-progress-bar ">
                                                                            <h4 className="mkd-pb-title-holder">
                                                                                <span className="mkd-pb-title">
                                                                                    ET Now Real Estate Awards 2019
                                                                                </span>
                                                                            </h4>
                                                                            <p>Most Promising Delivery</p>
                                                                            <div className="mkd-pb-content-holder">
                                                                                <div
                                                                                    data-percentage={25}
                                                                                    className="mkd-pb-content"
                                                                                ></div>
                                                                            </div>
                                                                        </div>
                                                                        <div
                                                                            className="vc_empty_space"
                                                                            style={{ height: 32 }}
                                                                        >
                                                                            <span className="vc_empty_space_inner" />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <br />
                                <section className="wpb-content-wrapper certifications">
                                    <div className="mkd-row-grid-section-wrapper ">
                                        <div className="mkd-row-grid-section">
                                            <div className="vc_row wpb_row vc_row-fluid vc_custom_1516021087353">
                                                <div className="wpb_column vc_column_container vc_col-sm-12">
                                                    <div className="vc_column-inner vc_custom_1516007647796">
                                                        <div className="wpb_wrapper">
                                                            <div className="mkd-elements-holder   mkd-one-column  mkd-responsive-mode-768 ">
                                                                <div
                                                                    className="mkd-eh-item    "
                                                                    data-item-class="mkd-eh-custom-8773"
                                                                    data-1280-1600="0 0 20px 0"
                                                                    data-1024-1280="0 0 20px 0"
                                                                    data-768-1024="0 0 20px 0"
                                                                    data-680-768="0 0 20px 0"
                                                                    data-680="0px"
                                                                >
                                                                    <div className="mkd-eh-item-inner">
                                                                        <div
                                                                            className="mkd-eh-item-content mkd-eh-custom-8773"
                                                                            style={{ padding: "0 0 20px 0" }}
                                                                        >
                                                                            <div className="mkd-section-title-holder  ">
                                                                                <div className="mkd-st-inner">
                                                                                    <h2 className="mkd-st-title">
                                                                                        Certifications
                                                                                    </h2>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </section>
                                <br />
                                <br />
                                <div className="mkd-row-grid-section-wrapper ">
                                    <div className="mkd-row-grid-section">
                                        <div className="vc_row wpb_row vc_row-fluid vc_custom_1515425824843 vc_row-o-equal-height vc_row-flex">
                                            <div className="wpb_column vc_column_container vc_col-sm-6">
                                                <div className="vc_column-inner vc_custom_1515423400287">
                                                    <div className="wpb_wrapper">
                                                        <div className="mkd-elements-holder   mkd-one-column  mkd-responsive-mode-768 ">
                                                            <div
                                                                className="mkd-eh-item    "
                                                                data-item-class="mkd-eh-custom-4736"
                                                                data-1280-1600="0 18px 0 17px"
                                                                data-1024-1280="0 18px 0 17px"
                                                                data-768-1024="0 18px 0 6px"
                                                                data-680-768="0 18px 0 2px"
                                                                data-680="0 0 20px 0"
                                                            >
                                                                <div className="mkd-eh-item-inner">
                                                                    <div
                                                                        className="mkd-eh-item-content mkd-eh-custom-4736"
                                                                        style={{ padding: "0 18px 0 20px" }}
                                                                    >
                                                                        <div className="mkd-single-image-holder  mkd-image-behavior-lightbox   ">
                                                                            <div className="mkd-si-inner">
                                                                                <a
                                                                                    itemProp="image"
                                                                                    href="assets/aboutus/certificate1.png"
                                                                                    data-rel="prettyPhoto[si_pretty_photo]"
                                                                                    title="Goldy vase"
                                                                                >
                                                                                    <img
                                                                                        width="100%"
                                                                                        src="assets/aboutus/certificate1.png"
                                                                                        className="attachment-full size-full"
                                                                                        alt="Goldy vase"
                                                                                        loading="lazy"
                                                                                    />
                                                                                </a>
                                                                                <h3 className="certification-heading">
                                                                                    ISO 14001:2015{" "}
                                                                                </h3>
                                                                                <h3 className="certification-title">
                                                                                    Environmental Management System{" "}
                                                                                </h3>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="wpb_column vc_column_container vc_col-sm-6">
                                                <div className="vc_column-inner vc_custom_1515423400287">
                                                    <div className="wpb_wrapper">
                                                        <div className="mkd-elements-holder   mkd-one-column  mkd-responsive-mode-768 ">
                                                            <div
                                                                className="mkd-eh-item    "
                                                                data-item-class="mkd-eh-custom-4736"
                                                                data-1280-1600="0 18px 0 17px"
                                                                data-1024-1280="0 18px 0 17px"
                                                                data-768-1024="0 18px 0 6px"
                                                                data-680-768="0 18px 0 2px"
                                                                data-680="0 0 20px 0"
                                                            >
                                                                <div className="mkd-eh-item-inner">
                                                                    <div
                                                                        className="mkd-eh-item-content mkd-eh-custom-4736"
                                                                        style={{ padding: "0 18px 0 20px" }}
                                                                    >
                                                                        <div className="mkd-single-image-holder  mkd-image-behavior-lightbox   ">
                                                                            <div className="mkd-si-inner">
                                                                                <a
                                                                                    itemProp="image"
                                                                                    href="assets/aboutus/certificate2.png"
                                                                                    data-rel="prettyPhoto[si_pretty_photo]"
                                                                                    title="Goldy vase"
                                                                                >
                                                                                    <img
                                                                                        width="100%"
                                                                                        src="assets/aboutus/certificate2.png"
                                                                                        className="attachment-full size-full"
                                                                                        alt="Goldy vase"
                                                                                        loading="lazy"
                                                                                    />
                                                                                </a>
                                                                                <h3 className="certification-heading">
                                                                                    ISO 9001:2015{" "}
                                                                                </h3>
                                                                                <h3 className="certification-title">
                                                                                    Quality Management System
                                                                                </h3>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </>;
};

export default Awards;