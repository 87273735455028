import { Outlet, Link, useNavigate } from "react-router-dom";

const Header = () => {
  const navigate = useNavigate();

  const handleClick = (e, path) => {
    e.preventDefault();
    navigate(path, { replace: true });
    window.location.href = `${window.location.origin}${path}`;
  };

  return (
    <>
      <section
        className="sticky"
        id="newry-header"
        style={{
          position: "fixed",
          width: "100%",
          background: "transparent",
          zIndex: 9999
        }}
      >
        <header
          id="site-header"
          className="site-header header-sticky header-with-sticky-template"
        >
          <div className="header-wrapper">
            <div className="header-background">
              <div className="fullwidth-content">
                <div className="thegem-template-wrapper thegem-template-header thegem-template-2583">
                  <div
                    data-elementor-type="wp-post"
                    data-elementor-id={2583}
                    className="elementor elementor-2583"
                  >
                    <div className="elementor-inner">
                      <div className="elementor-section-wrap">
                        <section
                          className="elementor-section elementor-top-section elementor-element elementor-element-168aab6 cus-header-top elementor-section-boxed elementor-section-height-default elementor-section-height-default"
                          data-id="168aab6"
                          data-element_type="section"
                        >
                          <div className="elementor-container elementor-column-gap-thegem">
                            <div className="elementor-row">
                              <div
                                className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-1c10205"
                                data-id="1c10205"
                                data-element_type="column"
                              >
                                <div className="elementor-column-wrap elementor-element-populated">
                                  <div className="thegem-column-elements-inline elementor-widget-wrap">
                                    <div
                                      className="elementor-element elementor-element-fecf383 elementor-hidden-mobile flex-horizontal-align-left area_label flex-horizontal-align-tablet-default flex-horizontal-align-mobile-default flex-vertical-align-default flex-vertical-align-tablet-default flex-vertical-align-mobile-default elementor-widget elementor-widget-thegem-template-infobox"
                                      data-id="fecf383"
                                      data-element_type="widget"
                                      data-settings='{"thegem_elementor_preset":"tiny"}'
                                      data-widget_type="thegem-template-infobox.default"
                                    >
                                      <div className="elementor-widget-container">
                                        <div className="thegem-te-infobox styled-textbox-tiny width-link">
                                          <div className="thegem-te-infobox-top thegem-te-infobox-top-none default-fill"></div>
                                          <div className="thegem-te-infobox-inner">
                                            <div className="thegem-te-infobox-content">
                                              <div className="gem-texbox-text-wrapper">
                                                <div className="thegem-te-infobox-title text-body-tiny light">
                                                  <a href="https://www.newry.co.in/careers">
                                                    Careers
                                                  </a>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <div
                                      className="elementor-element elementor-element-70fb8ec elementor-hidden-mobile flex-horizontal-align-left area_label flex-horizontal-align-tablet-default flex-horizontal-align-mobile-default flex-vertical-align-default flex-vertical-align-tablet-default flex-vertical-align-mobile-default elementor-widget elementor-widget-thegem-template-infobox"
                                      data-id="70fb8ec"
                                      data-element_type="widget"
                                      data-settings='{"thegem_elementor_preset":"tiny"}'
                                      data-widget_type="thegem-template-infobox.default"
                                    >
                                      <div className="elementor-widget-container">
                                        <div className="thegem-te-infobox styled-textbox-tiny width-link">
                                          <div className="thegem-te-infobox-top thegem-te-infobox-top-none default-fill"></div>
                                          <div className="thegem-te-infobox-inner">
                                            <div className="thegem-te-infobox-content">
                                              <div className="gem-texbox-text-wrapper">
                                                <div className="thegem-te-infobox-title text-body-tiny light">
                                                  <a href="https://www.newry.co.in/contact">
                                                    Contact
                                                  </a>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <div
                                      className="elementor-element elementor-element-e813bd8 elementor-hidden-mobile flex-horizontal-align-left area_label flex-horizontal-align-tablet-default flex-horizontal-align-mobile-default flex-vertical-align-default flex-vertical-align-tablet-default flex-vertical-align-mobile-default elementor-widget elementor-widget-thegem-template-infobox"
                                      data-id="e813bd8"
                                      data-element_type="widget"
                                      data-settings='{"thegem_elementor_preset":"tiny"}'
                                      data-widget_type="thegem-template-infobox.default"
                                    >
                                      <div className="elementor-widget-container">
                                        <div className="thegem-te-infobox styled-textbox-tiny width-link">
                                          <div className="thegem-te-infobox-top thegem-te-infobox-top-none default-fill"></div>
                                          <div className="thegem-te-infobox-inner">
                                            <div className="thegem-te-infobox-content">
                                              <div className="gem-texbox-text-wrapper">
                                                <div className="thegem-te-infobox-title text-body-tiny light">
                                                  <a href="https://www.newry.co.in/blog-listing">
                                                    Blogs
                                                  </a>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <div
                                      className="elementor-element elementor-element-a936bf0 elementor-hidden-mobile elementor-shape-circle elementor-grid-0 e-grid-align-center flex-horizontal-align-default flex-horizontal-align-tablet-default flex-horizontal-align-mobile-default flex-vertical-align-default flex-vertical-align-tablet-default flex-vertical-align-mobile-default elementor-widget elementor-widget-social-icons"
                                      data-id="a936bf0"
                                      data-element_type="widget"
                                      data-widget_type="social-icons.default"
                                    >
                                      <div className="elementor-widget-container">
                                        <div className="elementor-social-icons-wrapper elementor-grid">
                                          <a
                                            className="mkd-social-icons-group-widget-holder mkd-icon-has-hover facebook"
                                            href="https://www.facebook.com/NewryProperties"
                                            target="_blank"
                                          >
                                            <img
                                              src="assets/facebook-white.png"
                                              style={{ width: 28 }}
                                            />
                                          </a>
                                          <a
                                            className="mkd-social-icons-group-widget-holder mkd-icon-has-hover instagram"
                                            href="https://www.instagram.com/newrypropertiesprivatelimited/"
                                            target="_blank"
                                          >
                                            <img
                                              src="assets/instagram-white.png"
                                              style={{ width: 28 }}
                                            />
                                          </a>
                                          <a
                                            className="mkd-social-icons-group-widget-holder mkd-icon-has-hover twitter"
                                            href="https://x.com/newryproperties"
                                            target="_blank"
                                          >
                                            <img
                                              src="assets/twitter-white.png"
                                              style={{ width: 26 }}
                                            />
                                          </a>
                                          <a
                                            className="mkd-social-icons-group-widget-holder mkd-icon-has-hover youtube"
                                            href="https://www.youtube.com/channel/UColnNNXqwwWIPzoWVFGOKkQ"
                                            target="_blank"
                                          >
                                            <img
                                              src="assets/youtube-white.png"
                                              style={{ width: 28 }}
                                            />
                                          </a>
                                          <a
                                            className="mkd-social-icons-group-widget-holder mkd-icon-has-hover linkedin"
                                            href="https://in.linkedin.com/company/newrypropertiesprivatelimited"
                                            target="_blank"
                                          >
                                            <img
                                              src="assets/linkedin-white.png"
                                              style={{ width: 28 }}
                                            />
                                          </a>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </section>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </header>
        <div className="custom-menu-container">
          <div className="custom-menu">
            <ul>
              <li>
                <a href="https://www.newry.co.in/" className="logo" aria-label="logo">
                  <img
                    data-tgpli-src="assets/logo3.png"
                    src="assets/logo3.png"
                    style={{ maxWidth: 250 }}
                    alt="logo"
                  />
                </a>
              </li>
              <li>
                <a href="https://www.newry.co.in/" className="logo" aria-label="logo">
                  <img
                    data-tgpli-src="assets/home-logo.png"
                    src="assets/home-logo.png"
                    style={{ maxWidth: 40 }}
                    alt="logo"
                  />
                </a>
              </li>
              <li>
                <a href="https://www.newry.co.in/about">
                  About Us
                </a>
                <ul className="about-menu">
                  <li>
                    <a href="https://www.newry.co.in/about">
                      Our Story
                    </a>
                  </li>
                  <li>
                    <a href="https://www.newry.co.in/founders">
                      Our Leadership
                    </a>
                  </li>
                  <li>
                    <a href="https://www.newry.co.in/awards">
                      Awards and Citations
                    </a>
                  </li>
                  <li>
                    <a href="https://www.newry.co.in/csr">
                      Social Initiatives
                    </a>
                  </li>
                </ul>
              </li>
              <li className="hide-mobile">
                {" "}
                <a>Residential</a>
                <ul className="about-menu">
                  <li className="ongoing">
                    {" "}
                    <a href="https://www.newry.co.in/ongoing">
                      Ongoing Projects
                    </a>
                    <ul className="child-menu">
                      <li>
                        {" "}
                        <a href="https://www.newry.co.in/astor">
                          Newry Astor
                        </a>
                      </li>
                      <li>
                        <a href="https://www.newry.co.in/adora">
                          Newry Adora
                        </a>
                      </li>
                      <li>
                        <a href="https://www.newry.co.in/clover-county">
                          Newry Clover County
                        </a>
                      </li>
                      {/* <li>
                        <a href="https://www.newry.co.in/arista">
                          Newry Arista
                        </a>
                      </li> */}
                    </ul>
                  </li>
                  <li>
                    <a href="https://www.newry.co.in/completed">
                      Completed Projects
                    </a>
                  </li>
                  <li>
                    <a href="https://www.newry.co.in/upcoming">
                      Upcoming Projects
                    </a>
                  </li>
                </ul>
              </li>
              <li className="hide-mobile">
                <a href="https://www.newry.co.in/commercial">
                  Commercial
                </a>
              </li>
              <li>
                <a href="https://www.newry.co.in/customer-speaks">
                  Customer Speak
                </a>
              </li>
              <li>
                <a href="https://www.newry.co.in/services">
                  Services
                </a>
              </li>
            </ul>
          </div>
        </div>
      </section>
      <nav className="mobile">
        <div className="nav-brand">
          <a href="https://www.newry.co.in/">
            <img
              src="assets/logo3.png"
              style={{ maxHeight: 40 }}
            />
          </a>
        </div>
        <label htmlFor="drop" className="toggle top">
          <i className="fa fa-bars fa-fw" />
        </label>
        <input type="checkbox" id="drop" />
        <ul className="menu">
          <li>
            <a href="https://www.newry.co.in/">
              Home
            </a>
          </li>
          <li>
            <label htmlFor="drop-1" className="toggle">
              About Us <i className="fa fa-angle-down" aria-hidden="true" />
            </label>
            <a href="https://www.newry.co.in/about1">
              About Us
            </a>
            <input type="checkbox" id="drop-1" />
            <ul>
              <li>
                <a href="https://www.newry.co.in/about1">
                  Our Story
                </a>
              </li>
              <li>
                <a href="https://www.newry.co.in/founders">
                  Our Leadership
                </a>
              </li>
              <li>
                <a href="https://www.newry.co.in/awards">
                  Awards and Citations
                </a>
              </li>
              <li>
                <a href="https://www.newry.co.in/csr">
                  Social Initiatives
                </a>
              </li>
            </ul>
          </li>
          <li>
            <label htmlFor="drop-2" className="toggle">
              Residential <i className="fa fa-angle-down" aria-hidden="true" />
            </label>
            <a href="https://www.newry.co.in/ongoing">
              Residential
            </a>
            <input type="checkbox" id="drop-2" />
            <ul>
              <li>
                <label htmlFor="drop-3" className="toggle">
                  Ongoing Projects{" "}
                  <i className="fa fa-angle-down" aria-hidden="true" />
                </label>
                <a href="https://www.newry.co.in/ongoing">
                  Ongoing Projects
                </a>
                <input type="checkbox" id="drop-3" />
                <ul>
                  <li>
                    <a href="https://www.newry.co.in/astor">
                      Newry Astor
                    </a>
                  </li>
                  <li>
                    <a href="https://www.newry.co.in/adora">
                      Newry Adora
                    </a>
                  </li>
                  <li>
                    <a href="https://www.newry.co.in/clover-county">
                      Newry Clover County
                    </a>
                  </li>
                  {/* <li>
                    <a href="https://www.newry.co.in/arista">
                      Newry Arista
                    </a>
                  </li> */}
                </ul>
              </li>
              <li>
                <a href="https://www.newry.co.in/completed">
                  Completed Projects
                </a>
              </li>
              <li>
                <a href="https://www.newry.co.in/upcoming">
                  Upcoming Projects
                </a>
              </li>
            </ul>
          </li>
          <li>
            <a href="https://www.newry.co.in/commercial">
              Commercial
            </a>
          </li>
          <li>
            <a href="https://www.newry.co.in/customer-speaks">
              Customer Speak
            </a>
          </li>
          <li>
            <a href="https://www.newry.co.in/services">
              Services
            </a>
          </li>
          <li>
            <a href="https://www.newry.co.in/careers">
              Careers
            </a>
          </li>
          <li>
            <a href="https://www.newry.co.in/contact">
              Contact
            </a>
          </li>
          <li>
            <a href="https://www.newry.co.in/blog-listing">
              Blogs
            </a>
          </li>
        </ul>
      </nav>
      <Outlet />
    </>
  )
};

export default Header;