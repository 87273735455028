const Blog1 = () =>{
    return <>
    <link rel="stylesheet" href="css/BlogDetails.module.css" />
        {/*BLOG START*/}
<div className="mkd-content">
  <div className="mkd-content-inner"> 
    {/*SLIDER START*/}
    <div className="mkd-ps-image-holder">
      <div className="mkd-ps-image mkd-owl-slider">
        <div className="mkd-ps-image">
          <picture>
            <source media="(min-width: 481px)" srcSet="assets/arista1.webp" />
            <source media="(max-width: 480px)" srcSet="assets/arista1-mobile.webp" />
            <img className="d-block w-100" src="assets/arista1-mobile.webp" alt="Banner" />
          </picture> 
        </div>  
      </div>
    </div>
    {/*SLIDER END*/}
    <div className="vc_empty_space" style={{height: '60px'}}>
      <span className="vc_empty_space_inner" />
    </div>
    <div className="mkd-container">
      <div className="mkd-container-inner clearfix">
        <div className="mkd-grid-row mkd-content-has-sidebar mkd-grid-medium-gutter">
          <div className="mkd-page-content-holder mkd-grid-col-8">
            <div className="mkd-blog-holder mkd-blog-single mkd-blog-single-standard">
              <article id="post-176" className="post-176 post type-post status-publish format-standard has-post-thumbnail hentry category-uncategorized tag-design">
                <div className="mkd-post-content"> 
                  <div className="mkd-post-text">
                    <div className="mkd-post-text-inner">
                      <div className="mkd-post-info-top">
                        <div itemProp="dateCreated" className="mkd-post-info-date entry-date published updated">
                          <a itemProp="url" href="#">
                            November 26, 2022</a>
                          <meta itemProp="interactionCount" content="UserComments: 2" />
                        </div> 
                      </div>
                      <div className="mkd-post-text-main">
                        <h3 itemProp="name" className="entry-title mkd-post-title">
                        Find The Home of Your Dreams and The Lifestyle You Deserve</h3>
                        <div className="vc_empty_space" style={{height: '20px'}}>
                          <span className="vc_empty_space_inner" />
                        </div>
                        <section className="wpb-content-wrapper">
                          <div className="vc_row wpb_row vc_row-fluid">
                            <div className="wpb_column vc_column_container vc_col-sm-12">
                              <div className="vc_column-inner">
                                <div className="wpb_wrapper">
                                  <div className="vc_empty_space" style={{height: '4px'}}><span className="vc_empty_space_inner" />
                                  </div>
                                  <div className="wpb_text_column wpb_content_element ">
                                    <div className="wpb_wrapper">
                                      <p>Chennai is the hub of mesmerising properties. It’s a treat to live in this vibrant town and experiencing the thrill of city life. Having a luxurious abode here is the closest you’ll get to heaven on earth. It makes the gateway to a sophisticated lifestyle filled with serenity and elegance.</p>
                                    </div>
                                  </div>
                                  <div className="vc_empty_space" style={{height: '30px'}}><span className="vc_empty_space_inner" />
                                  </div>
                                  <h4 itemProp="name" className="mkd-pli-title entry-title">Your Hub of Luxury: Newry Arista</h4>
                                  <div className="wpb_text_column wpb_content_element ">
                                    <div className="wpb_wrapper">
                                      <p>Combining comfort with stature, Newry properties have introduced the spectacular Newry Arista. The contemporary residential complex sits in Kolathur, Chennai, promising a luxurious atmosphere. Arista is home to grandeur, and offers a variety of inclusive activities to keep glorifying your life.</p>
                                    </div>
                                  </div>
                                  <div className="vc_empty_space" style={{height: '30px'}}><span className="vc_empty_space_inner" />
                                  </div>
                                  <h4 itemProp="name" className="mkd-pli-title entry-title">Smart layouts</h4>
                                  <div className="wpb_text_column wpb_content_element ">
                                    <div className="wpb_wrapper">
                                      <p>Newry Arista includes all-inclusive layouts to match your requirements. The apartments are spaced out and systematically built to allow adequate natural sunlight and wind into your home. Flats range from 928 sq.ft to 1070 sq.ft. The cut outs include spacious 2 BHK and 2.5 BHK apartments, making it an ideal home for your family.</p>
                                    </div>
                                  </div>
                                  <div className="vc_empty_space" style={{height: '30px'}}><span className="vc_empty_space_inner" />
                                  </div>
                                  <h4 itemProp="name" className="mkd-pli-title entry-title">Strategically located                                  </h4>
                                  <div className="wpb_text_column wpb_content_element ">
                                    <div className="wpb_wrapper">
                                      <p>Situated at the heart of Kolathur, Arista is a stone’s throw from the best entertainment and shopping centre. Whether you need a hot cup of coffee or some shopping therapy, you’ll be a short trip away.</p> 
                                    </div>
                                  </div>
                                  <div className="vc_empty_space" style={{height: '30px'}}><span className="vc_empty_space_inner" />
                                  </div>
                                  <h4 itemProp="name" className="mkd-pli-title entry-title">Attractive amenities                                  </h4>
                                  <div className="wpb_text_column wpb_content_element ">
                                    <div className="wpb_wrapper">
                                      <p>
                                      Modern amenities meet extravagant lifestyles at Newry Arista. The property hosts a calming and serene environment, but isn’t out of touch with the modern facilities. It offers an equipped gym, sufficient car parks, a strong security system lined with CCTVs, power backups and an adaptive rainwater harvesting system.</p> 
                                    </div>
                                  </div>

                                  <div className="vc_empty_space" style={{height: '30px'}}><span className="vc_empty_space_inner" />
                                  </div>
                                  <h4 itemProp="name" className="mkd-pli-title entry-title">Your dream home, made affordable                                  </h4>
                                  <div className="wpb_text_column wpb_content_element ">
                                    <div className="wpb_wrapper">
                                      <p>Considering the countless amenities, inclusive facilities, the beautiful location and elegant environment, you’d expect a hefty price tag.</p>
                                      <p>But Newry Arista starts at an affordable ₹81 L* Onwards. This exemplary property is the step toward your dream lifestyle. Arista lets you in a world of serenity blended perfectly with dignity.</p> 
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>  
                        </section>
                      </div> 
                    </div>
                  </div>
                </div>
              </article>   
            </div>
          </div>
          <div className="mkd-sidebar-holder mkd-grid-col-4">
            <aside className="mkd-sidebar">
              {/* <div id="search-2" className="widget widget_search">
                <form role="search" method="get" className="searchform" id="searchform-925" action>
                  <label className="screen-reader-text">Search for:</label>
                  <div className="input-holder clearfix">
                    <input value="" type="search" className="search-field" placeholder="Search" name="s" title="Search for:" />
                    <button type="submit" className="mkd-search-submit"><img src="https://entre.qodeinteractive.com/wp-content/themes/entre/assets/img/search_icon_dark.png" alt="search-icon" /></button>
                  </div>
                </form>
              </div> */}
              <div className="widget mkd-separator-widget">
                <div className="mkd-separator-holder clearfix  mkd-separator-center mkd-separator-normal">
                  <div className="mkd-separator" style={{borderStyle: 'solid', marginTop: '-11px'}} />
                </div>
              </div>
              <div className="widget mkd-blog-slider-widget">
                <div className="mkd-widget-title-holder">
                  <h4 className="mkd-widget-title">Recent posts</h4>
                </div>
                <div className="mkd-blog-slider-holder mkd-bs-slider">
                  <ul className="mkd-blog-slider mkd-owl-slider" data-number-of-items={1} data-enable-pagination="yes">
                    <li className="mkd-blog-slider-item">
                      <div className="mkd-blog-slider-item-inner">  
                        <div className="mkd-item-image">
                          <a itemProp="url" href="https://www.newry.co.in/blog-details">
                            <img width={757} height={418} src="assets/blog/blog1-thumb.jpg" className="attachment-full size-full wp-post-image" alt="a" loading="lazy" /> </a>
                        </div>
                        <div className="mkd-item-text-wrapper">
                          <div className="mkd-item-text-holder">
                            <div className="mkd-item-text-holder-inner">
                              <div className="mkd-item-info-section">
                              </div>
                              <h4 itemProp="name" className="entry-title mkd-post-title">
                                <a itemProp="url" href="https://www.newry.co.in/blog-details" title="Home decore">
                                  Why Should You Invest in Premium Villa Plots </a>
                              </h4>
                              <div itemProp="dateCreated" className="mkd-post-info-date entry-date published updated">
                                <a itemProp="url" href="https://www.newry.co.in/blog-details">
                                  November 26, 2022 </a>
                                <meta itemProp="interactionCount" content="UserComments: 2" />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </li>
                    <li className="mkd-blog-slider-item">
                      <div className="mkd-blog-slider-item-inner">
                        <div className="mkd-item-image">
                          <a itemProp="url" href="https://www.newry.co.in/blog1">
                            <img width={757} height={418} src="assets/blog/blog2-thumb.jpg" className="attachment-full size-full wp-post-image" alt="a" loading="lazy" /> </a>
                        </div>
                        <div className="mkd-item-text-wrapper">
                          <div className="mkd-item-text-holder">
                            <div className="mkd-item-text-holder-inner">
                              <div className="mkd-item-info-section">
                              </div>
                              <h4 itemProp="name" className="entry-title mkd-post-title">
                                <a itemProp="url" href="https://www.newry.co.in/blog1" title="Spring set up">
                                  Find The Home of Your Dreams and The Lifestyle You Deserve </a>
                              </h4>
                              <div itemProp="dateCreated" className="mkd-post-info-date entry-date published updated">
                                <a itemProp="url" href="https://www.newry.co.in/blog1">
                                  November 12, 2022</a>
                                <meta itemProp="interactionCount" content="UserComments: 2" />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </li>
                    <li className="mkd-blog-slider-item">
                      <div className="mkd-blog-slider-item-inner">
                        <div className="mkd-item-image">
                          <a itemProp="url" href="https://www.newry.co.in/blog2">
                            <img width={757} height={418} src="assets/blog/blog3-thumb.jpg" className="attachment-full size-full wp-post-image" alt="a" loading="lazy" /> </a>
                        </div>
                        <div className="mkd-item-text-wrapper">
                          <div className="mkd-item-text-holder">
                            <div className="mkd-item-text-holder-inner">
                              <div className="mkd-item-info-section">
                              </div>
                              <h4 itemProp="name" className="entry-title mkd-post-title">
                                <a itemProp="url" href="https://www.newry.co.in/blog2" title="Bed Rooms">
                                  What is Normal Plot Development &amp; Integrated Township?</a>
                              </h4>
                              <div itemProp="dateCreated" className="mkd-post-info-date entry-date published updated">
                                <a itemProp="url" href="https://www.newry.co.in/blog2">
                                  October 15, 2022 </a>
                                <meta itemProp="interactionCount" content="UserComments: 2" />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="widget mkd-separator-widget">
                <div className="mkd-separator-holder clearfix  mkd-separator-center mkd-separator-normal">
                  <div className="mkd-separator" style={{borderStyle: 'solid', marginTop: '-10px'}} />
                </div>
              </div>
              <div id="categories-2" className="widget widget_categories">
                <div className="mkd-widget-title-holder">
                  <h4 className="mkd-widget-title">Categories</h4>
                </div>
                <ul>
                  <li className="cat-item cat-item-85"><a href="#">Project</a>
                  </li>
                  <li className="cat-item cat-item-31"><a href="#">Financial</a>
                  </li>
                  <li className="cat-item cat-item-30"><a href="#">Real Estate</a>
                  </li>
                  <li className="cat-item cat-item-33"><a href="#">Home Guide</a>
                  </li>
                  <li className="cat-item cat-item-34"><a href="#">Locations</a>
                  </li> 
                  <li className="cat-item cat-item-34"> 
                  </li> 
                </ul>
              </div>
              <div className="widget mkd-separator-widget">
                <div className="mkd-separator-holder clearfix  mkd-separator-center mkd-separator-normal">
                  <div className="mkd-separator" style={{borderStyle: 'solid', marginTop: '-12px'}} />
                </div>
              </div> 
            </aside>
          </div>
        </div>
      </div>
    </div>
  </div></div>

    </>
};

export default Blog1;