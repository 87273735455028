const Media = () => {
    return <>
    <link rel="stylesheet" href="css/BuyersGuide.moudle.css" />
        <div className="mkd-content">
  {/*SLIDER START*/}
  <div className="mkd-ps-image-holder">
    <div className="mkd-ps-image mkd-owl-slider">
      <div className="mkd-ps-image">
        <picture>
          <source media="(min-width: 481px)" srcSet="assets/banner-white.jpg" />
          <source media="(max-width: 480px)" srcSet="assets/banner-white-mobile.jpg" />
          <img
            className="d-block w-100"
            src="assets/banner-white-mobile.jpg"
            alt="Banner"
          />
        </picture>
      </div>
    </div>
  </div>
  {/*SLIDER END*/}
  <div className="vc_empty_space" style={{ height: 20 }}>
    <span className="vc_empty_space_inner" />
  </div>
  <section className="wpb-content-wrapper location-advantages">
    <div className="mkd-row-grid-section-wrapper ">
      <div className="mkd-row-grid-section">
        <div className="vc_row wpb_row vc_row-fluid vc_custom_1516021087353">
          <div className="wpb_column vc_column_container vc_col-sm-12">
            <div className="vc_column-inner vc_custom_1516007647796">
              <div className="wpb_wrapper">
                <div className="mkd-elements-holder   mkd-one-column  mkd-responsive-mode-768 ">
                  <div
                    className="mkd-eh-item    "
                    data-item-class="mkd-eh-custom-8773"
                    data-1280-1600="0 0 20px 0"
                    data-1024-1280="0 0 20px 0"
                    data-768-1024="0 0 20px 0"
                    data-680-768="0 0 20px 0"
                    data-680="0px"
                  >
                    <div className="mkd-eh-item-inner">
                      <div
                        className="mkd-eh-item-content mkd-eh-custom-8773"
                        style={{ padding: "0 0 20px 0" }}
                      >
                        <div className="mkd-section-title-holder  ">
                          <div className="mkd-st-inner road">
                            <h2 className="mkd-st-title">Media</h2>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <br />
  <div className="mkd-full-width">
    <div className="mkd-full-width-inner">
      <div className="mkd-grid-row">
        <div className="mkd-page-content-holder mkd-grid-col-12">
          <section className="wpb-content-wrapper">
            <div className="mkd-row-grid-section-wrapper ">
              <div className="mkd-row-grid-section">
                <div className="vc_row wpb_row vc_row-fluid vc_custom_1517922302191">
                  <div className="wpb_column vc_column_container vc_col-sm-12">
                    <div className="vc_column-inner">
                      <div className="wpb_wrapper">
                        <div
                          className="mkd-portfolio-list-holder mkd-pl-gallery mkd-normal-space mkd-pl-two-columns mkd-pl-gallery-slide-from-left-midway    mkd-pl-pag-load-more     "
                          data-type="gallery"
                          data-number-of-columns={2}
                          data-space-between-items="normal"
                          data-number-of-items={4}
                          data-image-proportions="full"
                          data-enable-fixed-proportions="no"
                          data-enable-image-shadow="no"
                          data-category="architecture-project"
                          data-orderby="date"
                          data-order="ASC"
                          data-item-style="gallery-slide-from-left-midway"
                          data-enable-title="yes"
                          data-title-tag="h4"
                          data-enable-category="yes"
                          data-category-tag="p"
                          data-enable-count-images="yes"
                          data-enable-excerpt="no"
                          data-excerpt-length={20}
                          data-pagination-type="load-more"
                          data-load-more-top-margin="57px"
                          data-filter="no"
                          data-filter-order-by="name"
                          data-enable-article-animation="no"
                          data-portfolio-slider-on="no"
                          data-enable-loop="yes"
                          data-enable-autoplay="yes"
                          data-slider-speed={5000}
                          data-slider-speed-animation={600}
                          data-enable-navigation="yes"
                          data-enable-pagination="yes"
                          data-max-num-pages={3}
                          data-next-page={2}
                        >
                          <div className="mkd-pl-inner mkd-outer-space  clearfix">
                            <article className="mkd-pl-item mkd-item-space  post-1536 portfolio-item type-portfolio-item status-publish has-post-thumbnail hentry portfolio-category-architecture-project">
                              <div className="mkd-pl-item-inner">
                                <div className="mkd-pli-image">
                                  <img src="assets/media/2.jpg" />
                                </div>
                              </div>
                            </article>
                            <article className="mkd-pl-item mkd-item-space  post-1536 portfolio-item type-portfolio-item status-publish has-post-thumbnail hentry portfolio-category-architecture-project">
                              <div className="mkd-pl-item-inner">
                                <div className="mkd-pli-image">
                                  <img src="assets/media/3.jpg" />
                                </div>
                              </div>
                            </article>
                            <article className="mkd-pl-item mkd-item-space  post-1536 portfolio-item type-portfolio-item status-publish has-post-thumbnail hentry portfolio-category-architecture-project">
                              <div className="mkd-pl-item-inner">
                                <div className="mkd-pli-image">
                                  <img src="assets/media/1.jpg" />
                                </div>
                              </div>
                            </article>
                            <article className="mkd-pl-item mkd-item-space  post-1536 portfolio-item type-portfolio-item status-publish has-post-thumbnail hentry portfolio-category-architecture-project">
                              <div className="mkd-pl-item-inner">
                                <div className="mkd-pli-image">
                                  <img src="assets/media/4.jpg" />
                                </div>
                              </div>
                            </article>
                          </div>
                          <div className="mkd-pl-loading">Loading...</div>
                          <div className="mkd-pl-load-more-holder">
                            <div
                              className="mkd-pl-load-more"
                              style={{ marginTop: 57 }}
                            >
                              <a
                                itemProp="url"
                                href=""
                                target="_self"
                                className="mkd-btn mkd-btn-medium mkd-btn-outline"
                              >
                                <span className="mkd-btn-text">load more</span>{" "}
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    </div>
  </div>
</div>

    </>;
}

export default Media;