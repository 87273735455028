import React, { useRef, useEffect } from 'react';

const ProjectAmenity = ({ id, defaultSrc, hoverSrc, description, iconStyle, boxStyle }) => {
  const iconRef = useRef(null);

  useEffect(() => {
    const handleMouseOver = () => {
      iconRef.current.setAttribute('data', hoverSrc);
    };

    const handleMouseOut = () => {
      iconRef.current.setAttribute('data', defaultSrc);
    };

    const iconElement = iconRef.current;
    if (iconElement) {
      iconElement.addEventListener('mouseover', handleMouseOver);
      iconElement.addEventListener('mouseout', handleMouseOut);
    }

    // Cleanup function to remove event listeners
    return () => {
      if (iconElement) {
        iconElement.removeEventListener('mouseover', handleMouseOver);
        iconElement.removeEventListener('mouseout', handleMouseOut);
      }
    };
  }, [hoverSrc, defaultSrc]);

  return (
    <div className="elementor-icon-box-wrapper" style={boxStyle}>
      <div className="elementor-icon-box-svg">
        <object
          ref={iconRef}
          id={id}
          data={defaultSrc}
          type="image/svg+xml"
          style={iconStyle}
        />
        <object
          data={hoverSrc}
          type="image/svg+xml"
          style={{ display: 'none' }}
        />
      </div>
      <div className="elementor-icon-box-content">
        <h3 className="elementor-icon-box-title">
          <span />
        </h3>
        <p className="elementor-icon-box-description">
          {description}
        </p>
      </div>
    </div>
  );
};

export default ProjectAmenity;