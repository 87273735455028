import React, { useRef, useEffect } from 'react';

const AboutIcons = ({ id, defaultSrc, hoverSrc, title, listItems }) => {
  const iconRef = useRef(null);

  useEffect(() => {
    const handleMouseOver = () => {
      iconRef.current.setAttribute('data', hoverSrc);
    };

    const handleMouseOut = () => {
      iconRef.current.setAttribute('data', defaultSrc);
    };

    const iconElement = iconRef.current;
    if (iconElement) {
      iconElement.addEventListener('mouseover', handleMouseOver);
      iconElement.addEventListener('mouseout', handleMouseOut);
    }

    // Cleanup function to remove event listeners
    return () => {
      if (iconElement) {
        iconElement.removeEventListener('mouseover', handleMouseOver);
        iconElement.removeEventListener('mouseout', handleMouseOut);
      }
    };
  }, [hoverSrc, defaultSrc]);

  return (
    <div className="mkd-iwt-content">
      <h3 className="mkd-iwt-title" style={{ color: '#464646', marginTop: '4px', cursor: 'pointer' }}>
        <object
          ref={iconRef}
          id={id}
          data={defaultSrc}
          type="image/svg+xml"
          style={{ maxWidth: '100px', maxHeight: '100px' }}
        />
        <object
          data={hoverSrc}
          type="image/svg+xml"
          style={{ display: 'none' }}
        />
      </h3>
      <ul>
        {listItems.map((item, index) => (
          <li key={index}>{item}</li>
        ))}
      </ul>
    </div>
  );
};

export default AboutIcons;