const BuyersGuide = () => {
    return <>
    <link rel="stylesheet" href="css/BuyersGuide.moudle.css" />
        <div className="mkd-content">
            {/*SLIDER START*/}
            <div className="mkd-ps-image-holder">
                <div className="mkd-ps-image mkd-owl-slider">
                    <div className="mkd-ps-image">
                        <picture>
                            <source media="(min-width: 481px)" srcSet="assets/banner-white.jpg" />
                            <source media="(max-width: 480px)" srcSet="assets/banner-white-mobile.jpg" />
                            <img
                                className="d-block w-100"
                                src="assets/banner-white-mobile.jpg"
                                alt="Banner"
                            />
                        </picture>
                    </div>
                </div>
            </div>
            {/*SLIDER END*/}
            <div className="vc_empty_space" style={{ height: 20 }}>
                <span className="vc_empty_space_inner" />
            </div>
            <section className="wpb-content-wrapper location-advantages">
                <div className="mkd-row-grid-section-wrapper ">
                    <div className="mkd-row-grid-section">
                        <div className="vc_row wpb_row vc_row-fluid vc_custom_1516021087353">
                            <div className="wpb_column vc_column_container vc_col-sm-12">
                                <div className="vc_column-inner vc_custom_1516007647796">
                                    <div className="wpb_wrapper">
                                        <div className="mkd-elements-holder   mkd-one-column  mkd-responsive-mode-768 ">
                                            <div
                                                className="mkd-eh-item    "
                                                data-item-class="mkd-eh-custom-8773"
                                                data-1280-1600="0 0 20px 0"
                                                data-1024-1280="0 0 20px 0"
                                                data-768-1024="0 0 20px 0"
                                                data-680-768="0 0 20px 0"
                                                data-680="0px"
                                            >
                                                <div className="mkd-eh-item-inner">
                                                    <div
                                                        className="mkd-eh-item-content mkd-eh-custom-8773"
                                                        style={{ padding: "0 0 20px 0" }}
                                                    >
                                                        <div className="mkd-section-title-holder  ">
                                                            <div className="mkd-st-inner road">
                                                                <h2 className="mkd-st-title">Buyer's Guide</h2>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <div className="mkd-content-inner">
                <div className="mkd-full-width">
                    <div className="mkd-full-width-inner">
                        <div className="mkd-grid-row">
                            <div className="mkd-page-content-holder mkd-grid-col-12">
                                <div className="mkd-row-grid-section-wrapper ">
                                    <div className="mkd-row-grid-section">
                                        <div className="vc_row wpb_row vc_row-fluid vc_custom_1516877211553 vc_column-gap-35">
                                            <div className="wpb_column vc_column_container vc_col-sm-12">
                                                <div className="vc_column-inner">
                                                    <div className="wpb_wrapper">
                                                        <div className="wpb_text_column wpb_content_element ">
                                                            <div className="wpb_wrapper">
                                                                <h3 style={{ color: "#2c498a" }}>
                                                                    Do’s &amp; Don'ts of buying your dream home
                                                                </h3>
                                                            </div>
                                                        </div>
                                                        <br />
                                                        <div className="vc_row wpb_row vc_inner vc_row-fluid">
                                                            <div className="wpb_column vc_column_container vc_col-sm-12">
                                                                <div className="vc_column-inner">
                                                                    <div className="wpb_wrapper">
                                                                        <div className="wpb_text_column wpb_content_element ">
                                                                            <div className="wpb_wrapper">
                                                                                <h4>Do’s</h4>
                                                                                <p>
                                                                                    {" "}
                                                                                    <strong>
                                                                                        {" "}
                                                                                        1. Choose RERA Approved Properties for a
                                                                                        Secured Process:
                                                                                    </strong>
                                                                                </p>
                                                                                <p>
                                                                                    Prioritize your peace of mind and
                                                                                    safeguard your investment by selecting
                                                                                    properties approved under the Real Estate
                                                                                    Regulatory Act 2016. This crucial step
                                                                                    ensures not only the legality of your
                                                                                    purchase but also guarantees a transparent
                                                                                    and fair transaction, protecting your
                                                                                    rights as a homeowner.{" "}
                                                                                </p>
                                                                                <br />
                                                                                <p>
                                                                                    <strong>
                                                                                        2. Know the Developers' Track Records
                                                                                        for Reliability:
                                                                                    </strong>
                                                                                </p>
                                                                                <p>
                                                                                    Scrutinize your search for the perfect
                                                                                    home by conducting thorough investigations
                                                                                    into the track records of developers. By
                                                                                    prioritizing reliability, you ensure the
                                                                                    timely completion and delivery of your
                                                                                    dream home, mitigating the risks
                                                                                    associated with project delays or
                                                                                    discrepancies.{" "}
                                                                                </p>
                                                                                <br />
                                                                                <p>
                                                                                    <strong>
                                                                                        3. Strategically Maximize Your Down
                                                                                        Payment:
                                                                                    </strong>
                                                                                </p>
                                                                                <p>
                                                                                    Strengthen your negotiating power and
                                                                                    secure favorable terms by optimizing your
                                                                                    down payment strategy. By maximizing your
                                                                                    initial investment, you minimize your home
                                                                                    loan amount, thereby reducing long-term
                                                                                    interest payments. Consider innovative
                                                                                    options like interest-free capital through
                                                                                    HomeCapital to align your financial
                                                                                    strategy with your homeownership
                                                                                    aspirations.{" "}
                                                                                </p>
                                                                                <br />
                                                                                <p>
                                                                                    <strong>
                                                                                        4. Conduct a Comprehensive Property
                                                                                        Price Analysis:
                                                                                    </strong>
                                                                                </p>
                                                                                <p>
                                                                                    Empower yourself with comprehensive
                                                                                    knowledge by conducting a detailed
                                                                                    comparison of property prices in your
                                                                                    desired area. Evaluate various parameters
                                                                                    to make an informed decision and ensure
                                                                                    that you secure optimal value for your
                                                                                    hard-earned investment.{" "}
                                                                                </p>
                                                                                <br />
                                                                                <p>
                                                                                    <strong>
                                                                                        5. Explore Financial Benefits Through
                                                                                        Taxation Strategies:
                                                                                    </strong>
                                                                                </p>
                                                                                <p>
                                                                                    Harness the potential savings offered by
                                                                                    government schemes such as section 80(c),
                                                                                    80 EE, and 24(b) to your advantage.
                                                                                    Strategically leverage these tax benefits
                                                                                    to maximize your savings on home loan
                                                                                    interest payments and streamline your
                                                                                    overall financial planning for enhanced
                                                                                    long-term stability.{" "}
                                                                                </p>
                                                                                <br />
                                                                                <p>
                                                                                    <strong>
                                                                                        6. Explore Tailored Government Subsidies
                                                                                        for First-Time Buyers:
                                                                                    </strong>
                                                                                </p>
                                                                                <p>
                                                                                    Dive into available government subsidies
                                                                                    tailored specifically for first-time
                                                                                    homebuyers. These subsidies serve as
                                                                                    invaluable resources, significantly
                                                                                    alleviating the financial burden
                                                                                    associated with purchasing your dream home
                                                                                    and making homeownership more accessible
                                                                                    and achievable for you.{" "}
                                                                                </p>
                                                                                <br />
                                                                                <br />
                                                                            </div>
                                                                            <div className="wpb_wrapper">
                                                                                <h4>Don’ts </h4>
                                                                                <p>
                                                                                    {" "}
                                                                                    <strong>
                                                                                        {" "}
                                                                                        1. Exercise Patience with Low Credit
                                                                                        Scores:{" "}
                                                                                    </strong>
                                                                                </p>
                                                                                <p>
                                                                                    {" "}
                                                                                    Resist the urge to rush into purchases if
                                                                                    your credit score is below 750. Exercise
                                                                                    patience and prudence, as a higher credit
                                                                                    score not only enhances your eligibility
                                                                                    for financing but also ensures more
                                                                                    favorable terms from financial
                                                                                    institutions, safeguarding your financial
                                                                                    well-being in the long run.
                                                                                </p>
                                                                                <br />
                                                                                <p>
                                                                                    {" "}
                                                                                    <strong>
                                                                                        {" "}
                                                                                        2. Prioritize Proximity to Essential
                                                                                        Infrastructure for Convenience:{" "}
                                                                                    </strong>
                                                                                </p>
                                                                                <p>
                                                                                    Avoid properties situated far from
                                                                                    essential amenities and infrastructure.
                                                                                    Prioritize convenient access to schools,
                                                                                    workplaces, and medical facilities to
                                                                                    enhance your quality of life while
                                                                                    minimizing daily travel expenses and time
                                                                                    commitments, ensuring a seamless
                                                                                    transition into homeownership.
                                                                                </p>
                                                                                <br />
                                                                                <p>
                                                                                    {" "}
                                                                                    <strong>
                                                                                        {" "}
                                                                                        3. Establish a Realistic Budget Before
                                                                                        House Hunting:{" "}
                                                                                    </strong>
                                                                                </p>
                                                                                <p>
                                                                                    {" "}
                                                                                    Embrace proactive financial planning by
                                                                                    establishing a realistic budget before
                                                                                    embarking on your house-hunting journey.
                                                                                    Avoid overextending yourself financially
                                                                                    by exploring specialized homebuyer
                                                                                    programs tailored to support your unique
                                                                                    needs and circumstances, ensuring a smooth
                                                                                    and fulfilling homeownership experience.{" "}
                                                                                </p>
                                                                                <br />
                                                                                <br />
                                                                                <p>
                                                                                    Move ahead in your journey to
                                                                                    homeownership with confidence, armed with
                                                                                    exclusive insights and expert guidance
                                                                                    from Newry Properties. We are dedicated to
                                                                                    supporting you every step of the way,
                                                                                    turning your homeownership dreams into a
                                                                                    vibrant reality tailored just for you.{" "}
                                                                                </p>
                                                                            </div>
                                                                        </div>
                                                                        <div
                                                                            className="vc_empty_space"
                                                                            style={{ height: 32 }}
                                                                        >
                                                                            <span className="vc_empty_space_inner" />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </>;
}

export default BuyersGuide;