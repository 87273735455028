import React, { useRef, useEffect } from 'react';

const DynamicHoverIconComponent = ({ id, defaultSrc, hoverSrc, iconStyle }) => {
  const iconRef = useRef(null);

  useEffect(() => {
    const handleMouseOver = () => {
      iconRef.current.setAttribute('data', hoverSrc);
    };

    const handleMouseOut = () => {
      iconRef.current.setAttribute('data', defaultSrc);
    };

    const iconElement = iconRef.current;
    if (iconElement) {
      iconElement.addEventListener('mouseover', handleMouseOver);
      iconElement.addEventListener('mouseout', handleMouseOut);
    }

    // Cleanup function to remove event listeners
    return () => {
      if (iconElement) {
        iconElement.removeEventListener('mouseover', handleMouseOver);
        iconElement.removeEventListener('mouseout', handleMouseOut);
      }
    };
  }, [hoverSrc, defaultSrc]);

  return (
    <h3 className="mkd-iwt-title" style={{ marginTop: 4, cursor: 'pointer' }}>
      <object
        ref={iconRef}
        id={id}
        data={defaultSrc}
        type="image/svg+xml"
        style={iconStyle}
      >
      </object>
      <object
        data={hoverSrc}
        type="image/svg+xml"
        style={{ display: 'none' }}
      >
      </object>
    </h3>
  );
};

export default DynamicHoverIconComponent;