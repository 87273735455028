import React, { useState } from "react";
const Upcoming = () => {
    const [imageSrc, setImageSrc] = useState("assets/upcoming/upcoming.jpg");

    const loadImage = (newSrc) => {
        setImageSrc(newSrc);
    };

    return <>
  <link rel="stylesheet" href="css/Upcoming.module.css" />
        <div className="mkd-content">
            {/*SLIDER START*/}
            <div className="mkd-ps-image-holder">
                <div className="mkd-ps-image mkd-owl-slider">
                    <div className="mkd-ps-image">
                        <picture>
                            <source media="(min-width: 481px)" srcSet="assets/upcoming/banner-new.jpg" />
                            <source
                                media="(max-width: 480px)"
                                srcSet="assets/upcoming/banner-mobile.jpg"
                            />
                            <img
                                className="d-block w-100"
                                src="assets/upcoming/banner-mobile.jpg"
                                alt="Banner"
                            />
                        </picture> 
                    </div>
                </div>
            </div>
            {/*SLIDER END*/}
            <div className="vc_empty_space" style={{ height: 40 }}>
                <span className="vc_empty_space_inner" />
            </div>
            {/*COMPLETED START*/}
            <div className="mkd-content">
                <div className="mkd-content-inner">
                    <section className="wpb-content-wrapper location-advantages">
                        <div className="mkd-row-grid-section-wrapper ">
                            <div className="mkd-row-grid-section">
                                <div className="vc_row wpb_row vc_row-fluid vc_custom_1516021087353">
                                    <div className="wpb_column vc_column_container vc_col-sm-12">
                                        <div className="vc_column-inner vc_custom_1516007647796">
                                            <div className="wpb_wrapper">
                                                <div className="mkd-elements-holder   mkd-one-column  mkd-responsive-mode-768 ">
                                                    <div
                                                        className="mkd-eh-item    "
                                                        data-item-class="mkd-eh-custom-8773"
                                                        data-1280-1600="0 0 20px 0"
                                                        data-1024-1280="0 0 20px 0"
                                                        data-768-1024="0 0 20px 0"
                                                        data-680-768="0 0 20px 0"
                                                        data-680="0px"
                                                    >
                                                        <div className="mkd-eh-item-inner">
                                                            <div
                                                                className="mkd-eh-item-content mkd-eh-custom-8773"
                                                                style={{ padding: "0 0 20px 0" }}
                                                            >
                                                                <div className="mkd-section-title-holder  ">
                                                                    <div className="mkd-st-inner road">
                                                                        <h2 className="mkd-st-title">
                                                                            Upcoming projects
                                                                        </h2>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    <br />
                    <div className="mkd-full-width">
                        <div className="mkd-full-width-inner">
                            <div className="mkd-grid-row">
                                <div className="mkd-page-content-holder mkd-grid-col-12">
                                    <section className="wpb-content-wrapper">
                                        <div className="mkd-row-grid-section-wrapper ">
                                            <div className="mkd-row-grid-section">
                                                <div className="vc_row wpb_row vc_row-fluid vc_custom_1517921876626">
                                                    <div className="wpb_column vc_column_container vc_col-sm-12">
                                                        <div className="vc_column-inner">
                                                            <div className="wpb_wrapper">
                                                                <div className="mkd-portfolio-list-holder mkd-pl-gallery mkd-normal-space mkd-pl-three-columns mkd-pl-standard-shader    mkd-pl-pag-no-pagination     ">
                                                                    <div className="mkd-pl-inner mkd-outer-space  clearfix">
                                                                        <div className="mkd-page-content-holder mkd-grid-col-12">
                                                                            <div className="mkd-row-grid-section-wrapper ">
                                                                                <div className="mkd-row-grid-section">
                                                                                    <div className="vc_row wpb_row vc_row-fluid vc_custom_1517909237365">
                                                                                        <div className="wpb_column vc_column_container vc_col-sm-12 vc_col-lg-6 vc_col-md-12 vc_col-xs-12">
                                                                                            <div className="vc_column-inner vc_custom_1516720842737">
                                                                                                <div className="wpb_wrapper">
                                                                                                    <div className="mkd-elements-holder   mkd-one-column  mkd-responsive-mode-768 ">
                                                                                                        <div
                                                                                                            className="mkd-eh-item    "
                                                                                                            data-item-class="mkd-eh-custom-2053"
                                                                                                            data-1280-1600="0 12px 0 25px"
                                                                                                            data-1024-1280="0 12px 0 0"
                                                                                                            data-768-1024="0 33px 0 0"
                                                                                                            data-680-768="0 33px 0 0"
                                                                                                            data-680="0 43px 0 10px"
                                                                                                        >
                                                                                                            <div className="mkd-eh-item-inner">
                                                                                                                <div
                                                                                                                    className="mkd-eh-item-content mkd-eh-custom-2053"
                                                                                                                    style={{
                                                                                                                        padding: "0 12px 0 25px"
                                                                                                                    }}
                                                                                                                >
                                                                                                                    <div className="mkd-single-image-holder   mkd-image-has-drop-shadow-effect  mkd-image-drop-shadow-left">
                                                                                                                        <div className="mkd-si-inner">
                                                                                                                            <img
                                                                                                                                width={800}
                                                                                                                                height={1166}
                                                                                                                                id="upcoming-img"
                                                                                                                                src={imageSrc}
                                                                                                                                className="attachment-full size-full"
                                                                                                                                alt="a"
                                                                                                                                loading="lazy"
                                                                                                                            />
                                                                                                                            <div
                                                                                                                                className="mkd-si-drop-shadow"
                                                                                                                                style={{
                                                                                                                                    backgroundColor:
                                                                                                                                        "#c3c7d0"
                                                                                                                                }}
                                                                                                                            ></div>
                                                                                                                        </div>
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                        <div className="wpb_column vc_column_container vc_col-sm-12 vc_col-lg-6 vc_col-md-12 vc_col-xs-12">
                                                                                            <div className="vc_column-inner vc_custom_1516720853580">
                                                                                                <div className="wpb_wrapper">
                                                                                                    <div className="mkd-elements-holder   mkd-one-column  mkd-responsive-mode-768 ">
                                                                                                        <div className="mkd-eh-item    ">
                                                                                                            <div className="mkd-eh-item-inner">
                                                                                                                <div className="mkd-eh-item-content mkd-eh-custom-7445">

                                                                                                                    <div className="mkd-progress-bar ">
                                                                                                                        <h4
                                                                                                                            className="mkd-pb-title-holder" 
                                                                                                                            onClick={() => loadImage("assets/upcoming/upcoming.jpg")}
                                                                                                                        >
                                                                                                                            <span className="mkd-pb-title">
                                                                                                                                NEWRY ALTURA
                                                                                                                            </span>
                                                                                                                        </h4>
                                                                                                                        <p>Paruthipattu</p>
                                                                                                                        <div className="mkd-pb-content-holder">
                                                                                                                            <div
                                                                                                                                data-percentage={30}
                                                                                                                                className="mkd-pb-content"
                                                                                                                            ></div>
                                                                                                                        </div>
                                                                                                                    </div>
                                                                                                                    <div
                                                                                                                        className="vc_empty_space"
                                                                                                                        style={{ height: 37 }}
                                                                                                                    >
                                                                                                                        <span className="vc_empty_space_inner" />
                                                                                                                    </div>
                                                                                                                    <div className="mkd-progress-bar ">
                                                                                                                        <h4
                                                                                                                            className="mkd-pb-title-holder"
                                                                                                                            onClick={() => loadImage("assets/upcoming/amethyst.jpg")}
                                                                                                                        >
                                                                                                                            <span className="mkd-pb-title">
                                                                                                                                NEWRY AMETHYST
                                                                                                                            </span>
                                                                                                                        </h4>
                                                                                                                        <p>Parivakkam</p>
                                                                                                                        <div className="mkd-pb-content-holder">
                                                                                                                            <div
                                                                                                                                data-percentage={25}
                                                                                                                                className="mkd-pb-content"
                                                                                                                            ></div>
                                                                                                                        </div>
                                                                                                                    </div>
                                                                                                                    <div
                                                                                                                        className="vc_empty_space"
                                                                                                                        style={{ height: 32 }}
                                                                                                                    >
                                                                                                                        <span className="vc_empty_space_inner" />
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <br />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </section>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/*COMPLETED END*/}
        </div>

    </>;
};

export default Upcoming;