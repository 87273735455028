const Ongoing = () => {
    return <>
        <link rel="stylesheet" href="css/Awards.module.css" />
        <div className="mkd-content">
  {/*SLIDER START*/}
  <div className="mkd-ps-image-holder">
    <div className="mkd-ps-image mkd-owl-slider">
      <div className="mkd-ps-image">
      <picture>
            <source media="(min-width: 481px)" srcSet="assets/banner/adora-banner.webp" />
            <source media="(max-width: 480px)" srcSet="assets/banner/adora-mobile.webp" />
            <img className="d-block w-100" src="assets/banner/adora-mobile.webp" alt="Banner" loading="lazy" />
        </picture>
      </div>
    </div>
  </div>
  {/*SLIDER END*/}
  <div className="vc_empty_space" style={{ height: 40 }}>
    <span className="vc_empty_space_inner" />
  </div>
  {/*COMPLETED START*/}
  <div className="mkd-content">
    <div className="mkd-content-inner">
      <section className="wpb-content-wrapper location-advantages">
        <div className="mkd-row-grid-section-wrapper ">
          <div className="mkd-row-grid-section">
            <div className="vc_row wpb_row vc_row-fluid vc_custom_1516021087353">
              <div className="wpb_column vc_column_container vc_col-sm-12">
                <div className="vc_column-inner vc_custom_1516007647796">
                  <div className="wpb_wrapper">
                    <div className="mkd-elements-holder   mkd-one-column  mkd-responsive-mode-768 ">
                      <div
                        className="mkd-eh-item    "
                        data-item-class="mkd-eh-custom-8773"
                        data-1280-1600="0 0 20px 0"
                        data-1024-1280="0 0 20px 0"
                        data-768-1024="0 0 20px 0"
                        data-680-768="0 0 20px 0"
                        data-680="0px"
                      >
                        <div className="mkd-eh-item-inner">
                          <div
                            className="mkd-eh-item-content mkd-eh-custom-8773"
                            style={{ padding: "0 0 20px 0" }}
                          >
                            <div className="mkd-section-title-holder  ">
                              <div className="mkd-st-inner road">
                                <h2 className="mkd-st-title">
                                  Ongoing projects
                                </h2>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <div className="vc_empty_space" style={{ height: 20 }}>
        <span className="vc_empty_space_inner" />
      </div>
      <div className="mkd-full-width">
        <div className="mkd-full-width-inner">
          <div className="blog1 vc_row wpb_row vc_row-fluid vc_column-gap-20 vc_row-o-equal-height vc_row-flex">
            <div className="wpb_column vc_column_container vc_col-sm-12 vc_col-lg-4 vc_col-md-12 vc_col-xs-12 vc_col-has-fill">
              <div className="vc_column-inner vc_custom_1515679036394">
                <div className="wpb_wrapper">
                  <div
                    className="mkd-animation-holder mkd-grow-in"
                    data-animation="mkd-grow-in"
                    data-animation-delay={500}
                  >
                    <div className="mkd-animation-inner">
                      <div className="mkd-elements-holder   mkd-one-column  mkd-responsive-mode-768 ">
                        <div className="mkd-eh-item">
                          <div className="mkd-eh-item-inner">
                            <div
                              className="mkd-eh-item-content mkd-eh-custom-8840"
                              style={{ padding: "10%" }}
                            >
                              <h4 style={{ color: "black" }}>
                                NEWRY ASTOR, AVADI
                              </h4>
                              <div
                                className="vc_empty_space"
                                style={{ height: 18 }}
                              >
                                <span className="vc_empty_space_inner" />
                              </div>
                              <p style={{ textAlign: "left" }}>
                                Introducing the Newry Astor - your gateway to
                                luxurious living! 9.27 Grounds with stilt and 5
                                floors.
                              </p>
                              <div
                                className="vc_empty_space"
                                style={{ height: 48 }}
                              >
                                <span className="vc_empty_space_inner" />
                              </div>
                              <a
                                itemProp="url"
                                href="https://www.newry.co.in/astor"
                                target="_self"
                                style={{ margin: "0 0 0 4px" }}
                                className="mkd-btn mkd-btn-medium mkd-btn-outline"
                              >
                                <span className="mkd-btn-text">Read more</span>
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="wpb_column vc_column_container vc_col-sm-12 vc_col-lg-8 vc_col-md-12 vc_col-xs-12 p-0">
              <div className="vc_column-inner vc_custom_1517388638346">
                <div className="wpb_wrapper">
                  <div className="mkd-elements-holder   mkd-one-column  mkd-responsive-mode-768 ">
                    <div
                      className="mkd-eh-item    "
                      data-item-class="mkd-eh-custom-8914"
                      data-1280-1600="0px"
                      data-1024-1280="0px"
                      data-768-1024="0%"
                      data-680-768="0%"
                      data-680="0%"
                    >
                      <div className="mkd-eh-item-inner">
                        <div
                          className="mkd-eh-item-content mkd-eh-custom-8914"
                          style={{ padding: 0 }}
                        >
                          <div className="mkd-single-image-holder">
                            <div className="mkd-si-inner">
                              <div className="mkd-ps-image-holder">
                                <div className="mkd-ps-image mkd-owl-slider">
                                  <div className="mkd-ps-image">
                                    <picture>
                                      <source
                                        media="(min-width: 481px)"
                                        srcSet="assets/banner1.webp"
                                      />
                                      <source
                                        media="(max-width: 480px)"
                                        srcSet="assets/banner1-mobile.webp"
                                      />
                                      <img
                                        className="d-block w-100"
                                        src="assets/banner1-mobile.webp"
                                        alt="Banner"
                                      />
                                    </picture>
                                  </div>
                                  <div className="mkd-ps-image">
                                    <picture>
                                      <source
                                        media="(min-width: 481px)"
                                        srcSet="assets/banner2.webp"
                                      />
                                      <source
                                        media="(max-width: 480px)"
                                        srcSet="assets/banner1-mobile.webp"
                                      />
                                      <img
                                        className="d-block w-100"
                                        src="assets/banner1-mobile.webp"
                                        alt="Banner"
                                      />
                                    </picture>
                                  </div>
                                  <div className="mkd-ps-image">
                                    <picture>
                                      <source
                                        media="(min-width: 481px)"
                                        srcSet="assets/banner3.webp"
                                      />
                                      <source
                                        media="(max-width: 480px)"
                                        srcSet="assets/banner1-mobile.webp"
                                      />
                                      <img
                                        className="d-block w-100"
                                        src="assets/banner1-mobile.webp"
                                        alt="Banner"
                                      />
                                    </picture>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="blog2 vc_row wpb_row vc_row-fluid vc_column-gap-15 vc_row-o-equal-height vc_row-flex">
            <div className="wpb_column vc_column_container vc_col-sm-12 vc_col-lg-8 vc_col-md-12 vc_col-xs-12 vc_col-has-fill">
              <div className="vc_column-inner vc_custom_1517387242406">
                <div className="wpb_wrapper">
                  <div className="mkd-elements-holder   mkd-one-column  mkd-responsive-mode-768 ">
                    <div
                      className="mkd-eh-item"
                      data-item-class="mkd-eh-custom-1385"
                      data-1280-1600="0px"
                      data-1024-1280="0px"
                      data-768-1024="0%"
                      data-680-768="0%"
                      data-680="0%"
                    >
                      <div className="mkd-eh-item-inner">
                        <div
                          className="mkd-eh-item-content mkd-eh-custom-1385"
                          style={{ padding: 0 }}
                        >
                          <div className="mkd-single-image-holder     ">
                            <div className="mkd-si-inner">
                              <div className="mkd-ps-image-holder">
                                <div className="mkd-ps-image mkd-owl-slider">
                                  <div className="mkd-ps-image">
                                    <picture>
                                      <source
                                        media="(min-width: 481px)"
                                        srcSet="assets/adora.jpg"
                                      />
                                      <source
                                        media="(max-width: 480px)"
                                        srcSet="assets/adora-mobile.jpg"
                                      />
                                      <img
                                        className="d-block w-100"
                                        src="assets/adora-mobile.jpg"
                                        alt="Banner"
                                      />
                                    </picture>
                                  </div>
                                  <div className="mkd-ps-image">
                                    <picture>
                                      <source
                                        media="(min-width: 481px)"
                                        srcSet="assets/adora1.jpg"
                                      />
                                      <source
                                        media="(max-width: 480px)"
                                        srcSet="assets/adora1-mobile.jpg"
                                      />
                                      <img
                                        className="d-block w-100"
                                        src="assets/adora1-mobile.jpg"
                                        alt="Banner"
                                      />
                                    </picture>
                                  </div>
                                  <div className="mkd-ps-image">
                                    <picture>
                                      <source
                                        media="(min-width: 481px)"
                                        srcSet="assets/adora2.jpg"
                                      />
                                      <source
                                        media="(max-width: 480px)"
                                        srcSet="assets/adora2-mobile.jpg"
                                      />
                                      <img
                                        className="d-block w-100"
                                        src="assets/adora2-mobile.jpg"
                                        alt="Banner"
                                      />
                                    </picture>
                                  </div>
                                  <div className="mkd-ps-image">
                                    <picture>
                                      <source
                                        media="(min-width: 481px)"
                                        srcSet="assets/adora3.jpg"
                                      />
                                      <source
                                        media="(max-width: 480px)"
                                        srcSet="assets/adora3-mobile.jpg"
                                      />
                                      <img
                                        className="d-block w-100"
                                        src="assets/adora3-mobile.jpg"
                                        alt="Banner"
                                      />
                                    </picture>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="wpb_column vc_column_container vc_col-sm-12 vc_col-lg-4 vc_col-md-12 vc_col-xs-12 vc_col-has-fill">
              <div className="vc_column-inner vc_custom_1546005427307">
                <div className="wpb_wrapper">
                  <div
                    className="mkd-animation-holder mkd-grow-in"
                    data-animation="mkd-grow-in"
                    data-animation-delay={500}
                  >
                    <div className="mkd-animation-inner">
                      <div className="mkd-elements-holder   mkd-one-column  mkd-responsive-mode-768 ">
                        <div className="mkd-eh-item    ">
                          <div className="mkd-eh-item-inner">
                            <div
                              className="mkd-eh-item-content mkd-eh-custom-9754"
                              style={{ padding: "10%" }}
                            >
                              <h4 style={{ color: "black" }}>
                                NEWRY ADORA, THIRUMULLAIVOYAL
                              </h4>
                              <div
                                className="vc_empty_space"
                                style={{ height: 18 }}
                              >
                                <span className="vc_empty_space_inner" />
                              </div>
                              <p style={{ textAlign: "left" }}>
                                Newry Adora is a premium apartment complex in
                                Kolathur, offering you a variety of options to
                                suit your needs.
                              </p>
                              <div
                                className="vc_empty_space"
                                style={{ height: 48 }}
                              >
                                <span className="vc_empty_space_inner" />
                              </div>
                              <a
                                itemProp="url"
                                href="https://www.newry.co.in/adora"
                                target="_self"
                                style={{ margin: "0 0 0 4px" }}
                                className="mkd-btn mkd-btn-medium mkd-btn-outline"
                              >
                                <span className="mkd-btn-text">Read more</span>
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="mkd-full-width">
        <div className="mkd-full-width-inner">
          <div className="blog1 vc_row wpb_row vc_row-fluid vc_column-gap-20 vc_row-o-equal-height vc_row-flex">
            <div className="wpb_column vc_column_container vc_col-sm-12 vc_col-lg-4 vc_col-md-12 vc_col-xs-12 vc_col-has-fill">
              <div className="vc_column-inner vc_custom_1515679036394">
                <div className="wpb_wrapper">
                  <div
                    className="mkd-animation-holder mkd-grow-in"
                    data-animation="mkd-grow-in"
                    data-animation-delay={500}
                  >
                    <div className="mkd-animation-inner">
                      <div className="mkd-elements-holder   mkd-one-column  mkd-responsive-mode-768 ">
                        <div className="mkd-eh-item">
                          <div className="mkd-eh-item-inner">
                            <div
                              className="mkd-eh-item-content mkd-eh-custom-8840"
                              style={{ padding: "10%" }}
                            >
                              <h4 style={{ color: "black" }}>
                                NEWRY CLOVER COUNTY, PARIVAKKAM
                              </h4>
                              <div
                                className="vc_empty_space"
                                style={{ height: 18 }}
                              >
                                <span className="vc_empty_space_inner" />
                              </div>
                              <p style={{ textAlign: "left" }}>
                                Newry Clover County is a plotted development
                                project in Poonamallee - Avadi Road, part of the
                                Newry Fortune City.
                              </p>
                              <div
                                className="vc_empty_space"
                                style={{ height: 48 }}
                              >
                                <span className="vc_empty_space_inner" />
                              </div>
                              <a
                                itemProp="url"
                                href="https://www.newry.co.in/clover-county"
                                target="_self"
                                style={{ margin: "0 0 0 4px" }}
                                className="mkd-btn mkd-btn-medium mkd-btn-outline"
                              >
                                <span className="mkd-btn-text">Read more</span>
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="wpb_column vc_column_container vc_col-sm-12 vc_col-lg-8 vc_col-md-12 vc_col-xs-12">
              <div className="vc_column-inner vc_custom_1517388638346">
                <div className="wpb_wrapper">
                  <div className="mkd-elements-holder   mkd-one-column  mkd-responsive-mode-768 ">
                    <div
                      className="mkd-eh-item    "
                      data-item-class="mkd-eh-custom-8914"
                      data-1280-1600="0px"
                      data-1024-1280="0px"
                      data-768-1024="0%"
                      data-680-768="0%"
                      data-680="0%"
                    >
                      <div className="mkd-eh-item-inner">
                        <div
                          className="mkd-eh-item-content mkd-eh-custom-8914"
                          style={{ padding: 0 }}
                        >
                          <div className="mkd-single-image-holder">
                            <div className="mkd-si-inner">
                              <div className="mkd-ps-image-holder">
                                <div className="mkd-ps-image mkd-owl-slider">
                                  <div className="mkd-ps-image">
                                    <picture>
                                      <source
                                        media="(min-width: 481px)"
                                        srcSet="assets/ongoing/clover.jpg"
                                      />
                                      <source
                                        media="(max-width: 480px)"
                                        srcSet="assets/ongoing/clover-mobile.jpg"
                                      />
                                      <img
                                        className="d-block w-100"
                                        src="assets/ongoing/clover-mobile.jpg"
                                        alt="Banner"
                                      />
                                    </picture>
                                  </div>
                                  <div className="mkd-ps-image">
                                    <picture>
                                      <source
                                        media="(min-width: 481px)"
                                        srcSet="assets/ongoing/clover1.jpg"
                                      />
                                      <source
                                        media="(max-width: 480px)"
                                        srcSet="assets/ongoing/clover1-mobile.jpg"
                                      />
                                      <img
                                        className="d-block w-100"
                                        src="assets/ongoing/clover1-mobile.jpg"
                                        alt="Banner"
                                      />
                                    </picture>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* <div className="blog2 vc_row wpb_row vc_row-fluid vc_column-gap-15 vc_row-o-equal-height vc_row-flex">
            <div className="wpb_column vc_column_container vc_col-sm-12 vc_col-lg-8 vc_col-md-12 vc_col-xs-12 vc_col-has-fill">
              <div className="vc_column-inner vc_custom_1517387242406">
                <div className="wpb_wrapper">
                  <div className="mkd-elements-holder   mkd-one-column  mkd-responsive-mode-768 ">
                    <div
                      className="mkd-eh-item"
                      data-item-class="mkd-eh-custom-1385"
                      data-1280-1600="0px"
                      data-1024-1280="0px"
                      data-768-1024="0%"
                      data-680-768="0%"
                      data-680="0%"
                    >
                      <div className="mkd-eh-item-inner">
                        <div
                          className="mkd-eh-item-content mkd-eh-custom-1385"
                          style={{ padding: 0 }}
                        >
                          <div className="mkd-single-image-holder     ">
                            <div className="mkd-si-inner">
                              <div className="mkd-ps-image-holder">
                                <div className="mkd-ps-image mkd-owl-slider">
                                  <div className="mkd-ps-image">
                                    <picture>
                                      <source
                                        media="(min-width: 481px)"
                                        srcSet="assets/arista.jpg"
                                      />
                                      <source
                                        media="(max-width: 480px)"
                                        srcSet="assets/arista-mobile.jpg"
                                      />
                                      <img
                                        className="d-block w-100"
                                        src="assets/arista-mobile.jpg"
                                        alt="Banner"
                                      />
                                    </picture>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="wpb_column vc_column_container vc_col-sm-12 vc_col-lg-4 vc_col-md-12 vc_col-xs-12 vc_col-has-fill">
              <div className="vc_column-inner vc_custom_1546005427307">
                <div className="wpb_wrapper">
                  <div
                    className="mkd-animation-holder mkd-grow-in"
                    data-animation="mkd-grow-in"
                    data-animation-delay={500}
                  >
                    <div className="mkd-animation-inner">
                      <div className="mkd-elements-holder   mkd-one-column  mkd-responsive-mode-768 ">
                        <div className="mkd-eh-item    ">
                          <div className="mkd-eh-item-inner">
                            <div
                              className="mkd-eh-item-content mkd-eh-custom-9754"
                              style={{ padding: "10%" }}
                            >
                              <h4 style={{ color: "black" }}>
                                NEWRY ARISTA, KOLATHUR
                              </h4>
                              <div
                                className="vc_empty_space"
                                style={{ height: 18 }}
                              >
                                <span className="vc_empty_space_inner" />
                              </div>
                              <p style={{ textAlign: "left" }}>
                                Arista is a Sanskrit word that means perfect,
                                complete, imperishable, secure, safe and happy.
                              </p>
                              <div
                                className="vc_empty_space"
                                style={{ height: 48 }}
                              >
                                <span className="vc_empty_space_inner" />
                              </div>
                              <a
                                itemProp="url"
                                href="https://www.newry.co.in/arista"
                                target="_self"
                                style={{ margin: "0 0 0 4px" }}
                                className="mkd-btn mkd-btn-medium mkd-btn-outline"
                              >
                                <span className="mkd-btn-text">Read more</span>
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div> */}
        </div>
      </div>
    </div>
  </div>
  {/*COMPLETED END*/}
</div>

    </>;
};

export default Ongoing;